import * as React from 'react'
import * as styles from './Tooltip.css';
import { Helper } from '../../Helper';
import { IAPopup } from '../popup/Popup';

export interface IAToolTipProps {
  content: string;
  type: "popup" | "bubble";
  styles?: any;
  color?: string;
  backgroundColor?: string;
  rootStyles?: any;
  componentContent?: JSX.Element;
  showBelow?: boolean;
  darkMode?: boolean;
}

export interface IAToolTipState {
  isVisible: boolean;
  hasFocus: boolean;
  top: number;
  left: number;
  showToolTipPopup: boolean;

}

export class IATooltip extends React.Component<IAToolTipProps, IAToolTipState> {

  private toolTipId: string;

  constructor(props: IAToolTipProps, state: IAToolTipState) {
    super(props, state);
    this.state = {
      isVisible: false,
      hasFocus: false,
      top: undefined,
      left: undefined,
      showToolTipPopup: false
    };
    this.toolTipId = Helper.getRandomStringKey();
  }

  public render(): JSX.Element {

    return (
      <div
        className={`${styles.tooltip} ${this.state.isVisible ? styles.isVisible : styles.isHidden}`}
        id={this.toolTipId}
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
        onClick={() => this.handleMouseClick()}
        style={{
          ...this.props.rootStyles
        }}
      >
        <div
          className={this.props.showBelow ? (this.props.darkMode ? styles.tooltipLabelBelowDarkMode : styles.tooltipLabelBelow) : (this.props.darkMode ? styles.tooltipLabelDarkMode : styles.tooltipLabel)}
          id={"toolTipContent-" + this.toolTipId}
          onMouseEnter={this.handleFocus.bind(this)}
          style={{
            top: this.state.top,
            left: this.state.left,
            ...this.props.styles
          }}
        >
          <div className={styles.scrollBar}>{this.props.componentContent}</div>
        </div>
        {this.props.children}
        {this.state.showToolTipPopup &&
          <IAPopup
            content={
              <div>
                <div
                  style={{
                    clear: "both",
                    float: "left",
                    maxHeight: 200,
                    overflow: "auto",
                    width: "100%"
                  }}
                >
                  {this.props.componentContent}
                </div>
              </div>
            }
            close={() => {
              this.setState({ showToolTipPopup: false });
            }}
          />
        }
      </div>
    );
  }

  private handleFocus() {
    this.setState({
      hasFocus: true
    });
  }

  private handleMouseEnter() {
    if (this.props.type === "bubble") {
      const toolTipTriggerElement = document.getElementById(this.toolTipId);
      const toolTipContentElement = document.getElementById("toolTipContent-" + this.toolTipId);
      this.setState({
        isVisible: true,
        top: toolTipTriggerElement.getBoundingClientRect().top - toolTipContentElement.getBoundingClientRect().height,
        left: toolTipTriggerElement.getBoundingClientRect().left + (toolTipTriggerElement.getBoundingClientRect().width / 2)
      });
    }
  }

  private handleMouseClick(): void {
    if (!this.state.showToolTipPopup && this.props.type === "popup") {
      this.setState({
        showToolTipPopup: true
      });
    }
  }

  private handleMouseLeave() {
    if (this.props.type === "bubble") {
      setTimeout(() => {
        this.setState({
          isVisible: false
        });
      }, 0);
    }
  }
}