import * as React from 'react';
import * as styles from "./MessageBar.css";
import { IAIcon } from "../icon";
import { LoggingService } from '../../services/LoggingService';

export interface IAMessageBarProps {
  icon?: string;
  closeButtonIcon?: string;
  showIcon?: boolean;
  type: "warning" | "success" | "error" | "information";
  style?: React.CSSProperties;
  content?: string | React.ReactElement;
  showCloseButton?: boolean;
  onDismiss(): void;
  dismissContentMessage?: string;
}

export interface IAMessageBarState {
  className: string;
  iconColor: string;
}

export class IAMessageBar extends React.Component<IAMessageBarProps, IAMessageBarState> {

  constructor(props: IAMessageBarProps) {
    super(props);

    this.state = {
      className: "IA_messageBar",
      iconColor: "#333333"
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.renderStyle = this.renderStyle.bind(this);
    
  }

  private onDismiss(): void {
    this.props.onDismiss();
  }

  public componentDidMount(): void {
    this.renderStyle();
  }

  private renderStyle(): void {

    let className = styles.IA_messageBar + " ";
    let iconColor = "";

    switch (this.props.type) {
      case "warning":
        className = className + styles.IA_messageBarWarning;
        iconColor = "rgb(96, 94, 92)";
        break;
      case "success":
        className = className + styles.IA_messageBarSuccess;
        iconColor = "rgb(16, 124, 16)";
        break;
      case "error":
        className = className + styles.IA_messageBarError;
        iconColor = "rgb(164, 38, 44)";
        break;
      case "information":
        className = className + styles.IA_messageBarInfo;
        iconColor = "rgb(96, 94, 92)";
        break;
    }

    this.setState({ className: className, iconColor: iconColor })
  }

  public render(): JSX.Element {
    return (
      <div
        className={this.state.className}
        style={this.props.style}
      >
        <div className={styles.IA_messageBarContent}>
          {this.props.showIcon &&
            <div className={styles.IA_messageBarContentIcon}>
              <IAIcon
                title={this.props.icon}
                size={17}
                color={this.state.iconColor}
              />
            </div>
          }
          <div className={styles.IA_messageBarContentText}>{this.props.content}</div>
          {this.props.dismissContentMessage && <div className={styles.IA_messageBarContentDismissText} onClick={this.onDismiss}>{this.props.dismissContentMessage}</div>}
          {this.props.showCloseButton &&
            <div className={styles.IA_messageBarContentClose}>
              <div className={styles.IA_messageBarContentCloseBtn} onClick={() => {
                this.onDismiss();
              }}>
                <IAIcon
                  title={ this.props.closeButtonIcon ? this.props.closeButtonIcon : "Close"}
                  size={16}
                  color={"rgb(96, 94, 92)"}
                />
              </div>
            </div>
          }
        </div>
      </div>

    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}