exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_button_1Ju5SdCN7YEMP7vemC2G7g {\n  border-style: none;\n  height: 30px;\n  width: auto;\n  float: left;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 14px;\n  line-height: 30px;\n  font-weight: 400;\n  cursor: pointer;\n  outline: none;\n  color:#333333;    \n  background-color:#f4f4f4;\n  padding: 0;\n  border-radius: 2px;\n}\n.IA_button_1Ju5SdCN7YEMP7vemC2G7g .spinner_WW8FuzD5vnkXUvulHmbE9 {\n  width: 20px;\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_1JVZMPQlfSBE03AzwAEHIP 700ms linear infinite;\n  -moz-animation: spin_1JVZMPQlfSBE03AzwAEHIP 700ms linear infinite;\n  animation: spin_1JVZMPQlfSBE03AzwAEHIP 700ms linear infinite;\n }\n\n.IA_button_1Ju5SdCN7YEMP7vemC2G7g .label_2VAn3DvmIa1BKu5wWQRHBO {\n  text-align: center;\n}\n\n.IA_button_1Ju5SdCN7YEMP7vemC2G7g .icon_Aypexir9lSYb7IYQwSbWH {\n  float: left;\n  margin-top: 3px;\n  margin-right: 5px;\n  line-height: normal;\n}\n@-moz-keyframes spin_1JVZMPQlfSBE03AzwAEHIP {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_1JVZMPQlfSBE03AzwAEHIP {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_1JVZMPQlfSBE03AzwAEHIP {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_button": "IA_button_1Ju5SdCN7YEMP7vemC2G7g",
	"spinner": "spinner_WW8FuzD5vnkXUvulHmbE9",
	"spin": "spin_1JVZMPQlfSBE03AzwAEHIP",
	"label": "label_2VAn3DvmIa1BKu5wWQRHBO",
	"icon": "icon_Aypexir9lSYb7IYQwSbWH"
};