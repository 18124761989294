declare global {
  interface Window { language: string; }
}
export class LocalizationService {
  public strings = {
    Birthday_Location: undefined as string,
    Birthday_jobTile: undefined as string,
    Birthday_chat: undefined as string,
    Birthday_email: undefined as string,
    Birthday_No_birthday: undefined as string,
    Birthday_Years: undefined as string,
    Birthday_Today: undefined as string,
    Birthday_No_Anniversary: undefined as string,
    Messages_Add: undefined as string,
    Messages_Filter: undefined as string,
    Messages_Search: undefined as string,
    Messages_Clear_Filter: undefined as string,
    Messages_ReadAll: undefined as string,

    Messages_ViewPanel_Today: undefined as string,
    Messages_ViewPanel_ModifiedBy: undefined as string,
    Messages_ViewPanel_NoMessages: undefined as string,
    Messages_ViewPanel_PrintOverlayText: undefined as string,
    Messages_ViewPanel_UnableToPrintImage: undefined as string,

    Messages_EditPanel_TopImage: undefined as string,
    Messages_EditPanel_Title: undefined as string,
    Messages_EditPanel_Video: undefined as string,
    Messages_EditPanel_VideoUrl: undefined as string,
    Messages_EditPanel_StartTime: undefined as string,
    Messages_EditPanel_EndTime: undefined as string,
    Messages_EditPanel_SendAsPushNotification: undefined as string,
    Messages_EditPanel_SendUsingBotWarning: undefined as string,
    Messages_EditPanel_SendUsingBotTargetingWarning: undefined as string,
    Messages_EditPanel_SendAsPush: undefined as string,
    Messages_EditPanel_SendToApp: undefined as string,
    Messages_EditPanel_ScheduledPushWarning: undefined as string,
    Messages_EditPanel_SendToAppWarning: undefined as string,
    Messages_EditPanel_SendUsingBot: undefined as string,
    Messages_EditPanel_SendUsingBotTooltip: undefined as string,
    Messages_EditPanel_SaveAndClose: undefined as string,
    Messages_EditPanel_AddingAMessageTo: undefined as string,
    Messages_EditPanel_EditingMessage: undefined as string,
    Messages_EditPanel_Contacts: undefined as string,
    Messages_EditPanel_Approvers: undefined as string,
    Messages_EditPanel_ApprovalComments: undefined as string,
    Messages_EditPanel_Categories: undefined as string,
    Messages_EditPanel_GroupHeadlineContent: undefined as string,
    Messages_EditPanel_GroupHeadlineSettings: undefined as string,
    Messages_EditPanel_GroupHeadlineApprovalDetails: undefined as string,
    Messages_EditPanel_MissingTitle: undefined as string,
    Messages_EditPanel_MissingStartTime: undefined as string,
    Messages_EditPanel_MissingTargeting: undefined as string,
    Messages_EditPanel_MissingCategories: undefined as string,
    Messages_EditPanel_MissingApprovers: undefined as string,
    Messages_EditPanel_MissingPush: undefined as string,
    Messages_EditPanel_DisableLikes: undefined as string,
    Messages_EditPanel_DisableComments: undefined as string,
    Messages_EditPanel_UnsupportedImageFormat: undefined as string,
    Messages_EditPanel_ImageAltText: undefined as string,
    Messages_EditPanel_MissingImageAltText: undefined as string,
    Messages_EditPanel_MissingVideoAltText: undefined as string,
    Messages_EditPanel_AltTextToolTip: undefined as string,
    Messages_EditPanel_PublishToFeeds: undefined as string,
    Messages_FilterPanel_Categories: undefined as string,
    Messages_FilterPanel_Author: undefined as string,
    Messages_FilterPanel_Me: undefined as string,
    Messages_FilterPanel_All: undefined as string,
    Messages_FilterPanel_Contacts: undefined as string,
    Messages_FilterPanel_Date: undefined as string,
    Messages_FilterPanel_AllTime: undefined as string,
    Messages_FilterPanel_Period: undefined as string,
    Messages_FilterPanel_StartTime: undefined as string,
    Messages_FilterPanel_EndTime: undefined as string,
    Messages_DeletePopup_Headline: undefined as string,
    Messages_DeletePopup_Message: undefined as string,
    Messages_DeletePopup_Yes: undefined as string,
    Messages_DeletePopup_No: undefined as string,

    Messages_CancelPopup_Headline: undefined as string,
    Messages_CancelPopup_Yes: undefined as string,
    Messages_CancelPopup_No: undefined as string,

    Messages_ErrorPopup_Deleted: undefined as string,
    Messages_TargetContent: undefined as string,

    Messages_Categories_Edit: undefined as string,
    Messages_Categories_Open: undefined as string,
    Messages_Categories_Delete: undefined as string,
    Messages_PivotMessages: undefined as string,
    Messages_PivotCategories: undefined as string,
    Messages_ServerError: undefined as string,

    Messages_RollUpTypeImage: undefined as string,
    Messages_RollUpTypeVideo: undefined as string,
    Messages_RollUpTypeAttachment: undefined as string,
    Messages_ThisWeek: undefined as string,

    Card_CommandBar_Delete: undefined as string,
    Card_CommandBar_Review: undefined as string,
    Card_CommandBar_Copy: undefined as string,
    Card_CommandBar_Preview: undefined as string,
    Card_CommandBar_Open: undefined as string,
    Card_CommandBar_Edit: undefined as string,
    Card_ContentIsTargeted: undefined as string,
    List_CommandBar_Delete: undefined as string,
    List_CommandBar_Copy: undefined as string,
    List_CommandBar_Preview: undefined as string,
    List_CommandBar_Open: undefined as string,
    List_CommandBar_Edit: undefined as string,
    Panel_NavigatorBar_ExportToCalender: undefined as string,
    Panel_NavigatorBar_ClearFilter: undefined as string,
    Panel_NavigatorBar_Edit: undefined as string,
    Panel_NavigatorBar_Cancel: undefined as string,
    Panel_NavigatorBar_Save: undefined as string,
    Panel_NavigatorBar_SaveAsDraft: undefined as string,
    Panel_NavigatorBar_SaveAndUnpublish: undefined as string,
    Panel_NavigatorBar_SaveAndPublish: undefined as string,
    Panel_NavigatorBar_SubmitForApproval: undefined as string,
    Panel_NavigatorBar_ReviewReject: undefined as string,
    Panel_NavigatorBar_ReviewApprove: undefined as string,
    Panel_NavigatorBar_EmailLink: undefined as string,
    Panel_NavigatorBar_CopyLink: undefined as string,
    Panel_NavigatorBar_LinkCopied: undefined as string,
    Panel_NavigatorBar_Delete: undefined as string,
    Panel_NavigatorBar_Translate: undefined as string,
    Panel_NavigatorBar_TranslatedMessage: undefined as string,
    Panel_NavigatorBar_DismissTranslation: undefined as string,
    Panel_NavigatorBar_LanguageDanish: undefined as string,
    Panel_NavigatorBar_LanguageEnglish: undefined as string,
    Panel_NavigatorBar_LanguageGerman: undefined as string,
    Panel_NavigatorBar_LanguagePortuguese: undefined as string,
    Panel_NavigatorBar_LanguageSwedish: undefined as string,
    Panel_NavigatorBar_LanguageFrench: undefined as string,
    Panel_NavigatorBar_LanguageSpanish: undefined as string,
    Panel_NavigatorBar_LanguagePolish: undefined as string,
    Panel_NavigatorBar_LanguageDutch: undefined as string,
    Panel_NavigatorBar_LanguageCzech: undefined as string,
    Panel_NavigatorBar_LanguageRussian: undefined as string,
    Panel_NavigatorBar_LanguageHungarian: undefined as string,
    Panel_NavigatorBar_LanguageChinese: undefined as string,
    Panel_NavigatorBar_LanguageSlovak: undefined as string,
    Panel_NavigatorBar_LanguageItalian: undefined as string,
    Panel_NavigatorBar_LanguageTurkish: undefined as string,
    Panel_NavigatorBar_LanguageJapanese: undefined as string,
    Panel_NavigatorBar_LanguageCroatian: undefined as string,
    Panel_NavigatorBar_LanguageNorwegian: undefined as string,
    Panel_NavigatorBar_LanguageKorean: undefined as string,
    Panel_NavigatorBar_LanguageLithuanian: undefined as string,
    Panel_NavigatorBar_LanguageThai: undefined as string,
    Panel_NavigatorBar_LanguageUkrainian: undefined as string,
    Panel_NavigatorBar_LanguageMalaysian: undefined as string,
    Panel_NavigatorBar_LanguageHindi: undefined as string,
    Panel_NavigatorBar_LanguageIndonesian: undefined as string,
    Panel_NavigatorBar_LanguageVietnamese: undefined as string,
    Panel_NavigatorBar_Print: undefined as string,
    Navigator_Megamenu_AddGroup: undefined as string,
    Navigator_Megamenu_AddItem: undefined as string,
    Navigator_Megamenu_DropItem: undefined as string,
    Navigator_Megamenu_ReorderItems: undefined as string,
    Navigator_Megamenu_ReorderGroups: undefined as string,
    Navigator_Megamen_ReorderTopItems: undefined as string,
    Navigator_Megamenu_DoneReordering: undefined as string,
    Navigator_Content_Titel: undefined as string,
    Navigator_Content_UrlTopItem: undefined as string,
    Navigator_Content_Url: undefined as string,
    Navigator_Content_LinkOpen: undefined as string,
    Navigator_Content_Icon: undefined as string,
    Navigator_Content_ElementType: undefined as string,
    Navigator_Content_ElementLink: undefined as string,
    Navigator_Content_ElementImage: undefined as string,
    Navigator_Content_ElementVideo: undefined as string,
    Navigator_Content_TopItemType: undefined as string,
    Navigator_Content_TopItemLink: undefined as string,
    Navigator_Content_TopItemMenu: undefined as string,
    Navigator_Content_TopItemPhonebook: undefined as string,
    Navigator_Content_SiteCollectionInputWarning: undefined as string,
    Navigator_Content_SiteCollectionTopItem: undefined as string,
    Navigator_Content_ResultSource: undefined as string,   
    Navigator_Content_EnableLikes: undefined as string,
    Navigator_Content_EnableComments: undefined as string,
    Navigator_Content_ShowDropShadow: undefined as string,
    Navigator_Content_AddMessage: undefined as string,
    Navigator_Content_ShowFilter: undefined as string,
    Navigator_Content_ShowTimespand: undefined as string,
    Navigator_Content_ShowCategories: undefined as string,
    Navigator_Content_IsTargetingAvailable: undefined as string,
    Navigator_Content_ShowPaging: undefined as string,
    Navigator_Content_ShouldFilterByCategory: undefined as string,
    Navigator_Content_CodeEditor: undefined as string,
    Navigator_Settings_GeneralSettings: undefined as string,
    Navigator_Settings_Content: undefined as string,
    Navigator_Settings_TopItem: undefined as string,
    Navigator_Settings_ElementGroup: undefined as string,
    Navigator_Settings_Element: undefined as string,
    Navigator_Design_DesignSettings: undefined as string,
    Navigator_Design_MenuBar: undefined as string,
    Navigator_Design_TopItems: undefined as string,
    Navigator_Design_ElementGroups: undefined as string,
    Navigator_Design_Elements: undefined as string,
    Navigator_Design_TextColor: undefined as string,
    Navigator_Design_BorderColor: undefined as string,
    Navigator_Design_BackgroundColor: undefined as string,
    Navigator_Design_DateBadgeBackgroundColor: undefined as string,
    Navigator_Design_DateBadgeTextColor: undefined as string,
    Navigator_Design_HighlightColor: undefined as string,
    Navigator_Design_HighlightTextColor: undefined as string,
    Navigator_Design_FontSize: undefined as string,
    Navigator_Design_FontWeight: undefined as string,
    Navigator_Design_TextTransform: undefined as string,
    Navigator_Design_LetterSpacing: undefined as string,
    Navigator_Design_Margin: undefined as string,
    Navigator_Design_IconColor: undefined as string,
    Navigator_Design_LineShow: undefined as string,
    Navigator_Design_LineThickness: undefined as string,
    Navigator_Design_TextIndentation: undefined as string,
    Navigator_Design_SideBarWarning: undefined as string,
    Navigator_Design_SlimPanel: undefined as string,
    Navigator_MenuBarSettings_Logo: undefined as string,
    Navigator_MenuBarSettings_Url: undefined as string,
    Navigator_MenuBarSettings_MenuColor: undefined as string,
    Navigator_MenuBarSettings_MenuLayout: undefined as string,
    Navigator_MenuBarSettings_Burger: undefined as string,
    Navigator_MenuBarSettings_Horizontal: undefined as string,
    Navigator_MenuBarSettings_Vertical: undefined as string,
    Navigator_Version_Title: undefined as string,
    Navigator_Version_LoadOlderVersions: undefined as string,
    Navigator_Smartmenu_NumberOfItems: undefined as string,
    Navigator_TopItemPanel_Delete: undefined as string,
    Navigator_TopItemPanel_Cancel: undefined as string,
    Navigator_ElementGroupPanel_Delete: undefined as string,
    Navigator_ElementGroupPanel_Cancel: undefined as string,
    Navigator_ElementPanel_Delete: undefined as string,
    Navigator_ElementPanel_Cancel: undefined as string,
    Navigator_Admin_CheckOutPopupPartOne: undefined as string,
    Navigator_Admin_CheckOutPopupPartTwo: undefined as string,
    Navigator_Admin_NewVersionTitle: undefined as string,
    Navigator_Admin_NewVersionSaveButton: undefined as string,
    Navigator_Admin_CheckOutPopupHeadline: undefined as string,
    Navigator_Admin_CheckOutPopupCancelButton: undefined as string,
    Navigator_Admin_CheckOutPopupOverrideButton: undefined as string,
    Navigator_Admin_SaveButton: undefined as string,
    Navigator_Admin_SaveAndPublishButton: undefined as string,
    Navigator_Admin_CancelButton: undefined as string,
    Navigator_Admin_VersionsButton: undefined as string,
    Navigator_Admin_SelectInstance: undefined as string,
    Navigator_Content_EnableSnowByDefault: undefined as string,
    Navigator_Content_EnableConfettiByDefault: undefined as string,
    Navigator_Content_CelebrationType: undefined as string,
    Navigator_Admin_SelectPeriod: undefined as string,
    Navigator_Admin_AmountOfCardsPerPage: undefined as string,
    Navigator_Admin_HighlightColor: undefined as string,
    Navigator_Admin_HeadlineColor: undefined as string,
    Navigator_Admin_HeadlineFontSize: undefined as string,
    Navigator_Admin_HeadlineFontWeight: undefined as string,
    Navigator_Admin_HeadlineTextAlign: undefined as string,
    Navigator_Admin_DeleteDraftButton: undefined as string,
    Navigator_Admin_CheckedOutToYou: undefined as string,
    Navigator_Admin_ForgotToSave: undefined as string,
    Navigator_Admin_ForgotToSaveMessage: undefined as string,
    Navigator_Admin_ForgotToSaveButtonSave: undefined as string,
    Navigator_Admin_ForgotToSaveButtonCancel: undefined as string,
    Navigator_Admin_CheckedOutOverrideDeleteChanges: undefined as string,
    Navigator_Admin_CodeEditorWarning: undefined as string,
    Navigator_Content_EnableConfettiUserInteraction: undefined as string,
    Navigator_SnowPopupHeadline: undefined as string,
    Navigator_SnowPopupStopSnowingForToday: undefined as string,
    Navigator_SnowPopupStopSnowing: undefined as string,
    Navigator_SnowPopupStartSnowing: undefined as string,
    Navigator_ConfettiPopupHeadline: undefined as string,
    Navigator_ConfettiPopupStopConfetti: undefined as string,
    Navigator_ConfettiPopupStartConfetti: undefined as string,
    Navigator_EasterPopupHeadline: undefined as string,
    Navigator_EasterConfetti: undefined as string,

    Phonebook_Header: undefined as string,
    Phonebook_Search: undefined as string,
    Phonebook_LoadMore: undefined as string,

    IconSelector_SearchBar_Search: undefined as string,
    IconSelector_NoResult: undefined as string,

    ImagePicker_Placeholder: undefined as string,

    LikesAndComments_ShowOnePreviousComment: undefined as string,
    LikesAndComments_ShowPreviousComments_1: undefined as string,
    LikesAndComments_ShowPreviousComments_2: undefined as string,
    LikesAndComments_Unlike: undefined as string,
    LikesAndComments_Like: undefined as string,
    LikesAndComments_Comment: undefined as string,
    LikesAndComments_OnlyShowLatestComments: undefined as string,
    LikesAndComments_NewComment_AddComment: undefined as string,
    LikesAndComments_NewComment_Cancel: undefined as string,
    LikesAndComments_Comment_You: undefined as string,
    LikesAndComments_Comment_Collapse: undefined as string,
    LikesAndComments_Comment_Expand: undefined as string,
    LikesAndComments_Comment_Unlike: undefined as string,
    LikesAndComments_Comment_Like: undefined as string,
    LikesAndComments_Comment_Reply: undefined as string,
    LikesAndComments_Comment_LikeThis: undefined as string,
    LikesAndComments_Comment_LikesThis: undefined as string,
    LikesAndComments_Comment_OneOther: undefined as string,
    LikesAndComments_Comment_Others: undefined as string,
    LikesAndComments_Comment_And: undefined as string,
    LikesAndComments_AllLikes: undefined as string,
    LikesAndComments_InReplyTo: undefined as string,
    LikesAndComments_ReplyTo: undefined as string,
    TargetTree_TargetContent: undefined as string,
    TargetTree_Operator_And: undefined as string,
    TargetTree_Operator_Or: undefined as string,
    TargetTree_WhoShouldSeeThis: undefined as string,
    TargetTree_All: undefined as string,
    DateTimeService_Sunday: undefined as string,
    DateTimeService_Monday: undefined as string,
    DateTimeService_Tuesday: undefined as string,
    DateTimeService_Wednesday: undefined as string,
    DateTimeService_Thursday: undefined as string,
    DateTimeService_Friday: undefined as string,
    DateTimeService_Saturday: undefined as string,
    DateTimeService_January: undefined as string,
    DateTimeService_February: undefined as string,
    DateTimeService_March: undefined as string,
    DateTimeService_April: undefined as string,
    DateTimeService_May: undefined as string,
    DateTimeService_June: undefined as string,
    DateTimeService_July: undefined as string,
    DateTimeService_August: undefined as string,
    DateTimeService_September: undefined as string,
    DateTimeService_October: undefined as string,
    DateTimeService_November: undefined as string,
    DateTimeService_December: undefined as string,
    DateTimeService_Today: undefined as string,
    DateTimeService_Tomorrow: undefined as string,
    DateTimeService_Yesterday: undefined as string,
    Event_EditPanel_Title: undefined as string,
    Event_EditPanel_StartTime: undefined as string,
    Event_EditPanel_EndTime: undefined as string,
    Event_TargetContent: undefined as string,
    Event_EditPanel_Categories: undefined as string,
    Event_EditPanel_SaveAndClose: undefined as string,
    Event_Add: undefined as string,
    Event_DeletePopup_Headline: undefined as string,
    Event_DeletePopup_Message: undefined as string,
    Event_DeletePopup_Yes: undefined as string,
    Event_DeletePopup_No: undefined as string,
    Event_NoContent: undefined as string,
    Event_EditPanel_AllDayEvent: undefined as string,
    Event_EditPanel_Address: undefined as string,
    Event_EditPanel_Location: undefined as string,
    Event_ExportLink: undefined as string,
    EventPivotEvents: undefined as string,
    EventPivotCategories: undefined as string,
    Event_EditPanel_AddingAMessageTo: undefined as string,
    Event_ViewPanel_When: undefined as string,
    Event_ViewPanel_Where: undefined as string,
    Event_EditPanel_EditingMessage: undefined as string,
    TargetingAdmin_UserGroup_GroupLabel: undefined as string,
    TargetingAdmin_UserGroup_GroupValues: undefined as string,
    TargetingAdmin_UserGroup_TabText: undefined as string,
    TargetingAdmin_UserGroup_EditPanel_Title: undefined as string,
    TargetingAdmin_UserGroup_EditPanel_SaveAndClose: undefined as string,
    TargetingAdmin_UserGroup_EditPanel_Cancel: undefined as string,
    TargetingAdmin_UserGroup_EditPanel_Values: undefined as string,
    TargetingAdmin_UserGroup_NoGroups: undefined as string,
    TargetingAdmin_columnName: undefined as string,
    TargetingAdmin_columnTermSet: undefined as string,
    TargetingAdmin_columnProfileProperty: undefined as string,
    TargetingAdmin_UserProperty: undefined as string,
    TargetingAdmin_DescriptionProfileData: undefined as string,
    TargetingAdmin_NewProperty: undefined as string,
    TargetingAdmin_Applications: undefined as string,
    TargetingAdmin_Syncronize: undefined as string,
    TargetingAdmin_PropertyPanelTitle: undefined as string,
    TargetingAdmin_PropertyPanelDisplayName: undefined as string,
    TargetingAdmin_PropertyPanelUserProperty: undefined as string,
    TargetingAdmin_PropertyPanelTermGroupMapping: undefined as string,
    TargetingAdmin_PropertyPanelTermSetMapping: undefined as string,
    TargetingAdmin_PropertyPanelCancel: undefined as string,
    TargetingAdmin_SynchronizeTitle: undefined as string,
    TargetingAdmin_SynchronizeDescription: undefined as string,
    TargetingAdmin_SynchronizeSelectTermGroup: undefined as string,
    TargetingAdmin_SynchronizeSyncTerms: undefined as string,
    TargetingAdmin_ApplicationDescription: undefined as string,
    TargetingAdmin_ApplicationColumnName: undefined as string,
    TargetingAdmin_ApplicationColumnProfileDataUserGroups: undefined as string,
    TargetingAdmin_ApplicationSettingsEnableTarget: undefined as string,
    TargetingAdmin_ApplicationSettingsUseDefault: undefined as string,
    TargetingAdmin_ApplicationSettingsSelectTarget: undefined as string,
    TargetingAdmin_ApplicationSettingsUserGroups: undefined as string,
    TargetingAdmin_ApplicationSettingsProfileData: undefined as string,
    TargetingAdmin_ApplicationSettingsUserGroupsTarget: undefined as string,
    TargetingAdmin_ApplicationSettingsAll: undefined as string,
    TargetingAdmin_ApplicationSettingsProfileDataTarget: undefined as string,
    TargetingAdmin_ApplicationSettingsSave: undefined as string,
    TargetingAdmin_ApplicationSettingsCancel: undefined as string,
    TargetingAdmin_ProfileDataColumnTargetName: undefined as string,
    TargetingAdmin_ProfileDataColumnTermSet: undefined as string,
    TargetingAdmin_ProfileDataColumnUserProfileProperty: undefined as string,
    TargetingAdmin_ApplicationSettingsInfo: undefined as string,
    Theme_Title: undefined as string,
    Theme_Description: undefined as string,
    Theme_Field_Name: undefined as string,
    Theme_Field_Name_Disclaimer: undefined as string,
    Theme_Field_Name_Placeholder: undefined as string,
    Theme_Field_Primary_Color: undefined as string,
    Theme_Field_Secondary_Color: undefined as string,
    Theme_Field_Background_Color: undefined as string,
    Theme_Edit_Info_Text: undefined as string,

    CancelPopup_Headline: undefined as string,
    CancelPopup_Yes: undefined as string,
    CancelPopup_No: undefined as string,

    DeletePopup_Headline: undefined as string,
    DeletePopup_Message: undefined as string,
    DeletePopup_Yes: undefined as string,
    DeletePopup_No: undefined as string,

    Teams_NoContent: undefined as string,

    HttpError_401: undefined as string,
    HttpError_403: undefined as string,
    HttpError_404: undefined as string,
    HttpError_503: undefined as string,
    HttpError_500: undefined as string,
    HttpError_SeeMoreButton: undefined as string,
    Uploader_CopyPasteUrl: undefined as string,
    Uploader_PasteUrl: undefined as string,
    Uploader_OurOrganization: undefined as string,
    Uploader_OurOrganization_Arialable: undefined as string,
    Uploader_Upload: undefined as string,
    Uploader_FromAlink: undefined as string,
    Uploader_BackToLibraries: undefined as string,
    Uploader_BackBtn: undefined as string,
    Uploader_DoneBtn: undefined as string,
    Uploader_OurOrganization_Header: undefined as string,
    Uploader_OurOrganization_Header_video: undefined as string,
    Uploader_Upload_Header: undefined as string,
    Uploader_FromALink_Header: undefined as string,
    Uploader_Upload_ToOrgAssets: undefined as string,
    Uploader_Upload_OverwriteConfirmation: undefined as string,
    Uploader_Upload_OverwriteYes: undefined as string,
    Uploader_Upload_OverwriteNo: undefined as string,
    Categories_Description: undefined as string,
    Categories_TermStoreLink: undefined as string,
    Categories_Unsync: undefined as string,
    Categories_UnsyncMessage: undefined as string,
    Categories_UnsyncConfirm: undefined as string,
    Categories_UnsyncWarning: undefined as string,
    Categories_UnsyncButton: undefined as string,
    Categories_SyncButton: undefined as string,
    Categories_SelectTermGroup: undefined as string,
    Categories_SelectTermSet: undefined as string,
    Categorier_SyncCategories: undefined as string,
    Categories_NoCategories: undefined as string,
    OrgAssetsAdmin_Title: undefined as string,
    OrgAssetsAdmin_Description1: undefined as string,
    OrgAssetsAdmin_Description2: undefined as string,
    OrgAssetsAdmin_Description3: undefined as string,
    OrgAssetsAdmin_Description4: undefined as string,
    OrgAssetsAdmin_Description5: undefined as string,
    OrgAssetsAdmin_SelectLibraryType: undefined as string,
    OrgAssetsAdmin_ImageDocumentLibrary: undefined as string,
    OrgAssetsAdmin_OfficeTemplateLibrary: undefined as string,
    OrgAssetsAdmin_Field_LibraryName: undefined as string,
    OrgAssetsAdmin_Field_LibraryName_Placeholder: undefined as string,
    OrgAssetsAdmin_Field_LibraryUrl: undefined as string,
    OrgAssetsAdmin_Field_LibraryUrl_Placeholder: undefined as string,
    OrgAssetsAdmin_Field_ThumbnailUrl: undefined as string,
    OrgAssetsAdmin_Field_ThumbnailUrl_Placeholder: undefined as string,
    OrgAssetsAdmin_Site: undefined as string,
    OrgAssetsAdmin_Add_AssetsLibrary: undefined as string,
    OrgAssetsAdmin_AssetsLibrary: undefined as string,
    OrgAssetsAdmin_ThumbnailImage: undefined as string,
    OrgAssetsAdmin_NoImage: undefined as string,
    OrgAssetsAdmin_Status_Synced: undefined as string,
    OrgAssetsAdmin_Status_Adding: undefined as string,
    OrgAssetsAdmin_Status_Updating: undefined as string,
    OrgAssetsAdmin_Status_Removing: undefined as string,
    OrgAssetsAdmin_Empty_Library: undefined as string,
    ContentCreator_Headline: undefined as string,
    ContentCreator_AddNew: undefined as string,
    ContentCreator_MyContent: undefined as string,
    ContentCreator_WaitingForApproval: undefined as string,
    ContentCreator_NoContent: undefined as string,
    ContentCreator_Modified: undefined as string,
    ContentCreator_Status: undefined as string,
    ContentCreator_Draft: undefined as string,
    ContentCreator_ApprovalStatusPending: undefined as string,
    ContentCreator_ApprovalStatusRejected: undefined as string,
    ContentCreator_Expired: undefined as string,
    ContentCreator_Pending: undefined as string,
    ContentCreator_Scheduled: undefined as string,
    ContentCreator_Published: undefined as string,
    ContentCreator_Active: undefined as string,
    ContentCreatorAdmin_SelectComponent: undefined as string,
    ContentCreatorAdmin_MessagesComponent: undefined as string,
    ContentCreatorAdmin_EventComponent: undefined as string,
    ContentCreatorAdmin_SelectInstance: undefined as string,
    ContentCreatorAdmin_SelectCategories: undefined as string,
    ContentCreatorAdmin_ItemTitle: undefined as string,
    ContentCreatorAdmin_ItemDescription: undefined as string,
    ContentCreatorAdmin_SelectIcon: undefined as string,
    ContentCreatorAdmin_NewItem: undefined as string,
    ContentCreatorAdmin_EditItem: undefined as string,
    ContentCreatorAdmin_LoadingInstances: undefined as string,
    ContentCreatorAdmin_LoadingCategories: undefined as string,
    ContentCreatorAdmin_ContentOrganizerComponent: undefined as string,
    ContentCreator_FilterPanel_Headline: undefined as string,
    ContentCreator_FilterPanel_ContentTypes: undefined as string,
    ContentCreator_FilterPanel_Instances: undefined as string,
    ContentCreator_FilterPanel_ItemStatus: undefined as string,
    ContentCreator_FilterPanel_Sort: undefined as string,
    ContentCreator_FilterPanel_Date: undefined as string,
    ContentCreator_FilterPanel_AllTime: undefined as string,
    ContentCreator_FilterPanel_Period: undefined as string,
    ContentCreator_FilterPanel_StartTime: undefined as string,
    ContentCreator_FilterPanel_EndTime: undefined as string,
    ContentCreator_PushPanel_PushTitle: undefined as string,
    ContentCreator_PushPanel_PushSubTitle: undefined as string,
    ContentCreator_PushPanel_FindPerson: undefined as string,
    ContentCreator_PushPanel_LoadMore: undefined as string,
    ContentCreator_PushPanel_SentToUser: undefined as string,
    ContentCreator_PushPanel_HasBeenReceived: undefined as string,
    ContentCreator_PushPanel_Working: undefined as string,
    ContentCreator_PushPanel_WorkingInstalling: undefined as string,
    ContentCreator_PushPanel_FailedNotInstalled: undefined as string,
    ContentCreator_PushPanel_FailedToSend: undefined as string,
    ContentCreator_PushPanel_UserNotWhitelisted: undefined as string,
    Analytics_All: undefined as string,
    Analytics_MessageWithMostViews: undefined as string,
    Analytics_NewMessagesThisWeek: undefined as string,
    Analytics_LatestDay: undefined as string,
    Analytics_LatestWeek: undefined as string,
    Analytics_LatestMonth: undefined as string,
    Analytics_SelectPeriod: undefined as string,
    Analytics_Period: undefined as string,
    Analytics_PivotMobileDevices: undefined as string,
    Analytics_Instance: undefined as string,
    Analytics_Category: undefined as string,
    Analytics_Title: undefined as string,
    Analytics_Views: undefined as string,
    Analytics_UniqueViews: undefined as string,
    Analytics_Email: undefined as string,
    Analytics_RegisteredMobileDevices: undefined as string,
    Analytics_TotalRegistredMobileDevices: undefined as string,
    Analytics_RegisteredUsersAndUnRegisteredUsers: undefined as string,
    Analytics_UnRegisteredUsers: undefined as string,
    Analytics_RegisteredUsers: undefined as string,
    Analytics_RegisteredUsersDescribtionLine1: undefined as string,
    Analytics_RegisteredUsersDescribtionLine2: undefined as string,
    Analytics_RegisteredUsersDescribtionLine3: undefined as string,
    Analytics_RegisteredUsersDescribtionLine4: undefined as string,
    Analytics_RegisteredUsersDescribtionLine5: undefined as string,
    Analytics_Instances: undefined as string,
    Analytics_Categories: undefined as string,
    Analytics_MessageWithMostUniqueViews: undefined as string,
    Analytics_Disclaimer: undefined as string,
    Analytics_StartDate: undefined as string,
    Analytics_OnlyBrowserDate: undefined as string,
    Analytics_Source: undefined as string,
    Analytics_SourceTotel: undefined as string,
    Analytics_SourceWeb: undefined as string,
    Analytics_SourceMobile: undefined as string,
    Analytics_SourceAndroid: undefined as string,
    Analytics_SourceIos: undefined as string,
    Analytics_SourceToolTip: undefined as string,
    Analytics_MessageUniqueViewsSource: undefined as string,
    Analytics_Browser: undefined as string,
    Analytics_MobileApp: undefined as string,
    Analytics_NoData: undefined as string,
    Analytics_ExportToExcel: undefined as string,

    AdvancedSettings_Description: undefined as string,
    AdvancedSettings_save: undefined as string,

    MyLinks_Title: undefined as string,
    MyLinks_AddButton: undefined as string,
    MyLinks_EditButton: undefined as string,
    MyLinks_LinkTitle: undefined as string,
    MyLinks_LinkUrl: undefined as string,
    MyLinks_PanelTitleCreate: undefined as string,
    MyLinks_PanelTitleEdit: undefined as string,
    MyLinks_DeletePopupTitle: undefined as string,
    MyLinks_DeletePopupDeteleButton: undefined as string,
    MyLinks_DeletePopupCancelButton: undefined as string,
    MyLinks_Save: undefined as string,
    MyLinks_EndEditButton: undefined as string,

    Permissions: undefined as string,
    Permissions_Description: undefined as string,
    Permissions_ApplicationsAndInstances: undefined as string,
    Permissions_Loading: undefined as string,
    Permissions_UniquePermissions: undefined as string,
    Permissions_InheritPermissions: undefined as string,
    Permissions_SpecialPermissions: undefined as string,
    Permissions_NoAccess: undefined as string,
    Permissions_ErrorWhileRequesting: undefined as string,
    Permissions_Set: undefined as string,
    Permissions_NotSet: undefined as string,
    Permissions_InheritPermissionsQuestion: undefined as string,
    Permissions_Owner: undefined as string,
    Permissions_Member: undefined as string,
    Permissions_Reader: undefined as string,
    Permissions_Owners: undefined as string,
    Permissions_Members: undefined as string,
    Permissions_Readers: undefined as string,
    Permissions_Remove: undefined as string,
    Permissions_AddUniquePermissions: undefined as string,
    Permissions_AddingUniquePermissions: undefined as string,
    Permissions_RemovingUniquePermissions: undefined as string,
    Permissions_AddPlaceholder: undefined as string,
    Permissions_AddButton: undefined as string,
    Permissions_AdminTooltip: undefined as string,
    Permissions_SpecialPermissionsTooltip: undefined as string,
    Permissions_AddUniquePermissionsTooltip: undefined as string,
    Permissions_PanelAdministratorAdminTooltip: undefined as string,
    Permissions_PanelEditorAdminTooltip: undefined as string,
    Permissions_PanelReaderAdminTooltip: undefined as string,
    Permissions_PanelAdministratorAppAdminTooltip: undefined as string,
    Permissions_PanelEditorAppAdminTooltip: undefined as string,
    Permissions_PanelReaderAppAdminTooltip: undefined as string,
    Permissions_PanelAdministratorEventsTooltip: undefined as string,
    Permissions_PanelEditorEventsTooltip: undefined as string,
    Permissions_PanelReaderEventsTooltip: undefined as string,
    Permissions_PanelAdministratorNavigatorTooltip: undefined as string,
    Permissions_PanelEditorNavigatorTooltip: undefined as string,
    Permissions_PanelReaderNavigatorTooltip: undefined as string,
    Permissions_PanelAdministratorInfoscreenTooltip: undefined as string,
    Permissions_PanelEditorInfoscreenTooltip: undefined as string,
    Permissions_PanelReaderInfoscreenTooltip: undefined as string,
    Permissions_PanelAdministratorToolboxTooltip: undefined as string,
    Permissions_PanelEditorToolboxTooltip: undefined as string,
    Permissions_PanelReaderToolboxTooltip: undefined as string,
    Permissions_PanelAdministratorMessagesTooltip: undefined as string,
    Permissions_PanelEditorMessagesTooltip: undefined as string,
    Permissions_PanelReaderMessagesTooltip: undefined as string,
    Permissions_PanelAdministratorContentCreatorTooltip: undefined as string,
    Permissions_PanelEditorContentCreatorTooltip: undefined as string,
    Permissions_PanelReaderContentCreatorTooltip: undefined as string,
    Permissions_ErrorForbidden: undefined as string,
    Permissions_ErrorUnknown: undefined as string,

    Admin_Admin: undefined as string,
    Admin_Messages: undefined as string,
    Admin_MobileApp: undefined as string,
    Admin_Events: undefined as string,
    Admin_Infoscreen: undefined as string,
    Admin_Toolbox: undefined as string,
    Admin_Navigator: undefined as string,
    Admin_ContentCreator: undefined as string,
    Admin_ContentOrganizer: undefined as string,

    AppAdmin_DesignTab: undefined as string,
    AppAdmin_ContentTab: undefined as string,
    AppAdmin_NavigationBarColor: undefined as string,
    AppAdmin_NavigationBarContentColor: undefined as string,
    AppAdmin_Logo: undefined as string,
    AppAdmin_LogoHelpText: undefined as string,
    AppAdmin_TabBarColor: undefined as string,
    AppAdmin_TabBarContentColor: undefined as string,
    AppAdmin_TabBarHighlightColor: undefined as string,
    AppAdmin_MenuStyle: undefined as string,
    AppAdmin_MenuColor: undefined as string,
    AppAdmin_MenuContentColor: undefined as string,
    AppAdmin_MenuHighlightColor: undefined as string,
    AppAdmin_ContentAreaColor: undefined as string,
    AppAdmin_ContentAreaContentColor: undefined as string,
    AppAdmin_ContentAreaHighlightColor: undefined as string,
    AppAdmin_TabBar: undefined as string,
    AppAdmin_ContentArea: undefined as string,
    AppAdmin_NavigationBar: undefined as string,
    AppAdmin_Menu: undefined as string,
    AppAdmin_BurgerMenu: undefined as string,
    AppAdmin_MenuType: undefined as string,
    AppAdmin_GeneralTab: undefined as string,
    AppAdmin_UseAppSettings: undefined as string,
    AppAdmin_Save: undefined as string,
    AppAdmin_Add: undefined as string,
    AppAdmin_Edit: undefined as string,
    AppAdmin_Settings: undefined as string,
    AppAdmin_Title: undefined as string,
    AppAdmin_NewItem: undefined as string,
    AppAdmin_EditItem: undefined as string,
    AppAdmin_ComponentMessages: undefined as string,
    AppAdmin_ComponentEvents: undefined as string,
    AppAdmin_ComponentEmbeddedPage: undefined as string,
    AppAdmin_ComponentExternalLink: undefined as string,
    AppAdmin_ComponentNavigator: undefined as string,
    AppAdmin_SelectComponent: undefined as string,
    AppAdmin_MenuItemDesign: undefined as string,
    AppAdmin_MenuItemConfiguration: undefined as string,
    AppAdmin_Icon: undefined as string,
    AppAdmin_SelectDisplay: undefined as string,
    AppAdmin_MenuItemUrl: undefined as string,
    AppAdmin_Preview: undefined as string,
    AppAdmin_ShowTopBar: undefined as string,
    AppAdmin_Layout: undefined as string,
    AppAdmin_ShowTitle: undefined as string,
    AppAdmin_CardView: undefined as string,
    AppAdmin_ListView: undefined as string,
    AppAdmin_RotatorView: undefined as string,
    AppAdmin_NumberOfMessages: undefined as string,
    AppAdmin_ShowFilter: undefined as string,
    AppAdmin_ShowSearch: undefined as string,
    AppAdmin_BackgroundColor: undefined as string,
    AppAdmin_BorderColor: undefined as string,
    AppAdmin_TextColor: undefined as string,
    AppAdmin_ShowShadow: undefined as string,
    AppAdmin_ShowImage: undefined as string,
    AppAdmin_ShowTeaser: undefined as string,
    AppAdmin_ShowContact: undefined as string,
    AppAdmin_ShowStartdate: undefined as string,
    AppAdmin_ShowLikes: undefined as string,
    AppAdmin_ShowComments: undefined as string,
    AppAdmin_ShowViews: undefined as string,
    AppAdmin_ShowFunctionButton: undefined as string,
    AppAdmin_ShowCategories: undefined as string,
    AppAdmin_Card: undefined as string,
    AppAdmin_List: undefined as string,
    AppAdmin_Filterring: undefined as string,
    AppAdmin_FilterByCategory: undefined as string,
    AppAdmin_ActivateTargeting: undefined as string,
    AppAdmin_DateBadgeBackgroundColor: undefined as string,
    AppAdmin_DateBadgeTextColor: undefined as string,
    AppAdmin_ShowLocation: undefined as string,
    AppAdmin_SelectPeriod: undefined as string,
    AppAdmin_PeriodeUpcoming: undefined as string,
    AppAdmin_PeriodeMonth: undefined as string,
    AppAdmin_PeriodeWeek: undefined as string,
    AppAdmin_PeriodeDay: undefined as string,
    AppAdmin_PeriodeFullView: undefined as string,
    AppAdmin_ComponentPhonebook: undefined as string,
    AppAdmin_ComponentBirthday: undefined as string,
    AppAdmin_MenuItemSiteCollection: undefined as string,
    AppAdmin_MenuItemResultSource: undefined as string,
    AppAdmin_ShowAdd: undefined as string,
    AppAdmin_PageTitle: undefined as string,
    AppAdmin_MenuItemContent: undefined as string,
    AppAdmin_ComponentTitle: undefined as string,
    AppAdmin_NumberOfDays: undefined as string,
    AppAdmin_BirthdayIcon: undefined as string,
    AppAdmin_BirthdayOptionBirthday: undefined as string,
    AppAdmin_BirthdayOptionAnniversary: undefined as string,
    AppAdmin_SelectBirthdayType: undefined as string,
    AppAdmin_BirthdayPropertyPhone: undefined as string,
    AppAdmin_BirthdayPropertyJobTitle: undefined as string,
    AppAdmin_BirthdayPropertyLocation: undefined as string,
    AppAdmin_BirthdayPropertyDepartment: undefined as string,
    AppAdmin_BirthdayPropertyCompany: undefined as string,
    AppAdmin_BirthdayPropertyEmail: undefined as string,
    AppAdmin_BirthdayPropertyChat: undefined as string,
    AppAdmin_BirthdayProperties: undefined as string,
    AppAdmin_SiteCollectionPlaceholder: undefined as string,
    AppAdmin_ResultSourcePlaceholder: undefined as string,
    AppAdmin_Cancel: undefined as string,
    AppAdmin_AddPage: undefined as string,
    AppAdmin_ShowNavigator: undefined as string,
    AppAdmin_SortPages: undefined as string,
    AppAdmin_PushSettings: undefined as string,
    AppAdmin_PushSettingsDescription: undefined as string,
    AppAdmin_PushSettingsDescriptionDeactivated: undefined as string,
    AppAdmin_NoPageTitle: undefined as string,
    AppAdmin_LoadLinksExternally: undefined as string,
    AppAdmin_SplashScreen: undefined as string,
    AppAdmin_PreviewSplashScreen: undefined as string,
    AppAdmin_SplashScreenUpload: undefined as string,
    AppAdmin_SplashScreenInfo: undefined as string,
    AppAdmin_CustomIcon: undefined as string,
    AppAdmin_CustomIconInfo: undefined as string,
    AppAdmin_CustomIconUpload: undefined as string,
    AppAdmin_CustomIconInfo2: undefined as string,
    AppAdmin_ComponentContentMap: undefined as string,
    AppAdmin_ComponentYears: undefined as string,
    AppAdmin_ComponentAnniversaryIntervals: undefined as string,
    AppAdmin_ContentOrganizerPortal: undefined as string,
    AppAdmin_SelectAllContentOrganizers: undefined as string,
    AppAdmin_SelectContentOrganizers: undefined as string,
    AppAdmin_ContentOrganizerPortalSearchPlaceholder: undefined as string,
    AppAdmin_ContentOrganizerSearch: undefined as string,
    AppAdmin_OnlyShowIfThereIsContent: undefined as string,
    AppAdmin_TabBarHelpText: undefined as string,
    AppAdmin_SharepointPagesCanNotBeEmbedded: undefined as string,

    ContentOrganizer_FooterNextButton: undefined as string,
    ContentOrganizer_FooterBackButton: undefined as string,
    ContentOrganizer_FooterCreateButton: undefined as string,
    ContentOrganizer_FooterUpdateButton: undefined as string,
    ContentOrganizer_FooterCancelButton: undefined as string,
    ContentOrganizer_PlaceholderNone: undefined as string,
    ContentOrganizer_ConfigTitle: undefined as string,
    ContentOrganizer_ConfigDescription: undefined as string,
    ContentOrganizer_ConfigSelect: undefined as string,
    ContentOrganizer_ConfigSelectDescription: undefined as string,
    ContentOrganizer_ConfigDocumentLibraries: undefined as string,
    ContentOrganizer_ConfigLoadingDocumentLibraries: undefined as string,
    ContentOrganizer_ConfigSelectDocumentLibraries: undefined as string,
    ContentOrganizer_ConfigProvisionedDocumentLibraries: undefined as string,

    ContentOrganizer_PermissionsTargetingTitle: undefined as string,
    ContentOrganizer_PermissionsTargetingDescription: undefined as string,
    ContentOrganizer_PermissionsTargetingSelect: undefined as string,
    ContentOrganizer_PermissionsTargetingSelectDescription: undefined as string,
    ContentOrganizer_PermissionsTargetingSetupPermissions: undefined as string,
    ContentOrganizer_PermissionsInheritContentOrganizerAppPermissions: undefined as string,
    ContentOrganizer_PermissionsInheritSharePointSitePermissions: undefined as string,
    ContentOrganizer_EnablePermissionsInfo: undefined as string,
    ContentOrganizer_RequiredPermissionsInfo: undefined as string,

    ContentOrganizer_PermissionsOwners: undefined as string,
    ContentOrganizer_PermissionsMembers: undefined as string,
    ContentOrganizer_PermissionsReaders: undefined as string,

    ContentOrganizer_OptionsCreateTitle: undefined as string,
    ContentOrganizer_OptionsCreateDescription: undefined as string,
    ContentOrganizer_OptionsCreateNew: undefined as string,
    ContentOrganizer_OptionsCreateNewDescription: undefined as string,
    ContentOrganizer_OptionsCreateSelect: undefined as string,
    ContentOrganizer_OptionsCreateSelectDescription: undefined as string,
    ContentOrganizer_CreateTitle: undefined as string,
    ContentOrganizer_CreateDescription: undefined as string,
    ContentOrganizer_TemplateDescriptionHandbook: undefined as string,
    ContentOrganizer_TemplateDescriptionBasicTemplate: undefined as string,
    ContentOrganizer_SelectTemplate: undefined as string,
    ContentOrganizer_SelectSiteCollection: undefined as string,
    ContentOrganizer_Handbook: undefined as string,
    ContentOrganizer_BasicTemplate: undefined as string,
    ContentOrganizer_SelectNavigator: undefined as string,
    ContentOrganizer_SelectHubSite: undefined as string,
    ContentOrganizer_Title: undefined as string,
    ContentOrganizer_SiteCollection: undefined as string,
    ContentOrganizer_Available: undefined as string,
    ContentOrganizer_Unavailable: undefined as string,
    ContentOrganizer_HasSpecialCharacters: undefined as string,
    ContentOrganizer_Description: undefined as string,
    ContentOrganizer_SelectLanguage: undefined as string,
    ContentOrganizer_English: undefined as string,
    ContentOrganizer_Danish: undefined as string,
    ContentOrganizer_German: undefined as string,
    ContentOrganizer_Swedish: undefined as string,
    ContentOrganizer_Portuguese: undefined as string,

    ContentOrganizer_SiteOwner: undefined as string,
    ContentOrganizer_TimeZone: undefined as string,
    ContentOrganizer_SelectTerms: undefined as string,

    ContentOrganizer_RequireContentApproval: undefined as string,
    ContentOrganizer_AddAproval: undefined as string,
    ContentOrganizer_UseMetadata: undefined as string,
    ContentOrganizer_Required: undefined as string,
    ContentOrganizer_UseRevision: undefined as string,
    ContentOrganizer_RevisionDays: undefined as string,
    ContentOrganizer_UsePageResponsible: undefined as string,
    ContentOrganizer_EditPanel_AddingAPageTo: undefined as string,
    ContentOrganizer_EditPanel_EditingPage: undefined as string,
    ContentOrganizer_EditPanel_CreatePage: undefined as string,

    ContentOrganizer_SelectCreateContentOrganizer: undefined as string,
    ContentOrganizer_CreateContentOrganizer: undefined as string,
    ContentOrganizer_ConfigContentOrganizer: undefined as string,
    ContentOrganizer_PermissionAndTargeting: undefined as string,
    ContentOrganizer_SiteSelectionPlaceHolder: undefined as string,
    ContentOrganizer_showMore: undefined as string,
    ContentOrganizer_notRequiredPermissionsOnSelectedSite: undefined as string,

    ContentOrganizer_PermissionIssueNotContentOrganizerSiteOwner: undefined as string,

    ContentOrganizerAdminInstance_Yes: undefined as string,
    ContentOrganizerAdminInstance_No: undefined as string,
    ContentOrganizerAdminInstance_Title: undefined as string,
    ContentOrganizerAdminInstance_URLAddress: undefined as string,
    ContentOrganizerAdminInstance_Description: undefined as string,
    ContentOrganizerAdminInstance_Timezone: undefined as string,
    ContentOrganizerAdminInstance_HubAssociation: undefined as string,
    ContentOrganizerAdminInstance_Language: undefined as string,
    ContentOrganizerAdminInstance_SiteAdministrator: undefined as string,
    ContentOrganizerAdminInstance_Template: undefined as string,
    ContentOrganizerAdminInstance_UseTags: undefined as string,
    ContentOrganizerAdminInstance_UseRevisionDate: undefined as string,
    ContentOrganizerAdminInstance_UseContentResponsible: undefined as string,
    ContentOrganizerAdminInstance_Navigator: undefined as string,
    ContentOrganizerAdminInstance_Info: undefined as string,
    ContentOrganizerAdminInstance_CompletedWithErrors: undefined as string,
    ContentOrganizerAdminInstance_SetupPermissionsError: undefined as string,
    ContentOrganizerAdminInstance_FeaturedImage: undefined as string,
    ContentOrganizerAdminInstance_FeaturedImageSize: undefined as string,
    ContentOrganizerAdminInstance_CannotLoadData: undefined as string,
    ContentOrganizerAdminInstance_HealthStatusSiteUrlSyncRequired: undefined as string,
    ContentOrganizerAdminInstance_HealthStatusFixIssue: undefined as string,
    ContentOrganizerAdminInstance_SiteUrlSyncSiteSelectorFieldDescription: undefined as string,
    ContentOrganizerAdminInstance_SiteUrlSyncCompleted: undefined as string,

    ContentOrganizerPortal_ShowMoreResults: undefined as string,
    ContentOrganizerPortal_NoResults: undefined as string,
    ContentOrganizerPortal_LastUpdated: undefined as string,
    ContentOrganizerPortal_Handbook: undefined as string,
    ContentOrganizerPortal_Basic: undefined as string,
    ContentOrganizerPortal_PreviousPage: undefined as string,
    ContentOrganizerPortal_NextPage: undefined as string,
    ContentOrganizerPortal_CategorySites: undefined as string,
    ContentOrganizerPortal_CategoryPages: undefined as string,
    ContentOrganizerPortal_CategoryAssets: undefined as string,
    ContentOrganizerPortal_CategoryFiles: undefined as string,

    CommentsAndLikes_AddComment: undefined as string,

    ProgressView_Title: undefined as string,
    ProgressView_Description: undefined as string,
    ProgressView_RetryButton: undefined as string,

    ProgressViewListItem_CreateInstance: undefined as string,
    ProgressViewListItem_CreateSite: undefined as string,
    ProgressViewListItem_InstallApps: undefined as string,
    ProgressViewListItem_SetupPermissions: undefined as string,
    ProgressViewListItem_ApplySiteDesign: undefined as string,
    ProgressViewListItem_CreateUpdateSite: undefined as string,
    ProgressViewListItem_SetupPage: undefined as string,
    ProgressViewListItem_SetupPageTemplate: undefined as string,
    ProgressViewListItem_HubSiteAssociation: undefined as string,

    TargetingApplication_EnableTargetingInfo: undefined as string,

    App_More: undefined as string,
    App_LogOut: undefined as string,
    App_SwitchApp: undefined as string,
    App_SelectAppIcon: undefined as string,

    ContentMap_AddNewHeader: undefined as string,
    ContentMap_EditModeOn: undefined as string,
    ContentMap_Save: undefined as string,
    ContentMap_Cancel: undefined as string,
    ContentMap_Title: undefined as string,
    ContentMap_GeneralSettings: undefined as string,
    ContentMap_Layout: undefined as string,
    ContentMap_SelectInstance: undefined as string,
    ContentMap_ShowTitle: undefined as string,
    ContentMap_ShowLine: undefined as string,
    ContentMap_ShowIconOnItems: undefined as string,
    ContentMap_SelectDefaultIcon: undefined as string,
    ContentMap_BackgroundColor: undefined as string,
    ContentMap_LineColor: undefined as string,
    ContentMap_TextColor: undefined as string,
    ContentMap_DropShadow: undefined as string,
    ContentMap_HighlightColor: undefined as string,
    ContentMap_AmountOfFirstLevelItemsToShow: undefined as string,
    ContentMap_IconColor: undefined as string,
    ContentMap_AmountOfColumns: undefined as string,
    ContentMap_ExpandAsDefault: undefined as string,
    ContentMap_ForgotToSave: undefined as string,
    ContentMap_ForgotToSaveMessage: undefined as string,
    ContentMap_ForgotToSaveButtonSave: undefined as string,
    ContentMap_ForgotToSaveButtonCancel: undefined as string,

    TreeLink_Minimize: undefined as string,
    TreeLink_SeeAll: undefined as string,
    Messages_ImageUpload: undefined as string,
    Messages_Insert: undefined as string,
    Messages_Cancel: undefined as string,

    TreeLink_Cancel: undefined as string,
    TreeLink_Create: undefined as string,
    TreeLink_EditButton: undefined as string,
    TreeLink_Address: undefined as string,
    TreeLink_DisplayName: undefined as string,
    TreeLink_SelectIcon: undefined as string,
    TreeLink_LinkTo: undefined as string,
    TreeLink_NewPage: undefined as string,
    TreeLink_ExistingContent: undefined as string,
    TreeLink_NewTab: undefined as string,
    TreeLink_Demote: undefined as string,
    TreeLink_Promote: undefined as string,
    TreeLink_Remove: undefined as string,
    TreeLink_MoveDown: undefined as string,
    TreeLink_MoveUp: undefined as string,
    TreeLink_Edit: undefined as string,
    TreeLink_AddNew: undefined as string,
    TreeLink_HeaderPlaceholder: undefined as string,
    TreeLink_Description: undefined as string,
    TreeLink_Image: undefined as string,
    TreeLink_Text: undefined as string,

    ContentOrganizerGovernance_Headline: undefined as string,
    ContentOrganizerGovernance_Title: undefined as string,
    ContentOrganizerGovernance_ContentOrganizer: undefined as string,
    ContentOrganizerGovernance_ContentResponsible: undefined as string,
    ContentOrganizerGovernance_RevisionDate: undefined as string,
    ContentOrganizerGovernance_LastModified: undefined as string,
    ContentOrganizerGovernance_LastModifiedBy: undefined as string,
    ContentOrganizerGovernance_FilterPanel_State: undefined as string,
    ContentOrganizerGovernance_FilterPanel_ContentOrganizer: undefined as string,
    ContentOrganizerGovernance_FilterPanel_ModifiedDate: undefined as string,
    ContentOrganizerGovernance_FilterPanel_ContentResponsible: undefined as string,
    ContentOrganizerGovernance_NoContent: undefined as string,
    ContentOrganizerGovernance_NoContentTooltip: undefined as string,

    FileUploader_ChooseFiles: undefined as string,
    FileUploader_DuplicateFile: undefined as string,
    FileUploader_FileTooBig: undefined as string,
    PeopleFinder_ShowMore: undefined as string,
    PeopleFinder_Results: undefined as string,
    PeopleFinder_ResultOf: undefined as string,
    PeopleFinder_Colleagues: undefined as string,
    PeopleFinder_Filter: undefined as string,
    PeopleFinder_FindColleagues: undefined as string,
    PeopleFinder_JobTitleLabel: undefined as string,
    PeopleFinder_Department: undefined as string,
    PeopleFinder_Suggested: undefined as string,
    PeopleFinder_NothingWasFound: undefined as string,

    Composer_OneMoreImage: undefined as string,
    Composer_MoreImages: undefined as string,
    Composer_DropImagesHere: undefined as string,
    Composer_SelectAFolderInYourOrgAssets: undefined as string,
    Composer_SelectFromMediaLibrary: undefined as string,
    Composer_TypeHere: undefined as string,
    Composer_UploadImageOrPickOne: undefined as string,
    Composer_DropAnImageHere: undefined as string,
    Composer_SelectImageSource: undefined as string,
    Composer_Upload: undefined as string,
    Composer_FromALink: undefined as string,
    Composer_FromOrgAssets: undefined as string,
    Composer_UploadToOrgAssets: undefined as string,
    Composer_UploadImageToFolder: undefined as string,
    Composer_URL: undefined as string,
    Composer_ImageURL: undefined as string,
    Composer_ALTText: undefined as string,
    Composer_URLLink: undefined as string,
    Composer_Format: undefined as string,
    Composer_Horizontal: undefined as string,
    Composer_Vertical: undefined as string,
    Composer_Custom: undefined as string,
    Composer_Width: undefined as string,
    Composer_Uploading: undefined as string,
    Composer_SelectSourceOfGallery: undefined as string,
    Composer_SelectAGalleryFolder: undefined as string,
    Composer_UploadImagesToFolder: undefined as string,
    Composer_SelectGalleryType: undefined as string,
    Composer_Tiles: undefined as string,
    Composer_Collage: undefined as string,
    Composer_Rotator: undefined as string,
    Composer_NumberOfVisibleImages: undefined as string,
    Composer_GalleryImages: undefined as string,
    Composer_VideoInfo: undefined as string,
    Composer_SelectVideoSource: undefined as string,
    Composer_UploadVideoToFolder: undefined as string,
    Composer_AddComponent: undefined as string,
    Composer_ComponentSettings: undefined as string,
    Composer_Paragraph: undefined as string,
    Composer_Heading: undefined as string,
    Composer_Image: undefined as string,
    Composer_Gallery: undefined as string,
    Composer_Video: undefined as string,
    Composer_DropVideoHere: undefined as string,
    Composer_AddFromALink: undefined as string,
    Composer_PasteAVideoURL: undefined as string,
    Composer_CoverInfo: undefined as string,
    Composer_SelectTypeToEdit: undefined as string,
    Composer_ContactsInfo: undefined as string,
    Composer_SubjectsInfo: undefined as string,
    Composer_SubjectsPlaceholderText: undefined as string,
    Composer_TitlePlaceholderText: undefined as string,
    Composer_ToolboxContentTab: undefined as string,
    Composer_ToolboxSettingsTab: undefined as string,
    Composer_AuthorsPlaceholderText: undefined as string,
    Composer_TabCompose: undefined as string,
    Composer_TabSettings: undefined as string,
    Composer_TabPreview: undefined as string,
    Composer_SelectPosterSource: undefined as string,
    Composer_PosterInfo: undefined as string,
    Composer_ImageInfo: undefined as string,
    Composer_StockPhotos: undefined as string,
    Composer_AllowLikes: undefined as string,
    Composer_AllowComments: undefined as string,
    Composer_TypeHeadingHere: undefined as string,
    Composer_AttachmentsPlaceholderText: undefined as string,
    Composer_AttachmentsInfo: undefined as string,
    Composer_PublishButton: undefined as string,
    Composer_DraftButton: undefined as string,
    Composer_PublishChangesButton: undefined as string,
    Composer_SaveAndUnpublishButton: undefined as string,
    Composer_LinkText: undefined as string,
    Composer_OpenInNewTab: undefined as string,
    Composer_AddLink: undefined as string,
    Composer_AddSubjects: undefined as string,
    Composer_AddContent: undefined as string,
    Composer_Attach: undefined as string,
    Composer_ImageFormatError: undefined as string,
    Composer_ImageUploadSizeError: undefined as string,
    Composer_ImageUploadGenericError: undefined as string,
    Composer_VideoFormatError: undefined as string,
    Composer_VideoUploadGenericError: undefined as string,
    Composer_VideoUploadSizeError: undefined as string,
    Composer_TextSizeParagraph: undefined as string,
    Composer_TextSizeHeading1: undefined as string,
    Composer_TextSizeHeading2: undefined as string,
    Composer_UploadImageToFolderNoContent: undefined as string,
    Composer_OnlyShowCoverImageInRollup: undefined as string,
    Composer_QuickGuide: undefined as string,
    Composer_Tips: undefined as string,
    Composer_SendPush: undefined as string,
    Composer_Table: undefined as string,
    Composer_AddRow: undefined as string,
    Composer_AddColumn: undefined as string,
    Composer_DeleteSelectedRow: undefined as string,
    Composer_DeleteSelectedColumn: undefined as string,
    Composer_TableSettings: undefined as string,
    Composer_RowSettings: undefined as string,
    Composer_CellSettings: undefined as string,
    Composer_InsertLinkIntoTable: undefined as string,
    Composer_BorderColor: undefined as string,
    Composer_FontStyle: undefined as string,
    Composer_BackgroundColor: undefined as string,
    Composer_TextColor: undefined as string,
    Composer_WeAreWorkingOnIt: undefined as string,
    Composer_UploadingImages: undefined as string,

    Composer_ContentInfo: undefined as string,
    Composer_SettingsInfo: undefined as string,
    Composer_SettingsInfoBullet1: undefined as string,
    Composer_SettingsInfoBullet2: undefined as string,
    Composer_SettingsInfoBullet3: undefined as string,
    Composer_SettingsInfoBullet4: undefined as string,
    Composer_PreviewInfo: undefined as string,
    Composer_Tip1Headline: undefined as string,
    Composer_Tip2Headline: undefined as string,
    Composer_Tip3Headline: undefined as string,
    Composer_Tip4Headline: undefined as string,
    Composer_Tip5Headline: undefined as string,
    Composer_Tip1: undefined as string,
    Composer_Tip2: undefined as string,
    Composer_Tip3: undefined as string,
    Composer_Tip4: undefined as string,
    Composer_Tip5: undefined as string,

    Viewer_MissingTitle: undefined as string,

    StockPhotos_SearchPlaceholder: undefined as string,
    StockPhotos_Showing: undefined as string,
    StockPhotos_OutOf: undefined as string,
    StockPhotos_Photos: undefined as string,
    StockPhotos_ResultsForTheSearch: undefined as string,
    StockPhotos_LoadMore: undefined as string,
    StockPhotos_NoImagesForTheResult: undefined as string,
    PlayAdmin_MenuArea_Players: undefined as string,
    PlayAdmin_MenuArea_Content: undefined as string,
    PlayAdmin_MenuArea_Slides: undefined as string,
    PlayAdmin_MenuArea_Playlists: undefined as string,
    PlayAdmin_MenuArea_MediaFiles: undefined as string,
    PlayAdmin_MenuArea_Settings: undefined as string,
    PlayAdmin_MenuArea_GetStarted: undefined as string,
    PlayAdmin_MenuArea_Collapse: undefined as string,
    PlayAdmin_NewPlayer: undefined as string,
    PlayAdmin_PlayerStateRunning: undefined as string,
    PlayAdmin_PlayerStateNew: undefined as string,
    PlayAdmin_PlayerStateFailing: undefined as string,
    PlayAdmin_PlayerTitle: undefined as string,
    PlayAdmin_PlayerTitlePlaceholder: undefined as string,
    PlayAdmin_PlayerSettings: undefined as string,
    PlayAdmin_SelectPlaylist: undefined as string,
    PlayAdmin_NoPlaylists: undefined as string,
    PlayAdmin_MenuArea_PlaylistsSettings: undefined as string,
    PlayAdmin_PlaylistShowFooter: undefined as string,
    PlayAdmin_PlaylistTirle: undefined as string,
    PlayAdmin_NoPlaylistSelected: undefined as string,
    PlayAdmin_MenuArea_WidgetSettings: undefined as string,
    PlayAdmin_MenuArea_SlideSettings: undefined as string,
    PlayAdmin_MenuArea_SlideRss: undefined as string,
    PlayAdmin_MenuArea_SlideWidgets: undefined as string,
    PlayAdmin_PlayerSize: undefined as string,
    PlayAdmin_PlayerPosition: undefined as string,
    PlayAdmin_PlayerCity: undefined as string,
    PlayAdmin_Save: undefined as string,
    PlayAdmin_Crossfade: undefined as string,
    PlayAdmin_Slide: undefined as string,
    PlayAdmin_TransitionType: undefined as string,
    PlayAdmin_SlideLayout: undefined as string,
    PlayAdmin_LayoutOneColumn: undefined as string,
    PlayAdmin_LayoutTwoColumns: undefined as string,
    PlayAdmin_LayoutRightSideTwoRows: undefined as string,
    PlayAdmin_LayoutLeftAndRightSideTwoRows: undefined as string,
    PlayAdmin_ShowFooter: undefined as string,
    PlayAdmin_Duration: undefined as string,
    PlayAdmin_RssUrl: undefined as string,
    PlayAdmin_RssEnabled: undefined as string,
    PlayAdmin_BackgroundColor: undefined as string,
    PlayAdmin_Color: undefined as string,
    PlayAdmin_RssLabelText: undefined as string,
    PlayAdmin_RssLabelLogo: undefined as string,
    PlayAdmin_RssLabelBackgroundColor: undefined as string,
    PlayAdmin_RssLabelColor: undefined as string,
    PlayAdmin_RssPosition: undefined as string,
    PlayAdmin_RssPositionTop: undefined as string,
    PlayAdmin_RssPositionBottom: undefined as string,
    PlayAdmin_RssShowTime: undefined as string,
    PlayAdmin_RssMaxItems: undefined as string,
    PlayAdmin_RssLabelType: undefined as string,
    PlayAdmin_RssLabelTypeFromFeed: undefined as string,
    PlayAdmin_RssLabelTypeCustomText: undefined as string,
    PlayAdmin_RssLabelTypeImage: undefined as string,
    PlayAdmin_WidgetTypevideo23: undefined as string,
    PlayAdmin_WidgetTypeArticleViewer: undefined as string,
    PlayAdmin_WidgetTypeDrRss: undefined as string,
    PlayAdmin_WidgetTypeEmbed: undefined as string,
    PlayAdmin_WidgetTypeEventViewer: undefined as string,
    PlayAdmin_WidgetTypeGallery: undefined as string,
    PlayAdmin_WidgetTypeImage: undefined as string,
    PlayAdmin_WidgetTypeMessageViewer: undefined as string,
    PlayAdmin_WidgetTypePdf: undefined as string,
    PlayAdmin_WidgetTypePowerpoint: undefined as string,
    PlayAdmin_WidgetTypeRss: undefined as string,
    PlayAdmin_WidgetTypeRssTicker: undefined as string,
    PlayAdmin_WidgetTypeVideo: undefined as string,
    PlayAdmin_WidgetTypeVimeo: undefined as string,
    PlayAdmin_WidgetTypeYouTube: undefined as string,
    PlayAdmin_WidgetTypeWeather: undefined as string,
    PlayAdmin_MenuArea_Widgets: undefined as string,
    PlayAdmin_MenuArea_Articles: undefined as string,
    PlayAdmin_SelectWidget: undefined as string,
    PlayAdmin_SelectWidgetType: undefined as string,
    PlayAdmin_MinimumDuration: undefined as string,
    PlayAdmin_TextOverflow: undefined as string,
    PlayAdmin_TextOverflowCut: undefined as string,
    PlayAdmin_TextOverflowScroll: undefined as string,
    PlayAdmin_FontSize: undefined as string,
    PlayAdmin_FontSizeExtraSmall: undefined as string,
    PlayAdmin_FontSizeSmall: undefined as string,
    PlayAdmin_FontSizeStandard: undefined as string,
    PlayAdmin_FontSizeLarge: undefined as string,
    PlayAdmin_FontSizeExtraLarge: undefined as string,
    PlayAdmin_TitleSize: undefined as string,
    PlayAdmin_TextSize: undefined as string,
    PlayAdmin_ArticleLayoutType: undefined as string,
    PlayAdmin_ArticleLayoutTypeOnlyText: undefined as string,
    PlayAdmin_ArticleLayoutTypeTextBoxOnBackgroundImage: undefined as string,
    PlayAdmin_ArticleLayoutTypeTextLeftImageRight: undefined as string,
    PlayAdmin_ArticleLayoutTypeTextRightImageLeft: undefined as string,
    PlayAdmin_ArticleLayoutTypeTextBottomImageTop: undefined as string,
    PlayAdmin_ArticleLayoutTypetTextTopImageBottom: undefined as string,
    PlayAdmin_ArticleLayoutTypeFullText: undefined as string,
    PlayAdmin_ArticleLayoutTypeTextBox: undefined as string,
    PlayAdmin_ArticleLayoutTypeHalfText: undefined as string,
    PlayAdmin_ArticleLayoutTypeOnlyImage: undefined as string,
    PlayAdmin_ArticleLayoutTypeBoxTopLeft: undefined as string,
    PlayAdmin_ArticleLayoutTypeBoxTopRight: undefined as string,
    PlayAdmin_ArticleLayoutTypeBoxBottomLeft: undefined as string,
    PlayAdmin_ArticleLayoutTypeBoxBottomRight: undefined as string,
    PlayAdmin_ArticleBoxWidth: undefined as string,
    PlayAdmin_ArticleMargin: undefined as string,
    PlayAdmin_ShowRssTicker: undefined as string,
    PlayAdmin_SetupoRssTicker: undefined as string,
    PlayAdmin_AdministrateWidgetsOnSlide: undefined as string,
    PlayAdmin_Type: undefined as string,
    PlayAdmin_FilterByWidgetType: undefined as string,
    PlayAdmin_ViewType: undefined as string,
    PlayAdmin_ListView: undefined as string,
    PlayAdmin_CardView: undefined as string,
    PlayAdmin_FilterModifiedDescending: undefined as string,
    PlayAdmin_FilterModifiedAscending: undefined as string,
    PlayAdmin_FilterTitleAlphabeticalAscending: undefined as string,
    PlayAdmin_FilterTitleAlphabeticalDescending: undefined as string,
    PlayAdmin_Sorting: undefined as string,
    PlayAdmin_UserSorting: undefined as string,
    PlayAdmin_MyFavorites: undefined as string,
    PlayAdmin_Mine: undefined as string,
    PlayAdmin_UserSortingPlaceholder: undefined as string,
    PlayAdmin_Add: undefined as string,
    PlayAdmin_Images: undefined as string,
    PlayAdmin_Image: undefined as string,
    PlayAdmin_Article: undefined as string,
    PlayAdmin_Articles: undefined as string,
    PlayAdmin_Title: undefined as string,
    PlayAdmin_Modified: undefined as string,
    PlayAdmin_ModifiedBy: undefined as string,
    PlayAdmin_PlayerStatus: undefined as string,
    PlayAdmin_Delete: undefined as string,
    PlayAdmin_Update: undefined as string,
    PlayAdmin_Profile: undefined as string,
    PlayAdmin_Groups: undefined as string,
    PlayAdmin_Permissions: undefined as string,
    PlayAdmin_ProfileTitle: undefined as string,
    PlayAdmin_ProfileTitlePlaceholder: undefined as string,
    PlayAdmin_ProfileIcon: undefined as string,
    PlayAdmin_Progressbar: undefined as string,
    PlayAdmin_ProgressbarBackgroundColor: undefined as string,
    PlayAdmin_ProgressbarColor: undefined as string,
    PlayAdmin_Footer: undefined as string,
    PlayAdmin_FooterBackgroundColor: undefined as string,
    PlayAdmin_FooterColor: undefined as string,
    PlayAdmin_FooterShowLogo: undefined as string,
    PlayAdmin_FooterLogo: undefined as string,
    PlayAdmin_FooterShowWeather: undefined as string,
    PlayAdmin_FooterShowClock: undefined as string,
    PlayAdmin_Select: undefined as string,
    PlayAdmin_AddNewProfile: undefined as string,
    PlayAdmin_Cancel: undefined as string,
    PlayAdmin_Close: undefined as string,
    PlayAdmin_SwitchProfile: undefined as string,
    PlayAdmin_GroupTitle: undefined as string,
    PlayAdmin_GroupsOptinal: undefined as string,
    PlayAdmin_NoGroupsAdded: undefined as string,
    PlayAdmin_NoTagsAdded: undefined as string,
    PlayAdmin_Preview: undefined as string,
    PlayAdmin_YourArticles: undefined as string,
    PlayAdmin_YourArticlesDesc: undefined as string,
    PlayAdmin_DeleteArticle: undefined as string,
    PlayAdmin_DeletePopup_Yes: undefined as string,
    PlayAdmin_DeletePopup_No: undefined as string,
    PlayAdmin_ArticleSettings: undefined as string,
    PlayAdmin_ImageUrl: undefined as string,
    PlayAdmin_Tag: undefined as string,
    PlayAdmin_QRCode: undefined as string,
    PlayAdmin_Link: undefined as string,
    PlayAdmin_YourWidgets: undefined as string,
    PlayAdmin_YourWidgetsDesc: undefined as string,
    PlayAdmin_YourMediaFiles: undefined as string,
    PlayAdmin_YourMediaFilesDesc: undefined as string,
    PlayAdmin_DeleteWidget: undefined as string,
    PlayAdmin_ChooseFromMediaLibrary: undefined as string,
    PlayAdmin_VideoUrl: undefined as string,
    PlayAdmin_PdfUrl: undefined as string,
    PlayAdmin_EmbedUrl: undefined as string,
    PlayAdmin_ShowThumbnail: undefined as string,
    PlayAdmin_GalleryImages: undefined as string,
    PlayAdmin_LabelSettings: undefined as string,
    PlayAdmin_YourPlaylists: undefined as string,
    PlayAdmin_YourPlaylistsDesc: undefined as string,
    PlayAdmin_DeletePlaylist: undefined as string,
    PlayAdmin_CreateNewSlide: undefined as string,
    PlayAdmin_AddExistingSlide: undefined as string,
    PlayAdmin_YourSlides: undefined as string,
    PlayAdmin_SlidesDescription: undefined as string,
    PlayAdmin_DeleteSlide: undefined as string,
    PlayAdmin_CreateNewRssTicker: undefined as string,
    PlayAdmin_CreateNewWidget: undefined as string,
    PlayAdmin_YourPlayers: undefined as string,
    PlayAdmin_YourPlayersDesc: undefined as string,
    PlayAdmin_ArePlayersRunning: undefined as string,
    PlayAdmin_ArePlayersRunningDesc: undefined as string,
    PlayAdmin_DeletePlayer: undefined as string,
    PlayAdmin_AddGroup: undefined as string,
    PlayAdmin_AddNewTag: undefined as string,
    PlayAdmin_Settings: undefined as string,
    PlayAdmin_DeleteGroup: undefined as string,
    PlayAdmin_SelectRssTickerPosition: undefined as string,
    PlayAdmin_UserPrincipalName: undefined as string,
    PlayAdmin_DisplayName: undefined as string,
    PlayAdmin_Search: undefined as string,
    PlayAdmin_RemoveUserFromProfile: undefined as string,
    PlayAdmin_AddUser: undefined as string,
    PlayAdmin_Remove: undefined as string,
    PlayAdmin_DragAndDropYourFilesOrClickOnAddButton: undefined as string,
    PlayAdmin_Size: undefined as string,
    PlayAdmin_OverrideFileQuestion: undefined as string,
    PlayAdmin_MediaFilesNoContent: undefined as string,
    PlayAdmin_SettingsHeaderText: undefined as string,
    PlayAdmin_LoadingProfiles: undefined as string,
    PlayAdmin_MissingPermissionsText: undefined as string,
    PlayAdmin_CreateNewProfile: undefined as string,
    PlayAdmin_SelectAProfileToAccess: undefined as string,
    PlayAdmin_NoProfilesText: undefined as string,
    PlayAdmin_DeleteProfile: undefined as string,
    PlayAdmin_ConnectingPlayerText1: undefined as string,
    PlayAdmin_ConnectingPlayerText2: undefined as string,
    PlayAdmin_FileAlreadyExists: undefined as string,
    PlayAdmin_AddArticleOrSelectOne: undefined as string,
    PlayAdmin_SelectArticle: undefined as string,
    PlayAdmin_FailingPlayers: undefined as string,
    PlayAdmin_NewPlayers: undefined as string,
    PlayAdmin_AllPlayers: undefined as string,
    PlayAdmin_PlayersWithoutGroup: undefined as string,
    PlayAdmin_MediaFilesMidiaLibraryTooltip: undefined as string,
    PlayAdmin_MediaFilesUploadTooltip: undefined as string,
    PlayAdmin_MediaFilesStockPhotosTooltip: undefined as string,
    PlayAdmin_ConsentText1: undefined as string,
    PlayAdmin_ConsentText2: undefined as string,
    PlayAdmin_Consent: undefined as string,
    PlayAdmin_Reload: undefined as string,
    PlayAdmin_SomethingWentWrong: undefined as string,
    PlayAdmin_CreateNewPlaylist: undefined as string,
    PlayAdmin_FitToView: undefined as string,
    PlayAdmin_ExpiredSlides: undefined as string,
    PlayAdmin_AvailableSlides: undefined as string,
    PlayAdmin_ActiveAndScheduledSlides: undefined as string,
    PlayAdmin_Scheduled: undefined as string,
    PlayAdmin_DoItLater: undefined as string,
    PlayAdmin_SetupNow: undefined as string,
    PlayAdmin_RemoveAllExpiredSlides: undefined as string,
    PlayAdmin_LoadMore: undefined as string,
    PlayAdmin_PublishToPlaylist: undefined as string,
    PlayAdmin_PublishToArticleViewer: undefined as string,
    PlayAdmin_SetupWidgetForSelectedArea: undefined as string,
    PlayAdmin_NoWidgetSelected: undefined as string,
    PlayAdmin_CreatingNewProfile: undefined as string,
    PlayAdmin_HideTitle: undefined as string,
    PlayAdmin_Playlist: undefined as string,
    PlayAdmin_ActiveAndScheduledArticles: undefined as string,
    PlayAdmin_ExpiredArticles: undefined as string,
    PlayAdmin_RemoveAllExpiredArticles: undefined as string,
    PlayAdmin_AvailableArticles: undefined as string,
    PlayAdmin_NoContentImageUrl: undefined as string,
    PlayAdmin_MenuArea_AccountSettingsaSettings: undefined as string,
    PlayAdmin_Users: undefined as string,
    PlayAdmin_License: undefined as string,
    PlayAdmin_LicenseDescription: undefined as string,
    PlayAdmin_ProfilesAndPlayers: undefined as string,
    PlayAdmin_ProfilesAndUsers: undefined as string,
    PlayAdmin_AdminUsers: undefined as string,
    PlayAdmin_HowManyPlayers: undefined as string,
    PlayAdmin_DkkPerMonth: undefined as string,
    PlayAdmin_LastActive: undefined as string,
    PlayAdmin_DaysAgo: undefined as string,
    PlayAdmin_DayAgo: undefined as string,
    PlayAdmin_RemoveAdminUser: undefined as string,
    PlayAdmin_Profiles: undefined as string,
    PlayAdmin_Integrations: undefined as string,
    PlayAdmin_NoAvailableLicensesHeadline: undefined as string,
    PlayAdmin_NoAvailableLicensesTextForAdmins: undefined as string,
    PlayAdmin_NoAvailableLicensesTextForEditors: undefined as string,
    PlayAdmin_GoToAccountSettings: undefined as string,
    PlayAdmin_OpenATeamsChatWithAdmin: undefined as string,
    PlayAdmin_MessagesViewerShowQR: undefined as string,
    PlayAdmin_MessagesViewerInstances: undefined as string,
    PlayAdmin_ActiveInstances: undefined as string,
    PlayAdmin_AvailableInstances: undefined as string,
    PlayAdmin_CompanyInformation: undefined as string,
    PlayAdmin_CompanyName: undefined as string,
    PlayAdmin_Address: undefined as string,
    PlayAdmin_City: undefined as string,
    PlayAdmin_PostalCode: undefined as string,
    PlayAdmin_Country: undefined as string,
    PlayAdmin_VatNumber: undefined as string,
    PlayAdmin_ContactPerson: undefined as string,
    PlayAdmin_FirstName: undefined as string,
    PlayAdmin_LastName: undefined as string,
    PlayAdmin_Email: undefined as string,
    PlayAdmin_RestartPlayerSuccess: undefined as string,
    PlayAdmin_RestartPlayerFailure: undefined as string,
    PlayAdmin_BackToPlay: undefined as string,
    PlayAdmin_UsersDescription: undefined as string,
    PlayAdmin_ProfilesDescription: undefined as string,
    PlayAdmin_UpdateCompanyInformationHeadline: undefined as string,
    PlayAdmin_AuthenticationError: undefined as string,
    PlayAdmin_LiveMode: undefined as string,
    PlayAdmin_EditMode: undefined as string,
    PlayAdmin_IntegrationsDescription: undefined as string,
    PlayAdmin_IntraActiveMessages: undefined as string,
    PlayAdmin_IntraActiveEvents: undefined as string,
    PlayAdmin_AddNewIntegration: undefined as string,
    PlayAdmin_EditIntegration: undefined as string,
    PlayAdmin_SelectAFeed: undefined as string,
    PlayAdmin_IntegrationConfirmDeleteSite: undefined as string,
    PlayAdmin_ConnectIAMessagesWithPlayHeadline: undefined as string,
    PlayAdmin_ConnectIAMessagesWithPlayText: undefined as string,
    PlayAdmin_ConnectIAMessagesWithPlayIssueWithPermissionsHeadline: undefined as string,
    PlayAdmin_ConnectIAMessagesWithPlayIssueWithPermissionsText: undefined as string,
    PlayAdmin_ConnectedHeadline: undefined as string,
    PlayAdmin_ConnectedText: undefined as string,
    PlayAdmin_DeleteHeadline: undefined as string,
    PlayAdmin_DeleteText: undefined as string,
    PlayAdmin_DeletedHeadline: undefined as string,
    PlayAdmin_DeletedText: undefined as string,
    PlayAdmin_Next: undefined as string,
    PlayAdmin_Previous: undefined as string,
    PlayAdmin_SelectInstances: undefined as string,
    PlayAdmin_FeedName: undefined as string,
    PlayAdmin_SelectFeed: undefined as string,
    PlayAdmin_NoFeedSelected: undefined as string,
    PlayAdmin_BillingOption: undefined as string,
    PlayAdmin_AddACreditCard: undefined as string,
    PlayAdmin_BillingOptionInfo: undefined as string,
    PlayAdmin_ContactAdmin: undefined as string,
    PlayAdmin_CompanyInformationPopup: undefined as string,
    PlayAdmin_NoAvailableLicensesTextForAdminsOnMobile: undefined as string,
    PlayAdmin_LicenseCanNotBeSmallerThanNumberOfPlayers: undefined as string,
    PlayAdmin_Free: undefined as string,
    PlayAdmin_Connect: undefined as string,
    PlayAdmin_Feeds: undefined as string,
    PlayAdmin_SelectFeeds: undefined as string,
    PLayAdmin_AddNewFeed: undefined as string,
    PlayAdmin_GradientBackground: undefined as string,
    PlayAdmin_SolidBackground: undefined as string,
    PlayAdmin_TransparentBackground: undefined as string,
    PlayAdmin_FullSolidBackground: undefined as string,
    PlayAdmin_FullTransparentBackground: undefined as string,
    PlayAdmin_BoxTransparentBackground: undefined as string,
    PlayAdmin_BoxBackground: undefined as string,
    PlayAdmin_HorizontalPosition: undefined as string,
    PlayAdmin_VerticalPosition: undefined as string,
    PlayAdmin_ColorTheme: undefined as string,
    PlayAdmin_ContentBackground: undefined as string,
    PlayAdmin_TextColor: undefined as string,
    PlayAdmin_ColorThemes: undefined as string,
    PlayAdmin_AddColorTheme: undefined as string,
    PlayAdmin_ShowOriginalImage: undefined as string,
    PlayAdmin_AllowExternalUpload: undefined as string,
    PlayAdmin_NoFeeds: undefined as string,
    PlayAdmin_CreatingNewFeed: undefined as string,
    PlayAdmin_NoIntranetConnection: undefined as string,
    PlayAdmin_MessagesViewerShowTitle: undefined as string,
    PlayAdmin_MessagesViewerShowText: undefined as string,
    PlayAdmin_MessagesViewerShowAuthor: undefined as string,
    PlayAdmin_MessagesViewerShowDate: undefined as string,
    PlayAdmin_MessagesViewerShowImage: undefined as string,
    PlayAdmin_MessagesViewerShowVideo: undefined as string,
    PlayAdmin_MessagesViewerShowGallery: undefined as string,
    PlayAdmin_ArticleDisableForNewArticles: undefined as string,
    PlayAdmin_ArticleDisableForOldArticles: undefined as string,
    PlayAdmin_MessagesViewerShowTime: undefined as string,
    PlayAdmin_MessagesViewerShowLocation: undefined as string,
    PlayAdmin_MessagesViewerShowTimeBox: undefined as string,
    PlayAdmin_AmountOfMessagesToShow: undefined as string,
    PlayAdmin_AmountOfEventsToShow: undefined as string,
    PlayAdmin_AmountOfSharepointNewsToShow: undefined as string,
    PlayAdmin_LayoutType: undefined as string,
    PlayAdmin_LayoutTypeSlides: undefined as string,
    PlayAdmin_LayoutTypeList: undefined as string,
    PlayAdmin_Colors: undefined as string,
    PlayAdmin_BoxColor: undefined as string,
    PlayAdmin_BorderColor: undefined as string,
    PlayAdmin_TimeBatchBackgroundColor: undefined as string,
    PlayAdmin_TimeBatchTextColor: undefined as string,
    PlayAdmin_TimeBatchBorderColor: undefined as string,
    PlayAdmin_ContentSettings: undefined as string,
    PlayAdmin_Timespand: undefined as string,
    PlayAdmin_TimespandToday: undefined as string,
    PlayAdmin_TimespandWeek: undefined as string,
    PlayAdmin_TimespandMonth: undefined as string,
    PlayAdmin_ExcludeToday: undefined as string,
    PlayAdmin_NumberOfTeaserLines: undefined as string,
    PlayAdmin_ShowWidgetTitle: undefined as string,
    PlayAdmin_HeadlineColor: undefined as string,
    PlayAdmin_ArticleEditorBoxWidth: undefined as string,
    PlayAdmin_ArticleEditorBoxRoundCorners: undefined as string,
    PlayAdmin_LayoutTypeWeek: undefined as string,
    PlayAdmin_Layout: undefined as string,
    PlayAdmin_BackgroundImage: undefined as string,
    PlayAdmin_TodayColor: undefined as string,
    PlayAdmin_CanteenMenuDisclaimer: undefined as string,
    PlayAdmin_AppRegistrationSharePointNewsTitle: undefined as string,
    PlayAdmin_AppRegistrationSharePointNewsDescription: undefined as string,
    PlayAdmin_AppRegistrationSharePointNewsSiteManagementTitle: undefined as string,
    PlayAdmin_AppRegistrationSharePointNewsSiteManagementDescription: undefined as string,
    PlayAdmin_SharePointNewsSelectSites: undefined as string,
    PlayAdmin_SharePointNewsNoFeedsMessage: undefined as string,
    PlayAdmin_SharePointNewsSiteManagementAddSite: undefined as string,
    PlayAdmin_SharePointNewsSiteManagementBack: undefined as string,
    PlayAdmin_SharePointNewsSiteManagementSearch: undefined as string,
    PlayAdmin_SharePointNewsSiteManagementConsentRequiredTitle: undefined as string,
    PlayAdmin_SharePointNewsSiteManagementConsentRequiredDescription: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperHeadingConsenting: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperHeadingFinished: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperDescriptionFinished: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperDescriptionConsenting: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperDescriptionConsented: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperButtonReviewing: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperButtonFinished: undefined as string,
    PlayAdmin_AppRegistrationConsentHelperButtonDefaultProceed: undefined as string,
    PlayAdmin_GettingStartedArticlesVideoUrl: undefined as string,
    PlayAdmin_GettingStartedArticlesPosterUrl: undefined as string,
    PlayAdmin_GettingStartedSlidesVideoUrl: undefined as string,
    PlayAdmin_GettingStartedSlidesPosterUrl: undefined as string,
    PlayAdmin_GettingStartedWidgetsVideoUrl: undefined as string,
    PlayAdmin_GettingStartedWidgetsPosterUrl: undefined as string,
    PlayAdmin_GettingStartedPlaylistsVideoUrl: undefined as string,
    PlayAdmin_GettingStartedPlaylistsPosterUrl: undefined as string,
    PlayAdmin_GettingStartedPlayersVideoUrl: undefined as string,
    PlayAdmin_GettingStartedPlayersPosterUrl: undefined as string,
    PlayAdmin_HideGettingStarted: undefined as string,
    PlayAdmin_MenuArea_GettingStarted: undefined as string,
    PlayAdmin_GettingStarted: undefined as string,
    PlayAdmin_GettingStartedHeaderText: undefined as string,
    Player_NoContent: undefined as string,
    Player_NoContentUrl: undefined as string,
    Player_Expired: undefined as string,
    Player_Scheduled: undefined as string,
    Player_NotActive: undefined as string,
    Player_Now: undefined as string,
    PlayAdmin_PlayerConnected: undefined as string,
    PlayAdmin_ConnectNewPlayer: undefined as string,
    PlayAdmin_SharepointNewsWidget: undefined as string,
    PlayAdmin_ProfileAddTicker: undefined as string,
    PlayAdmin_ProfileDeleteTicker: undefined as string,
    PlayAdmin_PlaylistEditPanelAddTicker: undefined as string,
    PlayAdmin_LayoutPortraitOneRow: undefined as string,
    PlayAdmin_LayoutPortraitTwoRows: undefined as string,
    PlayAdmin_PlayerFormat: undefined as string,
    PlayAdmin_PlayerFormatPortrait: undefined as string,
    PlayAdmin_PlayerFormatLandscape: undefined as string,
    Player_TimeToNewCode: undefined as string,
    PlayAdmin_GettingStartedPlayersTitle: undefined as string,
    PlayAdmin_GettingStartedPlaylistsTitle: undefined as string,
    PlayAdmin_GettingStartedSlidesTitle: undefined as string,
    PlayAdmin_AddImage: undefined as string,
    Player_More: undefined as string,
    PlayAdmin_RSSTickerSetupText: undefined as string,
    PlayAdmin_AllMediaFiles: undefined as string,
    PlayAdmin_FilterByMimeType: undefined as string
  };

  public languageUrlTiny = 'https://intraactive-cdn.azureedge.net/scripts/tinymce/';
  public editorLanguage = '';
  public constructor() {
    let language;
    if (window.language) {
      language = window.language;
    } else {
      if (window.localStorage && !!window.localStorage.getItem("IA_language")) {
        language = window.localStorage.getItem("IA_language");
      }
    }
    const defaultLanguage = this.getLanguageFromNavigator();
    let localizedStringsEnglish;
    const localizedStrings = require(`../localization/${language ? language : defaultLanguage}.json`);
    for (const key in this.strings) {
      if (this.strings.hasOwnProperty(key)) {
        if (localizedStrings[key]) {
          // String is localized
          this.strings[key] = localizedStrings[key];
        } else {
          // String is not localized
          if (defaultLanguage !== 'English') {
            // Use English localization
            if (localizedStringsEnglish === undefined) {
              // Load English strings if not loaded already
              localizedStringsEnglish = require("../localization/English.json");
            }
            if (localizedStringsEnglish[key]) {
              this.strings[key] = localizedStringsEnglish[key];
            } else {
              // Should never reach here. Means string is not localized in language neither in English
              this.strings[key] = key;
            }
          } else {
            // Should never reach here. Means string is not localized in language neither in English
            this.strings[key] = key;
          }
        }
      }
    }
    this.editorLanguage = this.getEditorLanguage(language);
    this.languageUrlTiny = `${this.languageUrlTiny + this.editorLanguage}.js`;
  }
  public getLanguageFromNavigator(): 'Danish' | 'English' | 'Swedish' | 'German' | 'Portuguese' | 'Norwegian' {
    switch (navigator.language.substring(0, 2)) {
      case 'en':
        return 'English';
      case 'da':
        return 'Danish';
      case 'sv':
        return 'Swedish';
      case 'de':
        return 'German';
      case 'pt':
        return 'Portuguese';
      case 'nb':
        return 'Norwegian';
      default:
        return 'English';
    }
  }
  private getEditorLanguage(language: string) {
    switch (language) {
      case 'English':
        return 'en';
      case 'Danish':
        return 'da';
      case 'Swedish':
        return 'sv_SE';
      case 'Portuguese':
        return 'pt_BR';
      case 'Norwegian':
        return 'nb-NO';
      default:
        return 'en';
    }
  }

  public getFromString(key: string): string {
    let language;
    if (window.language) {
      language = window.language;
    } else {
      if (localStorage && !!localStorage.getItem("IA_language")) {
        language = localStorage.getItem("IA_language");
      }
    }
    const localizedStrings = require(`../localization/${language ? language : this.getLanguageFromNavigator()}.json`);
    if (localizedStrings[key]) {
      return localizedStrings[key];
    } else {
      return key;
    }
  }
}
