import * as React from 'react';
import * as styles from './DropDownIcon.css';
import { IAIcon } from '../icon';
import { IASpinner } from '../spinner/Spinner'

export interface IDropDownIconProps {
  icon: string;
  label?: string;
  selectedOption: string;
  options: { "key": string, "value": string, "disabled"?: boolean }[];
  styles?: any;
  color?: string;
  isLoading?: boolean;
  zIndex?: number;
  onChange: (option: string) => void;
}

export interface IDropDownIconState {
  selectedOption: string;
  showDropdown: boolean;
}

export class IADropDownIcon extends React.Component<IDropDownIconProps, IDropDownIconState> {

  constructor(props: IDropDownIconProps) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption,
      showDropdown: false
    };
    
  }

  public componentWillReceiveProps(props: IDropDownIconProps) {
    if (!props.selectedOption) {
      this.setState({ showDropdown: false });
    }
  }

  private onSelectIcon() {
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  public render(): JSX.Element {
    let options: JSX.Element[] = [];
    this.props.options.forEach((option: { "key": string, "value": string, "disabled"?: boolean }) => {
      options.push(
        <li
          role="presentation"
          id={option.key}
        >
          <button
            role="menuitem"
            tabIndex={0}
            value={option.key}
            onClick={() => {
              let selectedOption = option.key;
              this.setState({ showDropdown: false, selectedOption },
                () => {
                  this.props.onChange(option.key);
                });
            }}
          >{option.value}</button>
        </li>
      );
    })
    return (
      <div className={styles.IA_dropDownIcon}
        style={this.props.styles}
      >
        <div
          className={styles.IA_Btn}
          tabIndex={-1}
          title={this.props.icon}
          onClick={() => { this.onSelectIcon() }}
        // onKeyPress={() => { this.onSelectIcon() }}
        // onBlur={() => {this.setState({showDropdown: false})}}
        >
          {this.props.isLoading &&
            <IASpinner style={{ clear: "both", float: "left", width: "12px", height: "12px", margin: "0px 5px 0px 0px" }} />
          }
          {!this.props.isLoading && 
          <IAIcon
            title={this.props.icon}
            size={14}
            color={this.props.color}
            style={{
              float: "left",
              marginRight: "5px",
            }}
            onClick={() => { this.onSelectIcon() }}
          />}
          <div
            style={{
              float: "left",
              color: this.props.color,
              fontSize: "11px"
            }}>
            {this.props.label}
          </div>
          <IAIcon
            title="Arrow"
            size={10}
            color={this.props.color}
            style={{
              width: "12px",
              display: "inline-block",
              position: "relative",
              marginLeft: "2px",
              top: "1px",
              transform: `rotate(${this.state.showDropdown ? 90 : 0}deg)`,
              transition: "all 200ms ease-in-out"
            }}
          />
        </div>
        {
          this.state.showDropdown ?

            <ul role="menu" style={this.props.zIndex ? {zIndex: this.props.zIndex} : {}}>
              {options}
            </ul>
            : null
        }
      </div>
    );
  }

  public componentDidCatch(error) {
    if (window.AppInsights != undefined) {
      window.AppInsights.trackException(error);
    }
    // console.log("Error: " + error);
    // console.log(info);
  }
}