exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_2iVef25bTNdILnanCsqxmi {\n  position: relative;\n  float: right;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_2iVef25bTNdILnanCsqxmi .likes_3dydzhqdrDe_D5hSgpJiit {\n  height: 20px;\n  float: right;\n  position: relative;\n}\n\n.likesAndComments_2iVef25bTNdILnanCsqxmi .spinner_3AdXyKXTC0dYwSKbRCyyEN {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_3cp6pd36MNOLvjPsEewb7l 700ms linear infinite;\n  -moz-animation: spin_3cp6pd36MNOLvjPsEewb7l 700ms linear infinite;\n  animation: spin_3cp6pd36MNOLvjPsEewb7l 700ms linear infinite;\n}\n\n.likesAndComments_2iVef25bTNdILnanCsqxmi .likesText_19PkAdVG9Y3YnEWvZK8zAP {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n.likesAndComments_2iVef25bTNdILnanCsqxmi .likesTextVertical_2cqiJHrujufU2mhUNbjDa4 {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_2iVef25bTNdILnanCsqxmi",
	"likes": "likes_3dydzhqdrDe_D5hSgpJiit",
	"spinner": "spinner_3AdXyKXTC0dYwSKbRCyyEN",
	"spin": "spin_3cp6pd36MNOLvjPsEewb7l",
	"likesText": "likesText_19PkAdVG9Y3YnEWvZK8zAP",
	"likesTextVertical": "likesTextVertical_2cqiJHrujufU2mhUNbjDa4"
};