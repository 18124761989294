(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("intraactive-sdk-helper"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "intraactive-sdk-helper"], factory);
	else if(typeof exports === 'object')
		exports["index"] = factory(require("react"), require("intraactive-sdk-helper"));
	else
		root["index"] = factory(root["react"], root["intraactive-sdk-helper"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__19__) {
return 