export * from './Icon';
const library = require('./IconLibrary.json');

export class Icons {

  public static getAll(): string[] {
    const icons: string[] = [];
    library.icons.forEach((icon: { title: string, tags: string[] }) => {
      icons.push(icon.title);
    });
    return icons;
  }

  public static withTag(tag: string): string[] {
    tag = tag.toLowerCase();
    const icons: string[] = [];
    library.icons.forEach((icon: { title: string, tags: string[] }) => {
      if (icon.title.toLowerCase().indexOf(tag) > -1) {
        icons.push(icon.title);
      } else {
        for (const tagForIcon of icon.tags) {
          if (tagForIcon.toLowerCase().indexOf(tag) > -1) {
            icons.push(icon.title);
            break;
          }
        }
      }
    });
    return icons;
  }
}