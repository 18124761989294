import { IEnvironment } from "../interfaces/IEnvironment";

export class Environment {
  public static getApplicationInsightsKey(environment: IEnvironment): string {
    let key = 'a115fb3a-8a29-4418-89bc-8e774b0197cb';
    switch (environment) {
      case 'Production':
        key = '19412317-cf3b-4dea-a7e7-7961ebf47937';
        break;
      case 'Test':
        key = 'c27d5d74-dc8e-4a3d-8a7f-3217aba8ef89';
        break;
      case 'Development':
        key = 'a115fb3a-8a29-4418-89bc-8e774b0197cb';
        break;

      default:
        break;
    }
    return key;
  }

  public static getEnvironmentForPeopleFinderAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-peoplefinder-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-peoplefinder-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-peoplefinder-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-peoplefinder-functions-dev.azurewebsites.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForMobileApp(environment: IEnvironment): string {
    let apiUrl = 'http://mobile-spa.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'http://mobile-spa.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'http://mobile-spa-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'http://mobile-spa-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForMainAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-api-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-api.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://main.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-api-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForMessagesAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-messages-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://messages.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://messages.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-messages-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForEventsAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-events-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-events-functions.azurewebsites.net/';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-events-functions-test.azurewebsites.net/';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-events-functions-dev.azurewebsites.net/';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForCategoriessAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-events-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://categories.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://categories.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-categories-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForThemeAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-theme-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://theme.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://theme.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-theme-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForOrgAssetsAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-orgassets-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://orgassets.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://orgassets.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-orgassets-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForImageAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-image-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-image-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-image-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-image-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForSocialAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-social-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://social.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://social.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-social-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForTranslationAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-translate-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://translate.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://translate.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-translate-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForTeamsAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-translate-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://teams.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://teams.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-teams-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForTargetingAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-targeting-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://targeting.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://targeting.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-targeting-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForSettingsAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-settings-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://settings.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://settings.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-settings-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForContentCreatorAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-contentcreator-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://contentcreator.api.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://contentcreator.api-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-contentcreator-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForAnalyticsAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-analytics-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-analytics-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-analytics-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-analytics-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForPermissionsAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-permissions-functions-dev.azurewebsites.net/';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-permissions-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-permissions-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-permissions-functions-dev.azurewebsites.net';
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentContentOrganizerAPI(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-contentorganizer-functions-dev.azurewebsites.net/';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-contentorganizer-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-contentorganizer-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-contentorganizer-functions-dev.azurewebsites.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForAzureImages(environment: IEnvironment): string {
    let azureImagesUrl = 'https://intraactivestoragedev.blob.core.windows.net';
    switch (environment) {
      case 'Production':
        azureImagesUrl = 'https://intraactivestorage.blob.core.windows.net';
        break;
      case 'Test':
        azureImagesUrl = 'https://intraactivestoragetest.blob.core.windows.net';
        break;
      case 'Development':
        azureImagesUrl = 'https://intraactivestoragedev.blob.core.windows.net';
        break;
      default:
        break;
    }
    return azureImagesUrl;
  }

  public static getEnvironmentForBotApi(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-bot-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-bot-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-bot-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-bot-functions-dev.azurewebsites.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForLinkPortal(environment: IEnvironment): string {
    let apiUrl = 'https://link.intraactive.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://link.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://link-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://purple-mushroom-0d37c6803.azurestaticapps.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForPlayApi(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-play-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-play-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-play-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-play-functions-dev.azurewebsites.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForPlayImageFiles(environment: IEnvironment): string {
    let apiUrl = 'https://files.intraactiveplay.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://files.intraactiveplay.net';
        break;
      case 'Test':
        apiUrl = 'https://files.intraactiveplay-test.net';
        break;
      case 'Development':
        apiUrl = 'https://files.intraactiveplay-dev.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForFeedApi(environment: IEnvironment): string {
    let apiUrl = 'https://intraactive-feed-functions-dev.azurewebsites.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://intraactive-feed-functions.azurewebsites.net';
        break;
      case 'Test':
        apiUrl = 'https://intraactive-feed-functions-test.azurewebsites.net';
        break;
      case 'Development':
        apiUrl = 'https://intraactive-feed-functions-dev.azurewebsites.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForNewBotApi(environment: IEnvironment): string {
    let apiUrl = 'https://api.pling-dev.intraactive.net';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://api.pling.intraactive.net';
        break;
      case 'Test':
        apiUrl = 'https://api.pling-test.intraactive.net';
        break;
      case 'Development':
        apiUrl = 'https://api.pling-dev.intraactive.net';
        break;
      default:
        break;
    }
    return apiUrl;
  }

  public static getEnvironmentForPlayFrontend(environment: IEnvironment): string {
    let apiUrl = 'https://dev.intraactiveplay.net/';
    switch (environment) {
      case 'Production':
        apiUrl = 'https://app.intraactiveplay.net/';
        break;
      case 'Test':
        apiUrl = 'https://test.intraactiveplay.net/';
        break;
      case 'Development':
        apiUrl = 'https://dev.intraactiveplay.net/';
        break;
      default:
        break;
    }
    return apiUrl;
  }
}
