import * as React from 'react';
import * as styles from './DateAndTimePicker.css';
import { IAIcon } from '../icon';
import { LoggingService } from '../../services/LoggingService';
import './datePickerStyles/react-datepicker.css';
import DateTimeService from '../../services/DateTimeService';
import DatePicker from 'react-datepicker';
import { Helper } from '../../Helper';

export interface IDateAndTimePickerProps {
  label?: string;
  selectedDate: Date;
  showTimeSelector: boolean;
  minDate?: Date;
  maxDate?: Date;
  highlightColor?: string;
  isClearable?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  required?: boolean;
  inputWidth?: number;
  darkMode?: boolean;
  borderRadius?: number;
  inputStyle?: any;
  onChange: (date: Date) => void;
}

export interface IDateAndTimePickerState {
}

export class IADateAndTimePicker extends React.Component<IDateAndTimePickerProps, IDateAndTimePickerState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();

  constructor(props: IDateAndTimePickerProps) {
    super(props);
    this.state = {
    };

  }

  public componentDidMount(): void {
    let dateTimeWrappers = document.getElementsByClassName("react-datetime-picker__wrapper");
    for (let i = 0; i < dateTimeWrappers.length; i++) {
      let element: any = dateTimeWrappers[i];
      element.style.borderColor = "#dddddd";
      element.style.minWidth = "150px";
      element.style.padding = "2px";
      element.style.paddingLeft = "5px";
    }
  }

  public render(): JSX.Element {
    let label = <div style={{ float: "left" }}>{this.props.label}</div>;
    if (this.props.required) {
      label = <div style={{ float: "left" }}>{this.props.label} <span style={{ color: "red" }}>*</span></div>;
    }
    return (
      <div
        className={styles.IA_dateAndTimePicker}
        style={this.props.style}
      >
        {this.props.label != undefined &&
          <span style={{ color: this.props.darkMode ? Helper.darkModeLabelColor : '#000' }}>
            {label}
          </span>
        }
        <div className={`${styles.picker} ${this.props.darkMode ? styles.IA_darkPicker :''}`}>
          {navigator.userAgent.toLowerCase().indexOf('firefox') === -1 ?
            <input
              type={this.props.showTimeSelector ? "datetime-local" : "date"}
              id="start"
              name="trip-start"
              value={this.props.selectedDate !== undefined ? (this.props.showTimeSelector ? this.dateTimeService.ConvertToLocalIOSString(this.props.selectedDate) : this.dateTimeService.ConvertToYYYYMMDD(this.props.selectedDate)) : ""}
              min={this.props.minDate !== undefined ? (this.props.showTimeSelector ? this.dateTimeService.ConvertToLocalIOSString(this.props.minDate) : this.dateTimeService.ConvertToYYYYMMDD(this.props.minDate)) : ""}
              max={this.props.maxDate !== undefined ? (this.props.showTimeSelector ? this.dateTimeService.ConvertToLocalIOSString(this.props.maxDate) : this.dateTimeService.ConvertToYYYYMMDD(this.props.maxDate)) : ""}
              onChange={(date: any) => {
                if (date.target.value && date.target.value !== "") {
                  this.props.onChange(new Date(date.target.value));
                }
              }}
              placeholder=""
              style={{
                color: this.props.selectedDate !== undefined ? this.props.darkMode ? '#fff' : "#333333" : "#b3b3b3",
                width: this.props.inputWidth ? this.props.inputWidth : 200,
                backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : '#fff',
                borderRadius: this.props.borderRadius,
                ...this.props.inputStyle
              }}
            />
            :
            <DatePicker
              selected={this.props.selectedDate}
              onChange={(date: any) => this.props.onChange(date)}
              showTimeSelect={this.props.showTimeSelector}
              timeFormat="HH:mm"
              timeIntervals={15}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              locale={navigator.language}
              dateFormat={this.props.showTimeSelector ? "dd/MM yyyy HH:mm" : "dd/MM yyyy"}
              
            />
          }
        </div>
        {this.props.isClearable &&
          <div
            className={styles.IA_dateAndTimePickerClearButton}
          >
            <IAIcon
              title="Close"
              size={18}
              color={this.props.disabled ? "gray" : "red"}
              onClick={this.props.disabled ? undefined : () => this.props.onChange(undefined)}
            />
          </div>
        }
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}