import * as React from 'react';
import * as styles from "./NewComment.css";
import "./IA-tinyStyles/IA-tinyStyles.css";
import { Helper } from './../../../Helper';
import { Promise } from 'es6-promise';
import { Editor } from '@tinymce/tinymce-react';
import { IReply } from '../../../interfaces/IReply';
import { IUser } from '../../../interfaces/IUser';
import SocialService from '../../../services/SocialService';
import { LoggingService } from '../../../services/LoggingService';
import { LocalizationService } from '../../../services/LocalizationService';
import { Environment } from '../../../services/Environment';

export interface INewCommentProps {
  token: string;
  tenant: string;
  environment: "Development" | "Test" | "Production";
  imageUploadUrl: string;
  buttonColor: string;
  reply: IReply;
  addComment: boolean;
  isMobile: boolean;
  dataAutomationIdPrefix?: string;
  comment: (comment: string, mentions: IUser[], reply?: IReply) => Promise<void>;
  cancelReply: () => void;
  newCommentPopupIsShowing: (isShowing: boolean) => void;
  isWriting: () => void;
  user?: any;
  highlightColor: string;
}

export interface INewCommentState {
  showMobileNewMessageOverlay: boolean;
  editor: any;
  allowPost: boolean;
}

export class IANewComment extends React.Component<INewCommentProps, INewCommentState> {

  constructor(props: INewCommentProps) {
    super(props);
    this.state = {
      showMobileNewMessageOverlay: false,
      editor: undefined,
      allowPost: false
    };
  }

  // private showMobileNewCommentOverview(): void {
  //   this.setState({ showMobileNewMessageOverlay: true });
  //   this.props.newCommentPopupIsShowing(true);
  // }

  public componentWillReceiveProps(props: INewCommentProps): void {
    if (!props.addComment) {
      if (this.state.editor) {
        this.state.editor.setContent('');
      }
      if (props.reply != undefined && props.reply != this.props.reply) {
        var div = document.getElementById('newComment');
        setTimeout(() => {
          // if (this.props.isMobile) {
          //   this.showMobileNewCommentOverview();
          // }
          if (div) {
            div.focus();
          };
        }, 0);
      }
      if (props.addComment != undefined && props.addComment != this.props.addComment) {
        var div = document.getElementById('newComment');
        setTimeout(() => {
          // if (this.props.isMobile) {
          //   this.showMobileNewCommentOverview();
          // }
          if (div) {
            div.focus();
          }
        }, 0);
      }
    }
  }

  private getEditor(): JSX.Element {
    let firstNameChar = this.props.user.displayName != undefined ? this.props.user.displayName.split(" ")[0].charAt(0) : "";
    let lastNameChar = this.props.user.displayName != undefined ? this.props.user.displayName.split(" ")[this.props.user.displayName.split(" ").length - 1].charAt(0) : "";

    let editorStyles = `p {font-size: 14px} .newComment img, .newComment iframe { max-width: 100%; height: auto;} .ia_mention, a {color: ${this.props.buttonColor}}}`;
    return (
      <div id="newComment">
        {this.props.reply &&
          <div
            style={{
              marginBottom: 10
            }}
          >
            {`${new LocalizationService().strings.LikesAndComments_ReplyTo} ${this.props.reply.user.displayName}:`}
          </div>
        }
        <div style={{ display: this.props.addComment ? "" : "none" }}>
          <span style={{
            display: this.props.addComment && (this.state.editor && (this.state.editor.getContent() !== "")) ? "none" : "",
            position: "absolute",
            top: this.props.reply ? "52px" : "22px",
            left: "50px",
            userSelect: "none",
            color: "rgba(34, 47, 62)",
            fontSize: "14px"
          }}
          >{new LocalizationService().strings.CommentsAndLikes_AddComment}</span>
          <div>
            <div
              className={styles.IA_commentProfileImageWrapper}
              style={{
                backgroundColor: this.props.highlightColor != undefined ? this.props.highlightColor : "#333333",
                color: this.props.highlightColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.highlightColor, "#ffffff", "#333333") : "#ffffff"
              }}
            >
              <div className={styles.IA_commentProfileImageText}>
                {firstNameChar + lastNameChar}
              </div>
              <div
                className={styles.IA_commentProfileImagePicture}
                style={{
                  backgroundImage: `url('${Helper.getProfileImageUrlFromImageAPI(Environment.getEnvironmentForImageAPI(this.props.environment),
                    this.props.tenant, this.props.token, this.props.user.userPrincipalName)}')`
                }}
              />
            </div>
            <div style={{
            float: "left",
            width: "calc(100% - 45px)",
            padding: "5px 0px 10px 5px"}}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              event.stopPropagation();
            }}>

            
            <Editor
              apiKey="zpr7liy29jsy6rovx8tdy9jp9s4zs7se2ui6yfzfo1d8nrjr"
              value={this.state.editor != undefined ? this.state.editor.getContent() : undefined}
              onEditorChange={(content) => {
                this.setState({ allowPost: content !== undefined && content !== "" });
              }}
              onFocus={() => {
                this.props.isWriting();
              }}
              textareaName='IntraActiveEditor'
              init={{
                setup: (editor) => {
                  this.setState({ editor });
                  // editor.ui.registry.addButton('myCustomToolbarButton', {
                  //   text: 'My Button',
                  //   onAction: () => alert('Button clicked!')
                  // });
                },
                inline: true,
                content_style: editorStyles,
                mobile: {
                  theme: 'silver'
                },
                toolbar: 'emoticons image media',
                menubar: false,
                plugins: 'link autolink emoticons image importcss media paste',
                images_upload_handler: (blobInfo, success) => {
                  SocialService.uploadSelecedFile(blobInfo, this.props.imageUploadUrl, this.props.token).then((imageUrl: string) => {
                    let imageAPI = "";
                    if (this.props.environment == "Production") {
                      imageAPI = "https://intraactive-image-functions.azurewebsites.net";
                    } else if (this.props.environment == "Test") {
                      imageAPI = "https://intraactive-image-functions-test.azurewebsites.net";
                    } else if (this.props.environment == "Development") {
                      imageAPI = "https://intraactive-image-functions-dev.azurewebsites.net";
                    }
                    let proxyUrl = Helper.getImageUrl(imageAPI, this.props.tenant, this.props.token, imageUrl, undefined, "Full");
                    success(proxyUrl);
                  });
                },
                link_assume_external_targets: "https",
                relative_urls: false,
                remove_script_host: false,
                default_link_target: "_blank",
                elementpath: false,
                branding: false,
                language: new LocalizationService().editorLanguage,
                language_url: new LocalizationService().languageUrlTiny,
                // mentions_fetch: (query, success) => {
                //   localStorage.setItem("userName", query.term);
                //   SocialService.getUsersWithName(this.props.environment, this.props.tenant, this.props.token, query.term).then((searchResult: any) => {
                //     let savedUserName = localStorage.getItem("userName");
                //     if (searchResult.searchQuery.toLowerCase() == savedUserName.toLowerCase()) {
                //       let users = [];
                //       searchResult.users.forEach((user) => {
                //         users.push(
                //           {
                //             id: user.userPrincipalName,
                //             name: user.displayName
                //           }
                //         );
                //       });
                //       success(users);
                //     }
                //   });
                // },
                // mentions_selector: 'span.ia_mention',
                // mentions_menu_complete: (editor, userInfo) => {
                //   var span = editor.getDoc().createElement('span');
                //   span.className = 'ia_mention';
                //   // store the user id in the mention so it can be identified later
                //   span.setAttribute('id', userInfo.id);
                //   span.appendChild(editor.getDoc().createTextNode(userInfo.name));
                //   return span;
                // }
              }}
            />
            </div>
          </div>
        </div>
        <div
          className={styles.IA_postBtn}
          data-automation-id={`${this.props.dataAutomationIdPrefix}-new`}
          onClick={() => {
            if (this.state.allowPost) {
              // Mentions
              let mentions: IUser[] = [];
              var contentDiv: any = document.createElement('div');
              contentDiv.innerHTML = this.state.editor.getContent();
              contentDiv.innerHTML = contentDiv.innerHTML.replace(new RegExp(`"`, 'g'), `'`);
              contentDiv.innerHTML = contentDiv.innerHTML.replace(new RegExp(`//www.y`, 'g'), `https://www.y`);
              let mentionElements = contentDiv.getElementsByClassName("ia_mention");
              for (let i = 0; i < mentionElements.length; i++) {
                mentions.push(
                  {
                    userPrincipalName: mentionElements[i].getAttribute("id").toLowerCase(),
                    displayName: mentionElements[i].innerText
                  }
                );
              }
              // Images
              let images = contentDiv.getElementsByTagName("img");
              for (let i = 0; i < images.length; i++) {
                let src = images[i].src;
                if (src.indexOf("intraactive-image-functions") != -1) {
                  let sharepointImageUrl = decodeURIComponent(src.split("path=")[1]);
                  contentDiv.getElementsByTagName("img")[i].src = sharepointImageUrl;
                }
              }
              // Save
              // let iframe: any = document.getElementsByClassName("tox-edit-area__iframe")[0];
              // iframe.focus();
              let activeElement: any = document.activeElement;
              activeElement.blur();
              this.setState({ showMobileNewMessageOverlay: false, allowPost: false });
              this.props.newCommentPopupIsShowing(false);
              this.props.comment(contentDiv.innerHTML, mentions, this.props.reply).then(() => {
                this.state.editor.setContent('');
              });
            }
          }}
          style={{
            backgroundColor: this.state.editor != undefined && this.state.editor.getContent() != "" ? this.props.buttonColor : "#e3e3e3",
            color: this.state.editor != undefined && this.state.editor.getContent() != "" ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.buttonColor, "#ffffff", "#333333") : "gray",
            visibility: this.props.addComment ? "visible" : "hidden",
            display: this.props.addComment && ( (this.state.editor && this.state.editor.hasFocus())) ? "" : "none"
          }}
        >
          {new LocalizationService().strings.LikesAndComments_NewComment_AddComment}
        </div>
        {this.props.addComment && <div
          className={styles.IA_cancelBtn}
          data-automation-id={`${this.props.dataAutomationIdPrefix}-cancel`}
          onClick={() => {
            this.state.editor.setContent('');
            this.setState({ showMobileNewMessageOverlay: false, allowPost: false });
            this.props.newCommentPopupIsShowing(false);
            // let iframe: any = document.getElementsByClassName("tox-edit-area__iframe")[0];
            // iframe.focus();
            let activeElement: any = document.activeElement;
            activeElement.blur();
            this.props.cancelReply();
          }}
          style={{
            backgroundColor: "gray",
            color: "#ffffff",
            display: (this.state.allowPost && (this.state.editor && this.state.editor.hasFocus()) || this.props.reply) ? "" : "none"
          }}
        >
          {new LocalizationService().strings.LikesAndComments_NewComment_Cancel}
        </div>}
      </div>
    );
  }

  // private getMobileEditor(): JSX.Element {
  //   let fromMobileApp = location.hostname.indexOf("mobile-spa") != -1 || this.props.isMobile;
  //   return (
  //     <div
  //       className={styles.IA_mobileNewCommentOverlay}
  //       style={{
  //         top: fromMobileApp ? (Helper.isIPhoneXType() ? 90 : 70) : 0,
  //         height: `calc(100vh - ${fromMobileApp ? 70 : 0}px)`
  //       }}
  //     >
  //       {this.getEditor()}
  //     </div>
  //   );
  // }

  // private getMobileButton(): JSX.Element {
  //   return (
  //     <div
  //       className={styles.IA_mobileButton}
  //       onClick={() => {
  //         this.setState({ showMobileNewMessageOverlay: true });
  //         this.props.newCommentPopupIsShowing(true);
  //       }}
  //     >
  //       {new LocalizationService().strings.LikesAndComments_NewComment_AddComment}
  //     </div>
  //   );
  // }

  public render(): JSX.Element {

    return (
      <div
        className={styles.IA_newComment}
        style={{
          height: "auto", // this.props.isMobile ? "600px" : "auto",
          marginBottom: this.props.isMobile ? 100 : 20
        }}
      >

        {<div>
          {this.getEditor()}</div>}
        {/* {this.props.isMobile ?
          this.getMobileButton()
          :
          this.getEditor()
        } */}
        {/* {this.state.showMobileNewMessageOverlay &&
          this.getMobileEditor()
        } */}
      </div >
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}