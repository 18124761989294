import * as React from 'react';
import * as styles from './TextField.css';
import { LoggingService } from '../../services/LoggingService';
import { IATooltip } from '../tooltip/Tooltip';
import { IAIcon } from '../icon';

export interface ITextFieldProps {
  id?: string;
  label?: string;
  text: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  secure?: boolean;
  style?: React.CSSProperties;
  inputFieldStyle?: React.CSSProperties;
  darkMode?: boolean;
  borderRadius?: number;
  onChange: (text: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  toolTipText?: string;
  labelStyle?: any;
  placeholderIcon?: string
}

export interface ITextFieldState {
  text: string;
}

export class IATextField extends React.Component<ITextFieldProps, ITextFieldState> {

  constructor(props: ITextFieldProps) {
    super(props);
    this.state = {
      text: props.text
    };

  }

  public componentWillReceiveProps(props: ITextFieldProps): void {
    this.setState({ text: props.text });
  }

  private onChange(text: string): void {
    this.setState({ text });
    this.props.onChange(text);
  }

  public render(): JSX.Element {
    let label;
    if (this.props.label) {
      label = <div style={{ float: "left", ...this.props.labelStyle }}>{this.props.label}</div>;
      if (this.props.required) {
        label = <div style={{ float: "left", ...this.props.labelStyle }}>{this.props.label} <span style={{ color: "red" }}>*</span></div>;
      }
    }
    return (
      <div
        className={(this.props.darkMode ? styles.IA_textFieldDarkMode : styles.IA_textField) + " " + (!this.props.className ? this.props.className : "")}
        style={this.props.style}>
        {this.props.label &&
          label
        }
        {this.props.toolTipText &&
          <IATooltip
            content="Tooltip"
            type="bubble"
            rootStyles={{
              marginTop: 2,
              marginLeft: 5
            }}
            componentContent={(
              <div>{this.props.toolTipText}</div>
            )}
          >
            <IAIcon
              title="Info"
              size={14}
              color="Black"
            />
          </IATooltip>
        }
        <input
          id={this.props.id}
          type={this.props.secure ? "password" : "text"}
          placeholder={this.props.placeholder}
          value={this.state.text != undefined ? this.state.text : ""}
          onChange={(event: any) => this.onChange(event.target.value)}
          onBlur={this.props.onBlur ? () => this.props.onBlur() : () => { }}
          disabled={this.props.disabled}
          style={{
            borderRadius: this.props.borderRadius,
            ...this.props.inputFieldStyle
          }}
        />
        {
          this.props.placeholderIcon && !this.state.text &&
          <div className={styles.IA_placeholderIcon}>
            <IAIcon
              title={this.props.placeholderIcon}
              size={14}
              color={this.props.darkMode ? '#a8a8a8' : 'black'}
            />
          </div>
        }
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}