exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_UfcXsLvmEVHAGDBDHTlHS {\n  position: relative;\n  float: right;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_UfcXsLvmEVHAGDBDHTlHS .likes_3i65rXai8wksrhCbL7a-Wm {\n  height: 20px;\n  float: right;\n  position: relative;\n  cursor: pointer;\n}\n\n.likesAndComments_UfcXsLvmEVHAGDBDHTlHS .spinner_2SXqNqZrr3XpHMtE5oIKMu {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2bq48DxyFMy7XjNMjwqxij 700ms linear infinite;\n  -moz-animation: spin_2bq48DxyFMy7XjNMjwqxij 700ms linear infinite;\n  animation: spin_2bq48DxyFMy7XjNMjwqxij 700ms linear infinite;\n}\n\n.likesAndComments_UfcXsLvmEVHAGDBDHTlHS .likesText_3Az2goMLFOFeJ3DFGOYefg {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n.likesAndComments_UfcXsLvmEVHAGDBDHTlHS .likesTextVertical_olP6wknvkY8PbslJBY4R_ {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n@-moz-keyframes spin_2bq48DxyFMy7XjNMjwqxij {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2bq48DxyFMy7XjNMjwqxij {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2bq48DxyFMy7XjNMjwqxij {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_UfcXsLvmEVHAGDBDHTlHS",
	"likes": "likes_3i65rXai8wksrhCbL7a-Wm",
	"spinner": "spinner_2SXqNqZrr3XpHMtE5oIKMu",
	"spin": "spin_2bq48DxyFMy7XjNMjwqxij",
	"likesText": "likesText_3Az2goMLFOFeJ3DFGOYefg",
	"likesTextVertical": "likesTextVertical_olP6wknvkY8PbslJBY4R_"
};