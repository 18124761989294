import { IUser } from "./interfaces/IUser";
import { LocalizationService } from "./services/LocalizationService";
import UserService from "./services/UserService";

export class Helper {

  public static darkModeBackgroundColor: string = "#1f1f1f";
  public static darkModeLabelColor: string = "#A8A8A8";
  public static darkModeCardBackgroundColor: string = "#323232";
  public static darkModeInputFieldBackgroundColor: string = "#414141";
  public static LocalizationService = new LocalizationService();

  public static removeSpecialCharacters(text: string): string {
    if (text) {
      return text.replace(/[^A-Za-z0-9._-]/g, "");
    }
    return this.getRandomStringKey();
  }

  public static convertHexToRgb(hex: string): { r: number; b: number; g: number; } {
    const shorthandRegex: any = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }

  private static componentColorToHex(c: number): string {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  public static convertRbToHex(rgb: string): string {
    rgb = rgb.replace("rgb(", "");
    rgb = rgb.replace(")", "");
    rgb = rgb.replace(/ /g, '');
    const rgbArray = rgb.split(",");
    return "#" + this.componentColorToHex(parseInt(rgbArray[0])) + this.componentColorToHex(parseInt(rgbArray[1])) + this.componentColorToHex(parseInt(rgbArray[2]));
  }

  public static isSharepointInAppBrowser(): boolean {
    return navigator.userAgent ? navigator.userAgent.indexOf("SharePoint for iOS") !== -1 : false;
  }

  public static getTextColorBasedOnBackgroundColorDarkness(backgroundColor: string, lightReturn: any, darkReturn: any): any {
    if (backgroundColor === "transparent" || backgroundColor == null) {
      return darkReturn;
    }
    const rgb: { r: number; b: number; g: number } = this.convertHexToRgb(backgroundColor);
    if (rgb) {
      const colorValue: number = rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114;
      const threshold: number = 186;
      return colorValue <= threshold ? lightReturn : darkReturn;
    }
    return "#FFFFFF";
  }

  public static getRandomStringKey(): string {
    return Math.random().toString(36).substring(7);

  }

  public static isYouTubeUrl(url: string): boolean {
    const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return true;
    }
    return false;
  }

  public static isStreamVideo(url: string): boolean {
    return url.indexOf("microsoftstream") !== -1;
  }

  public static isVimeoVideo(url: string): boolean {
    return url.indexOf("vimeo") !== -1;
  }

  public static getYouTubeThumbnailFromURL(url: string): string {
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const youtubeId: string = url.match(rx)[1];
    return "https://img.youtube.com/vi/" + youtubeId + "/mqdefault.jpg";
  }

  public static convertUrlToEmbedUrl(url: string): string {
    if (this.isYouTubeUrl(url)) {
      if (url.indexOf("embed") !== -1) {
        return url;
      } else {
        const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        const youtubeId: string = url.match(rx)[1];
        return "https://www.youtube.com/embed/" + youtubeId;
      }
    } else if (this.isStreamVideo(url)) {
      return url.indexOf("embed/video") === -1 ? url.replace("video", "embed/video") : url;
    } else {
      return url;
    }
  }

  public static getProfileImageUrl(apiUrl: string, tenantName: string, token: string, userPrincipalName: string): string {
    let url = "";
    if (apiUrl && tenantName && token && userPrincipalName) {
      if (tenantName.indexOf(".sharepoint.com") === -1) {
        tenantName = `${tenantName}.sharepoint.com`;
      }
      url = `${apiUrl}/api/${tenantName}/image/profile/token/${token}?userPrincipalName=${encodeURIComponent(userPrincipalName)}`;
    }
    return url;
  }

  public static getProfileImageUrlFromImageAPI(apiUrl: string, tenantName: string, token: string, id: string): string {
    let url = ""; // New
    if (apiUrl && tenantName && token && id) {
      if (tenantName.indexOf(".sharepoint.com") !== -1) {
        tenantName = tenantName.replace(".sharepoint.com", "");
      }
      url = `${apiUrl}/${tenantName}/profile/${encodeURIComponent(id)}?authtoken=${token}&ignoreCache=false`;
    }
    return url;
  }

  public static getImageUrl(apiImageUrl: string, tenantName: string, token: string, imageUrl: string, version?: string, format?: string, cropSettings?: { x: number, y: number, width: number, height: number }, ignoreCache: boolean = false, retainType?: boolean): string {
    if (imageUrl && apiImageUrl && tenantName && token) {
      tenantName = tenantName.replace(".onmicrosoft.com", "").replace(".sharepoint.com", "");
      const imageFormat = format !== undefined ? format : "Full";
      imageUrl = imageUrl.replace(/StandardSmall|StandardLarge|StandardSmall|Square|Wide|Full|DefaultRendition/, imageFormat);
      const versionNumber = version !== undefined ? version : "";

      const pathComparison = `${apiImageUrl}/${tenantName}`;
      let crop = "";
      let ignore = "";

      if (ignoreCache) {
        ignore = `&ignoreCache=${ignoreCache}`;
      }

      if (cropSettings) {
        crop = `&cropX=${cropSettings.x}&cropY=${cropSettings.y}&cropH=${cropSettings.height}&cropW=${cropSettings.width}`;
      }

      const fullPath = `/${imageFormat}?token=${token}&version=${versionNumber}${ignore}${crop}&path=`;

      if (imageUrl.indexOf(pathComparison) === -1) {
        imageUrl = `${pathComparison}${fullPath}${encodeURIComponent(imageUrl)}`;
      } else {
        imageUrl = imageUrl.replace("&version=&", `&version=${versionNumber}&`) + crop;
      }
      if (retainType !== undefined) {
        imageUrl = `${imageUrl}&retainType`;
      }
    }
    return imageUrl;
  }

  public static getVideoUrl(imageAPIUrl: string, tenantName: string, token: string, videoUrl: string): string {
    if (imageAPIUrl && videoUrl && tenantName && token) {
      tenantName = tenantName.replace(".onmicrosoft.com", "").replace(".sharepoint.com", "");

      const fullPath = `/${tenantName}/video?token=${token}&url=`;
      return `${imageAPIUrl}${fullPath}${encodeURIComponent(videoUrl)}`;
    }
    return videoUrl;
  }

  public static getImageUrlFromMainAPI(apiImageUrl: string, tenantName: string, token: string, imageUrl: string): string {
    if (imageUrl && apiImageUrl && tenantName && token) {
      if (tenantName.indexOf(".sharepoint.com") === -1) {
        tenantName = tenantName + ".sharepoint.com";
      }
      const pathComparison = `${apiImageUrl}/api/${tenantName}/image`;
      const fullPath = `/?token=${token}&url=`;
      if (imageUrl.indexOf(pathComparison) === -1) {
        imageUrl = `${pathComparison}${fullPath}${encodeURIComponent(imageUrl)}`;
      }
    }
    return imageUrl;
  }

  public static getMainAPIUrl(environment: "Development" | "Test" | "Production"): string {
    let apiUrl = "https://intraactive-api-dev.azurewebsites.net";
    switch (environment) {
      case "Production":
        apiUrl = "https://intraactive-api.azurewebsites.net";
        break;
      case "Test":
        apiUrl = "https://intraactive-api-test.azurewebsites.net";
        break;
      case "Development":
        apiUrl = "https://intraactive-api-dev.azurewebsites.net";
        break;

      default:
        break;
    }
    return apiUrl;
  }

  public static isValidUrl(str): boolean {
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  }

  public static syncUserProperties(user: IUser): IUser {
    if (user !== undefined) {
      if (user.displayName) {
        user.name = user.displayName;
        user.title = user.displayName;
      }
      if (user.name) {
        user.displayName = user.name;
        user.title = user.name;
      }
      if (user.title) {
        user.displayName = user.title;
        user.name = user.title;
      }
      if (user.userPrincipalName) {
        user.userPrincipalName = user.userPrincipalName.toLowerCase();
        if (user.email === undefined) {
          user.email = user.userPrincipalName;
        }
      }
      if (user.email) {
        user.email = user.email.toLowerCase();
        if (user.userPrincipalName === undefined) {
          user.userPrincipalName = user.email;
        }
      }
      if (user.photo) {
        user.image = user.photo;
      }
      if (user.image) {
        user.photo = user.image;
      }
      return user;
    }
    return undefined;
  }

  public static getCookieValue(cookieName): string {
    const ca = document.cookie.split('; ');
    const cookieObjects = ca.filter((cookie) => {
      return cookie.indexOf(cookieName) === 0;
    });
    if (cookieObjects.length !== 0) {
      const cookieValue = cookieObjects[0].split("=")[1];
      return cookieValue;
    }
    return undefined;
  }

  public static replaceStreamVideoTag(source: string): string {
    if (source) {
      const doc = new DOMParser().parseFromString(source, "text/html");
      const elements = doc.getElementsByTagName("intraactive");
      for (let i = 0; i < elements.length; i++) {
        const e = elements[i];
        switch ((e as any).dataset.type) {
          case "stream":
            const url = (e as any).dataset.url;
            const placeholder = e.outerHTML.replace("https://", "https&#58;//");
            const streamContent = `<iframe width="640" height="360" src="${url}?autoplay=false&amp;showinfo=true" style="border:none;" allowfullscreen ></iframe>`;
            source = source.replace(placeholder, streamContent);
            break;
          default:
            break;
        }
      }
    }
    return source;
  }

  private static sortLanguages(languages: any[]): any[] {
    return languages.sort((l1, l2) => {
      if (l1.title > l2.title) {
        return 1;
      }
      if (l1.title < l2.title) {
        return -1;
      }
      return 0;
    });
  }

  public static sortAlphabeticalByTitle(array: any[]): any[] {
    return array.sort((l1, l2) => {
      if (l1.title?.toLowerCase() > l2.title?.toLowerCase()) {
        return 1;
      }

      if (l1.title?.toLowerCase() < l2.title?.toLowerCase()) {
        return -1;
      }

      return 0;
    });
  }

  public static getLanguages(): { title: string, name: string, id: string }[] {
    return this.sortLanguages([
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageCzech, name: "Czech", id: "cs" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageDanish, name: "Dansk", id: "da" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageGerman, name: "Deutsch", id: "de" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageDutch, name: "Dutch", id: "nl" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageEnglish, name: "English", id: "en" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageFrench, name: "French", id: "fr" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguagePolish, name: "Polish", id: "pl" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguagePortuguese, name: "Portugues", id: "pt" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageRussian, name: "Russian", id: "ru" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageSpanish, name: "Spanish", id: "es" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageHungarian, name: "Hungarian", id: "hu" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageChinese, name: "Chinese", id: "zh-Hans" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageSwedish, name: "Swedish", id: "sv" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageSlovak, name: "Slovak", id: "sk" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageItalian, name: "Italian", id: "it" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageTurkish, name: "Turkish", id: "tr" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageJapanese, name: "Japanese", id: "ja" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageCroatian, name: "Croatian", id: "hr" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageNorwegian, name: "Norwegian", id: "nb" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageKorean, name: "Korean", id: "ko" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageLithuanian, name: "Lithuanian", id: "lt" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageThai, name: "Thai", id: "th" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageUkrainian, name: "Ukrainian", id: "uk" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageMalaysian, name: "Malaysian", id: "ms" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageHindi, name: "Hindi", id: "hi" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageIndonesian, name: "Indonesian", id: "id" },
      { title: this.LocalizationService.strings.Panel_NavigatorBar_LanguageVietnamese, name: "Vietnamese", id: "vi" }
    ]);
  }

  public static getDisplayEnvironment(): "ExternalEnvironment" | "Sharepoint" | "Teams" | "SharepointClassic" {
    let displayEnvironment: "ExternalEnvironment" | "Sharepoint" | "Teams" | "SharepointClassic" = "ExternalEnvironment";
    if (document.getElementById("SuiteNavPlaceholder")) {
      displayEnvironment = "Sharepoint";
    }
    if (document.getElementsByClassName('od-SuiteNav')[0]) {
      displayEnvironment = "SharepointClassic";
    }
    return displayEnvironment;
  }

  public static isIPhoneXType(): boolean {
    return window.matchMedia("(-webkit-min-device-pixel-ratio: 2)").matches && screen.height >= 812;
  }

  public static getTenantName(): string {
    let url = window.location.host;
    url = url.split('.')[0];
    return url;
  }

  public static getSiteCollectionFromPath(fullPath: string): string {
    let siteCollection: string = "";
    const siteCollectionDetector: string = "/sites/";
    if (fullPath.indexOf(siteCollectionDetector) >= 0) {
      const parts: string[] = fullPath.split("/");
      siteCollection = parts[4];
    }
    return siteCollection;
  }

  public static lightenOrDarkenColor(color: string) {
    const amountInProcentage = this.getTextColorBasedOnBackgroundColorDarkness(color, 30, -30);
    let usePound = false;
    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }
    const num = parseInt(color, 16);
    let r = (num >> 16) + amountInProcentage;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }
    let b = ((num >> 8) & 0x00FF) + amountInProcentage;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }
    let g = (num & 0x0000FF) + amountInProcentage;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  public static imageHasCORS(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(true);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = () => {
        resolve(false);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  }

  public static getFileIconFromExtension(extension: string): { icon: string, color: string } {
    switch (extension.toLowerCase()) {
      case ".pdf":
        return {
          icon: "Pdf",
          color: "#FFFFFF"
        };
      case ".docx":
      case ".doc":
      case ".docm":
      case ".dot":
      case ".dotm":
      case ".dotx":
      case ".odt":
        return {
          icon: "WordNew",
          color: "#FFFFFF"
        };
      case ".xlsx":
      case ".xls":
      case ".csv":
      case ".ods":
      case ".xla":
      case ".xlam":
      case ".xll":
      case ".xlsb":
      case ".xlsm":
      case ".xlt":
      case ".xltm":
      case ".xltx":
        return {
          icon: "ExcelNew",
          color: "#FFFFFF"
        };
      case ".pptx":
      case ".ppt":
      case ".odp":
      case ".pot":
      case ".potm":
      case ".potx":
      case ".ppa":
      case ".ppam":
      case ".pps":
      case ".ppsm":
      case ".ppsx":
      case ".pptm":
        return {
          icon: "PowerPointNew",
          color: "#FFFFFF"
        };
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return {
          icon: "PictureNew",
          color: "#FFFFFF"
        };
      case ".mp4":
      case ".mkv":
      case ".avi":
      case ".wmv":
      case ".m4v":
        return {
          icon: "VideoNew",
          color: "#FFFFFF"
        };
      case ".mp3":
      case ".m4p":
      case ".wav":
      case ".m4a":
      case ".flac":
        return {
          icon: "Audio",
          color: "#FFFFFF"
        };
      default:
        return {
          icon: "Txt",
          color: "#FFFFFF"
        };
    }
  }
  // directly importing UserService in MessageService causes build issue so Helper is used as a bridge
  public static async getUPNByLookupId(environment: "Development" | "Test" | "Production", token: string, tenant: string, component: string, instance: string, lookupId: string): Promise<string> {
    return await UserService.getUPNByLookupId(environment, token, tenant, component, instance, lookupId);
  }

  public static stripHtml(html): string {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    tmp.childNodes.forEach((node: any) => {
      if (node?.innerText) {
        node.innerText = node.innerText.replace(new RegExp(String.fromCharCode(160), "g"), " ");
        const lastCharacter = node?.innerText?.substr(node?.innerText.length - 1);
        if (lastCharacter !== "" && lastCharacter !== " " && lastCharacter !== ";" && lastCharacter !== "." && lastCharacter !== "," && lastCharacter !== ":" && lastCharacter !== "!" && lastCharacter !== "?") {
          node.innerText = node.innerText + ".";
        }

      }
    })
    let text = tmp.textContent || tmp.innerText || "";
    text = text.replace(/,/g, ', ');
    text = text.replace(/\./g, '. ');
    text = text.replace(/!/g, '! ');
    text = text.replace(/\?/g, '? ');
    text = text.replace(/:/g, ':');
    text = text.replace(/ \./g, '.');
    text = text.replace(/   /g, ' ');
    text = text.replace(/  /g, ' ');
    return text;
  }

  public static getState(startDate: string, endDate: string): "active" | "scheduled" | "expired" {
    let state: "active" | "scheduled" | "expired";
    if (new Date(startDate) < new Date() && (endDate == undefined || new Date(endDate) > new Date())) {
      state = "active";
    }
    if (new Date(startDate) > new Date()) {
      state = "scheduled";
    }
    if (endDate && new Date(endDate) < new Date()) {
      state = "expired";
    }
    return state;
  }

  public static hideStreamInfo(url: string): string {
    if (url && !url.includes("showinfo=false")) {
      if (url.includes("?")) {
        url = url + "&showinfo=false"
      } else {
        url = url + "?showinfo=false"
      }
    }
    return url;
  }

  public static getCountryCodes(): { key: string, value: string }[] {
    const countries = require('./CountryCodes.json');
    const sortedCountries = this.sortAlphabeticalByValue(countries)
    return sortedCountries;
  }

  public static sortAlphabeticalByValue(array: any[]): any[] {
    return array.sort((l1, l2) => {
      if (l1.value?.toLowerCase() > l2.value?.toLowerCase()) {
        return 1;
      }
      if (l1.value?.toLowerCase() < l2.value?.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  public static getRandomNumberBetween(min: number, max: number): number {
    const result = Math.floor(Math.random() * max) + min;
    return result;
  }

  public static convertToSimpleDateInterval(from: Date, to: Date): string {
    if (!from || !to) {
      return undefined;
    }
    if (from.getFullYear() === to.getFullYear() && from.getMonth() === to.getMonth() && from.getDate() === to.getDate()) {
      const hours = to.getHours() >= 10 ? to.getHours() : `0${to.getHours()}`;
      const minutes = to.getMinutes() >= 10 ? to.getMinutes() : `0${to.getMinutes()}`;
      return `${this.convertToSimpleDate(from)} - ${hours}:${minutes}`;
    }
    return `${this.convertToSimpleDate(from)} - ${this.convertToSimpleDate(to)}`;
  }

  public static convertToSimpleDate(date: Date): string {
    if (!date) {
      return undefined;
    }
    const hours = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
    const minutes = date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    return `${date.getDate()}/${date.getMonth()} ${hours}:${minutes}`;
  }
}