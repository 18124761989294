import * as React from 'react';
import * as styles from "./PersonaSmall.css";
import { Helper } from './../../Helper';
import { LoggingService } from '../../services/LoggingService';

export interface IPersonaSmallProps {
  name: string;
  metadata?: any;
  profileImage?: string;
  backgroundColor?: string;
  styles?: any;
  numMetaData?: number;
  listViewStyle?: boolean;
  styleMetadata?: any;
}

export class IAPersonaSmall extends React.Component<IPersonaSmallProps> {

  public render(): JSX.Element {
    let firstNameChar = this.props.name != undefined ? this.props.name.split(" ")[0].charAt(0) : "";
    let lastNameChar = this.props.name != undefined ? this.props.name.split(" ")[this.props.name.split(" ").length - 1].charAt(0) : "";
    return (
      <div
        className={styles.IA_personaSmall}
        style={this.props.styles}
      >
        <div
          className={styles.IA_personaSmallProfileImageWrapper}
          style={{
            backgroundColor: this.props.backgroundColor != undefined ? this.props.backgroundColor : "#333333",
            color: this.props.backgroundColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.backgroundColor, "#ffffff", "#333333") : "#ffffff",
            marginTop: this.props.numMetaData ? (5*this.props.numMetaData).toString() + 'px' : '1px',
            marginRight: !this.props.listViewStyle || !this.props.listViewStyle ? "7px" : "10px"
          }}
        >
          <div className={styles.IA_personaSmallProfileImageText}>
            {firstNameChar + lastNameChar}
          </div>
          <div
            className={styles.IA_personaSmallProfileImage}
            style={{
              backgroundImage: `url('${this.props.profileImage}')`
            }}
          />
        </div>
        <div className={styles.IA_personaSmallName} style={!this.props.metadata ? { width: "auto", marginTop: "7px" } : {}}>{this.props.name}</div>
        {this.props.metadata &&
          <div className={styles.IA_personaSmallMetadata} style={this.props.styleMetadata? this.props.styleMetadata: {}}>{this.props.metadata}</div>
        }
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}