import { LocalizationService } from "./LocalizationService";

export default class DateTimeService {

  public ConvertToDDMMYYYY(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)} ${date.getFullYear()}`;
  }

  public ConvertToDDMMYYYYWithSlashes(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}/${date.getFullYear()}`;
  }

  public ConvertToYYYYMMDD(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${date.getFullYear()}-${this.PadWithZero(date.getMonth() + 1)}-${this.PadWithZero(date.getDate())}`;
  }

  public ConvertToLocalIOSString(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${date.getFullYear()}-${this.PadWithZero(date.getMonth() + 1)}-${this.PadWithZero(date.getDate())}T${this.PadWithZero(date.getHours())}:${this.PadWithZero(date.getMinutes())}`;
  }

  public ConvertToDDMM(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}`;
  }

  public ConvertToHHMM(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getHours())}.${this.PadWithZero(date.getMinutes())}`;
  }

  public ConvertToHHMMWithDoubleDots(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getHours())}:${this.PadWithZero(date.getMinutes())}`;
  }


  public ConvertToDDMMYYYYHHMM(date: Date | string, doubleDots: boolean = false): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    if (doubleDots) {
      return `${this.ConvertToDDMMYYYY(date)} ${this.ConvertToHHMMWithDoubleDots(date)}`;
    }
    return `${this.ConvertToDDMMYYYY(date)} ${this.ConvertToHHMM(date)}`;
  }

  public parseDate(dateString: string): Date {
    return new Date(dateString);
  }

  private PadWithZero(num: number): string {
    if (num < 10) {
      return "0" + num;
    }
    return num.toString();
  }

  public getWeekday(date: Date): string {
    switch (date.getDay()) {
      case 0:
        return new LocalizationService().strings.DateTimeService_Sunday;
      case 1:
        return new LocalizationService().strings.DateTimeService_Monday;
      case 2:
        return new LocalizationService().strings.DateTimeService_Tuesday;
      case 3:
        return new LocalizationService().strings.DateTimeService_Wednesday;
      case 4:
        return new LocalizationService().strings.DateTimeService_Thursday;
      case 5:
        return new LocalizationService().strings.DateTimeService_Friday;
      case 6:
        return new LocalizationService().strings.DateTimeService_Saturday;
    }
  }

  public getDayInMonth(date: Date | string, allDayEvent?: boolean): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    if (allDayEvent) {
      return `${this.PadWithZero(parseInt(date.toISOString().substring(8, 10), 0))}`;
    }
    return this.PadWithZero(date.getDate());
  }

  public getNameOfMonth(date: Date | string): string {
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    if (date !== undefined) {
      switch (date.getMonth()) {
        case 0:
          return new LocalizationService().strings.DateTimeService_January;
        case 1:
          return new LocalizationService().strings.DateTimeService_February;
        case 2:
          return new LocalizationService().strings.DateTimeService_March;
        case 3:
          return new LocalizationService().strings.DateTimeService_April;
        case 4:
          return new LocalizationService().strings.DateTimeService_May;
        case 5:
          return new LocalizationService().strings.DateTimeService_June;
        case 6:
          return new LocalizationService().strings.DateTimeService_July;
        case 7:
          return new LocalizationService().strings.DateTimeService_August;
        case 8:
          return new LocalizationService().strings.DateTimeService_September;
        case 9:
          return new LocalizationService().strings.DateTimeService_October;
        case 10:
          return new LocalizationService().strings.DateTimeService_November;
        case 11:
          return new LocalizationService().strings.DateTimeService_December;
      }
    } else {
      return "";
    }
  }

  public isStartAndEndDateTheSameDay(startDate: Date | string, endDate: Date | string): boolean {
    if (typeof (startDate) === "string") {
      startDate = this.parseDate(startDate);
    }
    if (typeof (endDate) === "string") {
      endDate = this.parseDate(endDate);
    }
    return startDate.getUTCDate() === endDate.getUTCDate();
  }

  public getEventString(startDate: Date, endDate?: Date, allDayEvent?: boolean): string {
    if (typeof (startDate) === "string") {
      startDate = this.parseDate(startDate);
    }
    if (endDate !== undefined && typeof (endDate) === "string") {
      endDate = this.parseDate(endDate);
    }
    const startDateString = allDayEvent ? this.getShortDateLocalized(startDate) : this.getFullDateWithoutYearsLocalized(startDate);
    let endDateString = allDayEvent ? this.getShortDateLocalized(this.subtractDays(endDate, 1)) : this.getFullDateWithoutYearsLocalized(endDate);
    const isOneDayEvent = startDateString === endDateString;
    let eventString = startDateString;
    if (isOneDayEvent) {
      if (allDayEvent) {
        endDateString = undefined;
      } else {
        endDateString = this.getShortDateLocalized(endDate);
      }
    }
    if (endDateString !== undefined) {
      eventString += ` - ${endDateString}`;
    }
    return eventString;
  }

  public getUserfriendlyDate(date: Date): string {
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    let startDateString = `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}`;
    const today = new Date();
    const tomorrow = new Date(today.getDate() + 1);
    const yesterday = new Date(today.getDate() - 1);
    const todayPlusSevenDays = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
    if (date > today && date < todayPlusSevenDays) {
      startDateString = this.getWeekday(date);
    }
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      startDateString = new LocalizationService().strings.DateTimeService_Today;
    }
    if (date.getDate() === tomorrow.getDate() && date.getMonth() === tomorrow.getMonth() && date.getFullYear() === tomorrow.getFullYear()) {
      startDateString = new LocalizationService().strings.DateTimeService_Tomorrow;
    }
    if (date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear()) {
      startDateString = new LocalizationService().strings.DateTimeService_Yesterday;
    }
    return startDateString;
  }

  public getTodayAtMidnight(): Date {
    let defaultStartDate = new Date();
    defaultStartDate = new Date(defaultStartDate.setHours(0, 0, 0, 0));
    return defaultStartDate;
  }

  public getTomorrowAtMidnight(): Date {
    let defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 1);
    defaultEndDate = new Date(defaultEndDate.setHours(0, 0, 0, 0));
    return defaultEndDate;
  }

  public getDatePlusOneHour(date: Date): Date {
    return new Date(date.setTime(date.getTime() + (1 * 60 * 60 * 1000)));
  }

  public getDateAtMidnight(date: Date): Date {
    let defaultStartDate = date;
    defaultStartDate = new Date(defaultStartDate.setHours(0, 0, 0, 0));
    return defaultStartDate;
  }

  public getDateJustBeforeMidnight(date: Date): Date {
    let defaultStartDate = date;
    defaultStartDate = new Date(defaultStartDate.setHours(23, 59, 59, 0));
    return defaultStartDate;
  }

  private subtractDays(date: Date | string, days: number) {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return new Date(date.setDate(date.getDate() - days));
  }

  private convertToHHMM(date: Date | string, delimeter: ":" | "."): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getHours())}${delimeter}${this.PadWithZero(date.getMinutes())}`;
  }

  private convertToYYYYMMDD(date: Date | string, delimeter: "/" | "." | "-" = "-"): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${date.getFullYear()}${delimeter}${this.PadWithZero(date.getMonth() + 1)}${delimeter}${this.PadWithZero(date.getDate())}`;
  }

  private convertToDDMMYYYY(date: Date | string, delimeter: "/" | "." | "-" = "-"): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}${delimeter}${this.PadWithZero(date.getMonth() + 1)}${delimeter}${date.getFullYear()}`;
  }

  private convertToDDMM(date: Date | string, delimeter: "." | "/"): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}${delimeter}${this.PadWithZero(date.getMonth() + 1)}`;
  }

  // private convertToMMDD(date: Date | string, delimeter: "." | "/" | "-"): string {
  //   if (!date) {
  //     return undefined;
  //   }
  //   if (typeof (date) === "string") {
  //     date = this.parseDate(date);
  //   }
  //   return `${this.PadWithZero(date.getMonth() + 1)}${delimeter}${this.PadWithZero(date.getDate())}`;
  // }

  public getDateLocalized(date: Date | string): string {
    const language = new LocalizationService().getLanguageFromNavigator();
    if (language === "Swedish") {
      return this.convertToYYYYMMDD(date, "-");
    } else if (language === "Danish") {
      return this.convertToDDMMYYYY(date, "-");
    } else if (language === "Portuguese") {
      return this.convertToDDMMYYYY(date, "/");
    } else if (language === "German") {
      return this.convertToDDMMYYYY(date, ".");
    } else {
      return this.convertToDDMMYYYY(date, "-");
    }
  }
  public getHoursLocalized(date: Date | string): string {
    const language = new LocalizationService().getLanguageFromNavigator();
    if (language === "Swedish") {
      return this.convertToHHMM(date, ".");
    } else if (language === "Danish") {
      return this.convertToHHMM(date, ":");
    } else if (language === "Portuguese") {
      return this.convertToHHMM(date, ":");
    } else if (language === "German") {
      return this.convertToHHMM(date, ":");
    } else {
      return this.convertToHHMM(date, ".");
    }
  }

  public getShortDateLocalized(date: Date | string): string {
    const language = new LocalizationService().getLanguageFromNavigator();
    if (language === "Swedish") {
      return this.convertToDDMM(date, "/");
    } else if (language === "Danish") {
      return this.convertToDDMM(date, ".");
    } else if (language === "Portuguese") {
      return this.convertToDDMM(date, "/");
    } else if (language === "German") {
      return this.convertToDDMM(date, ".");
    } else {
      return this.convertToDDMM(date, "/");
    }
  }

  public getFullDateLocalized(date: Date | string): string {
    return `${this.getDateLocalized(date)} ${this.getHoursLocalized(date)}`;
  }
  public getFullDateWithoutYearsLocalized(date: Date | string): string {
    return `${this.getShortDateLocalized(date)} ${this.getHoursLocalized(date)}`;
  }
}