import { Helper } from "../Helper";
import { IEventTelemetry, IExceptionTelemetry, ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Environment } from "./Environment";
import '../global';

export class LoggingService {

  public static init (environment: "Development" | "Test" | "Production"): void {
    window.AppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: Environment.getApplicationInsightsKey(environment),
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        disableAjaxTracking: true
      }
    });
    window.AppInsights.loadAppInsights();
  }

  public static trackException(component: string, action: string, error: Error, instanceName?: string): void {
    const exception: IExceptionTelemetry = {
      error: error,
      exception: error,
      properties: {
        Component: component,
        ActionType: action,
        Tenant: location.host,
        Instance: instanceName,
        UserId: Helper.getCookieValue("token")
      }
    };
    if (window.AppInsights) {
      window.AppInsights.trackException(exception);
    }
    console.error(error);
  }

  public static trackEvent(component: string, properties: any): void {
    const event: IEventTelemetry = {
      name: component,
      properties: properties
    };
    window.AppInsights.trackEvent(event);
  }
}