import * as React from 'react';
import { ILike } from '../../interfaces/ILike';
import { IUser } from '../../interfaces/IUser';
import { LoggingService } from '../../services/LoggingService';
import { IATooltip } from '../tooltip/Tooltip';
import { LocalizationService } from '../../services/LocalizationService';

export default class Utils {

  public static getLikesWithExamples(likes: ILike[], you: IUser, color: string, isMobile?: boolean): JSX.Element[] {
    let peopleWhoLiked = [];
    likes.forEach((like: any) => {
      peopleWhoLiked.push(
        <div>
          {like.user.displayName}
        </div>
      );
    });
    let likesElement: JSX.Element[] = [];
    if (likes != undefined) {
      if (likes.length == 1) {
        let user: IUser = likes[likes.length - 1].user;
        if (you != undefined && user.userPrincipalName == you.userPrincipalName) {
          likesElement.push(
            <div style={{ float: "left" }}><span style={{ fontWeight: "bold" }}>{new LocalizationService().strings.LikesAndComments_Comment_You}</span> {new LocalizationService().strings.LikesAndComments_Comment_LikeThis}</div>
          );
        } else {
          likesElement.push(
            <div style={{ float: "left"}}><span style={{ fontWeight: "bold" }}>{user.displayName}</span> {new LocalizationService().strings.LikesAndComments_Comment_LikesThis}</div>
          );
        }
      }
      if (likes.length > 1) {
        let user: IUser = likes[likes.length - 1].user;
        likesElement.push(
          <div style={{ float: "left" }}><span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? new LocalizationService().strings.LikesAndComments_Comment_You : user.displayName}</span></div>
        );
      }
      if (likes.length == 2) {
        let user: IUser = likes[likes.length - 2].user;
        likesElement.push(
          <div style={{ float: "left" }}>&nbsp;{new LocalizationService().strings.LikesAndComments_Comment_And}&nbsp;<span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? new LocalizationService().strings.LikesAndComments_Comment_You : user.displayName}</span> {new LocalizationService().strings.LikesAndComments_Comment_LikeThis}</div>
        );
      }
      if (likes.length > 2) {
        let user: IUser = likes[likes.length - 2].user;
        likesElement.push(
          <div style={{ float: "left" }}>, <span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? new LocalizationService().strings.LikesAndComments_Comment_You : user.displayName}</span></div>
        );
      }
      if (likes.length == 3) {
        let user: IUser = likes[likes.length - 3].user;
        likesElement.push(
          <div style={{ float: "left" }}>&nbsp;{new LocalizationService().strings.LikesAndComments_Comment_And}&nbsp;<span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? new LocalizationService().strings.LikesAndComments_Comment_You : user.displayName}</span> {new LocalizationService().strings.LikesAndComments_Comment_LikeThis}</div>
        );
      }
      if (likes.length > 3) {
        let user: IUser = likes[likes.length - 3].user;
        likesElement.push(
          <div style={{ float: "left" }}>, <span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? new LocalizationService().strings.LikesAndComments_Comment_You : user.displayName}</span></div>
        );
      }
      if (likes.length == 4) {
        likesElement.push(
          <div style={{ float: "left" }}>
            <div style={{ float: "left" }}>&nbsp;{new LocalizationService().strings.LikesAndComments_Comment_And}&nbsp;</div>
            <IATooltip
              content="Tooltip"
              type={isMobile ? "popup" : "bubble"}
              styles={{
                width: 200,
                textAlign: "left",
                color: "#333333",
              }}
              componentContent={
                <div>
                  {peopleWhoLiked}
                </div>
              }
            >
              <div style={{ float: "left", color: color, fontWeight: "bold", cursor: "pointer" }}>{new LocalizationService().strings.LikesAndComments_Comment_OneOther}&nbsp;</div>
            </IATooltip>
            {new LocalizationService().strings.LikesAndComments_Comment_LikeThis}</div>
        );
      }
      if (likes.length > 4) {
        likesElement.push(
          <div style={{ float: "left" }}>
            <div style={{ float: "left" }}>&nbsp;{new LocalizationService().strings.LikesAndComments_Comment_And}&nbsp;</div>
            <IATooltip
              content="Tooltip"
              type={isMobile ? "popup" : "bubble"}
              styles={{
                width: 200,
                textAlign: "left",
                color: "#333333",
              }}
              componentContent={
                <div>
                  <ul>
                    {peopleWhoLiked}
                  </ul>
                </div>
              }
            >
              <div style={{ float: "left", color: color, fontWeight: "bold", cursor: "pointer" }}>{likes.length - 3} {new LocalizationService().strings.LikesAndComments_Comment_Others}&nbsp;</div>
            </IATooltip>
            {new LocalizationService().strings.LikesAndComments_Comment_LikeThis}</div >
        );
      }
    }
    return likesElement;
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}