import * as React from 'react';
import { LoggingService } from '../../services/LoggingService';
import * as styles from './Menu.css';
import { LocalizationService } from '../../services/LocalizationService';
import { IAIcon } from '../icon';
import { IPlayMenuItem } from '../../interfaces/IPlayMenuItem';
import { MenuItem } from './menuItem/MenuItem';
import { IPlayProfile } from '../../interfaces/IPlay';
import { IAButton } from '../button/Button';
import { Helper } from '../../Helper';

export interface IMenuProps {
  items: IPlayMenuItem[];
  bottomItems?: IPlayMenuItem[];
  selectedItem: string;
  collapsed: boolean;
  onSelect: (selectedItem: string) => void;
  onCollapseToggle: () => void;
  showCollapseButton: boolean;
  profile?: IPlayProfile;
  showProfile?: boolean;
  showAccountSettingsHeader?: boolean;
  profiles?: any[];
  darkMode: boolean;
  isMobile: boolean;
  showMobileMenu: boolean;
  highlightColor: string;
  isTeams: boolean;
  style?: any;
  resetProfile: () => void;
  setProfile: (profile: any) => void;
}

export interface IMenuState {
  showProfiles: boolean;
}

export class IAMenu extends React.Component<IMenuProps, IMenuState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IMenuProps) {
    super(props);
    this.state = {
      showProfiles: false
    }
  }

  public render(): JSX.Element {
    const profilesButton = [(
      <div className={this.props.darkMode ? styles.IA_profileDark : styles.IA_profile} key="profile">
        <div
          className={styles.IA_profileLogo}
          style={{
            backgroundImage: `url("${this.props.profile?.logoUrl}")`,
            backgroundColor: this.props.profile?.footer?.backgroundColor ? this.props.profile?.footer?.backgroundColor : "lightGray"
          }}
        />
        <div className={styles.IA_profileName}>
          {this.props.profile?.title ? this.props.profile?.title : "Untitles profile"}
        </div>
        {!this.props.collapsed && this.props.profiles?.length > 1 &&
          <IAButton
            label={this.state.showProfiles ? this.localizationService.strings.PlayAdmin_Cancel.toUpperCase() : this.localizationService.strings.PlayAdmin_SwitchProfile.toUpperCase()}
            onClick={() => {
              this.setState({ showProfiles: !this.state.showProfiles })
            }}
            textColor={this.props.highlightColor}
            darkMode={this.props.darkMode}
            arialable={this.localizationService.strings.PlayAdmin_SwitchProfile}
            style={{
              float: "right",
              marginTop: 10,
              marginRight: 20
            }}
          />
        }
      </div>
    )];

    const profiles = [];
    if (this.props.profiles && this.state.showProfiles) {
      this.props.profiles.forEach((profile: IPlayProfile) => {
        if (profile.hasAccessPermissions && profile.id !== this.props.profile?.id) {
          profiles.push(
            <div
              key={`profile_${profile.id}`}
              className={this.props.darkMode ? styles.IA_profileDark : styles.IA_profile}
              style={{
                backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : "#eeeeee"
              }}
            >
              <div
                className={styles.IA_profileLogo}
                style={{
                  backgroundImage: `url(${profile?.logoUrl})`,
                  backgroundColor: profile?.footer?.backgroundColor
                }}
              />
              <div className={styles.IA_profileName}>
                {profile?.title}
              </div>
              {!this.props.collapsed &&
                <IAButton
                  label={this.localizationService.strings.PlayAdmin_Select.toUpperCase()}
                  buttonColor={this.props.highlightColor}
                  darkMode={this.props.darkMode}
                  onClick={() => {
                    localStorage.setItem("IA_profileId", profile?.id);
                    this.props.onSelect("profileSettings");
                    this.props.setProfile(profile);
                    this.props.resetProfile();
                  }}
                  textColor={"#ffffff"}
                  arialable={this.localizationService.strings.PlayAdmin_Select}
                  borderRadius={5}
                  style={{
                    float: "right",
                    marginTop: 10,
                    marginRight: 20
                  }}
                />
              }
            </div>
          );
        }
      });
    }
    const menuItems = [];
    this.props.items.forEach((item: IPlayMenuItem) => {
      if (!item.isProfileItem) {
        if (item.customItem) {
          menuItems.push(item.customItem);
        } else {
          menuItems.push(
            <MenuItem
              key={item.key}
              label={item.label}
              icon={item.icon}
              highlightColor={this.props.highlightColor}
              darkMode={this.props.darkMode}
              isTeams={this.props.isTeams}
              selected={item.key === this.props.selectedItem}
              onSelect={() => {
                this.props.onSelect(item.key);
              }}
            />
          );
        }
      } else {
        menuItems.push(profilesButton);
        menuItems.push(...profiles);
      }
    });
    const bottomItems = [];
    if (this.props.bottomItems) {
      this.props.bottomItems.forEach((item: IPlayMenuItem) => {
        bottomItems.push(
          <MenuItem
            key={item.key}
            label={item.label}
            icon={item.icon}
            highlightColor={this.props.highlightColor}
            darkMode={this.props.darkMode}
            isTeams={this.props.isTeams}
            selected={item.key === this.props.selectedItem}
            onSelect={() => {
              this.props.onSelect(item.key);
            }}
          />
        );
      });
    }

    return (
      <div
        className={styles.IA_menu}
        style={{
          width: this.props.isMobile ? "100%" : (this.props.collapsed ? 50 : 300),
          transition: "all 300ms ease-in-out",
          top: this.props.isMobile ? 50 : 0,
          backgroundColor: this.props.darkMode ? "#141414" : (this.props.showAccountSettingsHeader ? "#ffffff" : "#ffffff"),
          ...this.props.style
        }}
      >
        {!this.props.isMobile &&
          <div className={this.props.darkMode ? styles.IA_menuShadowDark : styles.IA_menuShadow} />
        }
        <div
          className={this.props.darkMode ? styles.IA_topItemsDark : styles.IA_topItems}
          style={{
            backgroundColor: this.props.darkMode ? "#141414" : (this.props.showAccountSettingsHeader ? "#ffffff" : "#ffffff"),
          }}
        >
          {this.props.showProfile &&
            profilesButton
          }
          {this.props.showAccountSettingsHeader &&
            <div className={this.props.darkMode ? styles.IA_profileDark : styles.IA_profile}>
              <div
                className={styles.IA_profileLogo}
                style={{
                  backgroundColor: this.props.darkMode ? "#ffffff" : "#333333"
                }}
              >
                <IAIcon
                  title={"Landingpageadmin"}
                  size={20}
                  color={this.props.darkMode ? "#333333" : "#ffffff"}
                  style={{
                    marginTop: 4,
                    marginLeft: 4
                  }}
                />
              </div>
              <div
                className={styles.IA_profileName}
                style={{
                  width: "calc(100% - 90px)"
                }}
              >
                {this.localizationService.strings.PlayAdmin_MenuArea_AccountSettingsaSettings}
              </div>
            </div>
          }
          {this.props.showProfile &&
            profiles
          }
          {menuItems}
        </div>
        <div
          className={this.props.darkMode ? styles.IA_bottomItemsDark : styles.IA_bottomItems}
          style={{
            backgroundColor: this.props.darkMode ? "#141414" : (this.props.showAccountSettingsHeader ? "#ffffff" : "#ffffff"),
          }}
        >
          {bottomItems}
          {this.props.showCollapseButton &&
            <div
              className={this.props.darkMode ? styles.IA_menuItemDark : styles.IA_menuItem}
              style={{
                borderTop: this.props.darkMode ? "1px solid #727272" : "1px solid #eeeeee",
              }}
              onClick={() => {
                this.props.onCollapseToggle()
              }}
            >
              <div
                className={styles.IA_menuItemIcon}
                style={{
                  transform: this.props.collapsed ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "all 300ms ease-in-out"
                }}
              >
                <IAIcon
                  title={"Arrow"}
                  size={20}
                  color={this.props.darkMode ? Helper.darkModeLabelColor : "#333333"}
                />
              </div>
              {!this.props.collapsed &&
                <div className={styles.IA_menuItemText}>
                  {this.localizationService.strings.PlayAdmin_MenuArea_Collapse}
                </div>
              }
            </div>
          }
        </div>
      </div >
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}