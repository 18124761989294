exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp {\n  font-size: 12px;\n  float: left;\n  margin-top: 3px;\n  display: block;\n  width: 100%;\n}\n\n.IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp .IA_personaSmallProfileImageWrapper_3HS9Hmt_ErbsUN_gsL04k5 {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp .IA_personaSmallProfileImage_2nQ0Km0xFFljvaesQPCLFA {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n  background-size: cover;\n}\n\n.IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp .IA_personaSmallProfileImageText_2WXXxBEBwwUWLwZBOsvmpH {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp .IA_personaSmallName_1UE6FPu39CeTT6EvHIjvsX {\n  float: none;\n  width: calc(100% - 40px);\n  height: 16px;\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  font-weight: 600;\n}\n\n.IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp .IA_personaSmallMetadata_1j4iYrtCbu7dv8CHImr6do {\n  float: none;\n  width: calc(100% - 40px);\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  color: #858585;\n  margin-top: 2px;\n}\n\n.IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp .IA_personaSmallModifiedDate_1aiv7SpT7F-X7gxpo5_o7T {\n  float: none;\n  height: 12px;\n  width: 100%;\n  margin-top: 1px;\n}", ""]);

// Exports
exports.locals = {
	"IA_personaSmall": "IA_personaSmall_nqcpahQ1lRyq-FDhKr9wp",
	"IA_personaSmallProfileImageWrapper": "IA_personaSmallProfileImageWrapper_3HS9Hmt_ErbsUN_gsL04k5",
	"IA_personaSmallProfileImage": "IA_personaSmallProfileImage_2nQ0Km0xFFljvaesQPCLFA",
	"IA_personaSmallProfileImageText": "IA_personaSmallProfileImageText_2WXXxBEBwwUWLwZBOsvmpH",
	"IA_personaSmallName": "IA_personaSmallName_1UE6FPu39CeTT6EvHIjvsX",
	"IA_personaSmallMetadata": "IA_personaSmallMetadata_1j4iYrtCbu7dv8CHImr6do",
	"IA_personaSmallModifiedDate": "IA_personaSmallModifiedDate_1aiv7SpT7F-X7gxpo5_o7T"
};