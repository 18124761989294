import { Promise } from 'es6-promise';
import * as React from 'react';
import * as styles from './LikesAndComments.css';
import { IComment } from '../../interfaces/IComment';
import { IAComment } from './comment/Comment';
import { IUser } from '../../interfaces/IUser';
import { IReply } from '../../interfaces/IReply';
import { IANewComment } from './newComment/NewComment';
import { ILike } from '../../interfaces/ILike';
import { IAItem } from '../../interfaces/IAItem';
import SocialService from '../../services/SocialService';
import Utils from './Utils';
import { Environment } from '../../services/Environment';
import { IAIcon } from '../icon/Icon';
import { LocalizationService } from '../../services/LocalizationService';
import { Helper } from '../../Helper';
import { LoggingService } from '../../services/LoggingService';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ENTER_KEY_CODE, SPACEBAR_KEY_CODE } from '../../global/Constants';
const IC_SPINNER = require('../../images/spinner.png');

declare global {
  interface Window {
    social: any;
  }
}

export interface ILikesAndCommentsProps {
  user: IUser;
  top?: number;
  itemId: string;
  item?: IAItem;
  tenant: string;
  component: string;
  instance: string;
  token: string;
  environment?: 'Development' | 'Test' | 'Production';
  source: 'Home' | 'Mobile' | 'SharePoint';
  imageUploadUrl: string;
  likesEnabled: boolean;
  commentsEnabled: boolean;
  likesOnCommentsEnabled?: boolean;
  color?: string;
  style?: React.CSSProperties;
  padding: number;
  likes?: ILike[];
  commentCount?: number;
  isMobile: boolean;
  dataAutomationIdPrefix?: string;
  newCommentPopupIsShowing: (isShowing: boolean) => void;
  likesUpdated: (likes: ILike[]) => void;
  commentsUpdated: (comments: number) => void;
  commentClicked?: () => void;
  commentCanceled?: () => void;
  isWriting: () => void;
}

export interface ILikesAndCommentsState {
  showSpinner: boolean;
  showAllComments: boolean;
  likes: ILike[];
  comments: IComment[];
  showLikeButtonLoader: boolean;
  allCommentsCount: number;
  reply: IReply;
  isMobile: boolean;
}

const DEFAULT_COLOR: string = '#025159';

export class IALikesAndComments extends React.Component<ILikesAndCommentsProps, ILikesAndCommentsState> {
  constructor(props: ILikesAndCommentsProps) {
    super(props);
    this.state = {
      showSpinner: false,
      showAllComments: false,
      likes: props.likes != undefined ? props.likes : undefined,
      comments: undefined,
      showLikeButtonLoader: false,
      allCommentsCount: props.commentCount != undefined ? props.commentCount : undefined,
      reply: undefined,
      isMobile: window.innerWidth < 667,
    };
    window.AppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: Environment.getApplicationInsightsKey(props.environment),
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        disableAjaxTracking: true,
      },
    });
    window.AppInsights.loadAppInsights();
  }

  public componentDidMount(): void {
    if (this.props.likesEnabled && this.props.likes == undefined) {
      this.setState({ showSpinner: true });
      if (this.props.item != undefined && this.props.item.type == 'News') {
        SocialService.getPublishingLikes(this.props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
          if (response != 400 || response != 500 || response != 204) {
            let likes = [];
            response.likedBy.forEach((user: any) => {
              let userWhoLiked = Helper.syncUserProperties(user);
              likes.push({
                user: {
                  userPrincipalName: userWhoLiked.email.toLowerCase(),
                  id: userWhoLiked.id,
                  image: userWhoLiked.image,
                  displayName: userWhoLiked.name,
                },
              });
            });
            this.setState({ likes, showSpinner: false });
          } else {
            console.log('Error getting publiching likes');
          }
        });
      } else {
        SocialService.getLikes(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((likes: ILike[]) => {
          this.setState({ likes, showSpinner: false });
        });
      }
    }
    if (this.props.commentsEnabled) {
      this.setState({ showSpinner: true });
      if (this.props.item != undefined && this.props.item.type == 'News') {
        SocialService.getPublishingComments(this.props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
          if (response != 400 || response != 500 || response != 204) {
            console.log(response);
            this.setState({ allCommentsCount: response.length, comments: response, showSpinner: false });
          } else {
            console.log('Error getting publiching comments');
          }
        });
      } else {
        SocialService.getLatestComments(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((comments) => {
          this.setState({ comments, showSpinner: false });
        });
        SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((allCommentsCount: number) => {
          this.setState({ allCommentsCount });
        });
      }
    }
  }

  public componentWillReceiveProps(props: ILikesAndCommentsProps): void {
    if (props.itemId != undefined) {
      if (props.likesEnabled) {
        if (props.likes == undefined && this.state.likes == undefined) {
          this.setState({ showSpinner: true });
          if (props.item != undefined && props.item.type == 'News') {
            SocialService.getPublishingLikes(props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
              if (response != 400 || response != 500 || response != 204) {
                let likes = [];
                response.likedBy.forEach((user: any) => {
                  console.log(user);
                  let userWhoLiked = Helper.syncUserProperties(user);
                  likes.push({
                    user: {
                      userPrincipalName: userWhoLiked.email.toLowerCase(),
                      id: userWhoLiked.id,
                      image: userWhoLiked.image,
                      displayName: userWhoLiked.name,
                    },
                  });
                });
                this.setState({ likes, showSpinner: false });
              } else {
                console.log('Error getting publiching likes');
              }
            });
          } else {
            SocialService.getLikes(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, props.itemId).then((likes: ILike[]) => {
              this.setState({ likes, showSpinner: false });
            });
          }
        } else {
          if (props.likes != undefined) {
            this.setState({ likes: props.likes, showSpinner: false });
          }
        }
      }
      if (props.commentsEnabled) {
        if (props.item != undefined && props.item.type == 'News') {
          SocialService.getPublishingComments(props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
            if (response != 400 || response != 500 || response != 204) {
              console.log(response);
              this.setState({ allCommentsCount: response.length, comments: response, showSpinner: false });
            } else {
              console.log('Error getting publiching comments');
            }
          });
        } else {
          SocialService.getLatestComments(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, props.itemId).then((comments) => {
            this.setState({ comments, showSpinner: false, showAllComments: false });
          });
          if (props.commentCount == undefined) {
            SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, props.itemId).then((allCommentsCount: number) => {
              this.setState({ allCommentsCount });
            });
          } else {
            this.setState({ allCommentsCount: props.commentCount });
          }
        }
      }
    }
  }

  private likeButtonOnClick(hasLiked: boolean, isPublishingNews: boolean): void {
    if (!this.state.showLikeButtonLoader && this.props.user != undefined && !this.state.showSpinner) {
      if (hasLiked) {
        LoggingService.trackEvent(this.constructor.name, {
          ActionType: 'UnlikeFromLikesAndComments',
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : '-',
          ContentId: this.props.item != undefined ? this.props.item.id : '-',
          Tenant: this.props.tenant,
          Instance: this.props.instance,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment,
        });
      } else {
        LoggingService.trackEvent(this.constructor.name, {
          ActionType: 'LikeFromLikesAndComments',
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : '-',
          ContentId: this.props.item != undefined ? this.props.item.id : '-',
          Tenant: this.props.tenant,
          Instance: this.props.instance,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment,
        });
      }
      this.setState({ showLikeButtonLoader: true });
      if (isPublishingNews) {
        SocialService.publishingLike(this.props.item, this.props.environment, this.props.tenant, this.props.token, !hasLiked).then((response: any) => {
          if (response != 400 || response != 500 || response != 204) {
            let likes = [];
            response.likedBy.forEach((user: any) => {
              let userWhoLiked = Helper.syncUserProperties(user);
              likes.push({
                user: {
                  userPrincipalName: userWhoLiked.email.toLowerCase(),
                  id: userWhoLiked.id,
                  image: userWhoLiked.image,
                  displayName: userWhoLiked.name,
                },
              });
            });
            this.setState({ likes, showLikeButtonLoader: false });
            this.props.likesUpdated(likes);
          } else {
            console.log('Error getting publiching likes');
          }
        });
      } else {
        if (hasLiked) {
          let likeId: string;
          this.state.likes.forEach((like: ILike) => {
            if (this.props.user && like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase()) {
              likeId = like.id;
            }
          });
          SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, likeId).then((likes: ILike[]) => {
            this.setState({ likes, showLikeButtonLoader: false });
            this.props.likesUpdated(likes);
          });
        } else {
          SocialService.like(
            this.props.environment,
            this.props.tenant,
            this.props.component,
            this.props.instance,
            this.props.token,
            this.props.user,
            this.props.itemId,
            this.props.source
          ).then((likes: ILike[]) => {
            this.setState({ likes, showLikeButtonLoader: false });
            this.props.likesUpdated(likes);
          });
        }
      }
    }
  }

  private prevCommentsOnClick(): void {
    if (this.state.showAllComments) {
      SocialService.getLatestComments(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((comments) => {
        console.log(comments);
        this.setState({ comments });
      });
    } else {
      SocialService.getComments(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((comments) => {
        console.log(comments);
        this.setState({ comments });
      });
    }
    this.setState({ showAllComments: !this.state.showAllComments });
  }

  public render(): JSX.Element {
    let isPublishingNews = this.props.item?.type == 'News' ? true : false;
    // likes
    let hasLiked = false;
    let likes: JSX.Element[];
    if (this.props.likesEnabled && this.state.likes != undefined) {
      this.state.likes.forEach((like: any) => {
        if (isPublishingNews) {
          if (this.props.user && like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase()) {
            hasLiked = true;
          }
        } else {
          if (this.props.user && like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase()) {
            hasLiked = true;
          }
        }
      });
      likes = Utils.getLikesWithExamples(this.state.likes, this.props.user, this.props.color, this.props.isMobile);
    }
    // Comments
    let showPreviousReplies;
    if (this.state.comments != undefined && this.state.showAllComments && this.state.allCommentsCount == this.state.comments.length + 1) {
      showPreviousReplies = new LocalizationService().strings.LikesAndComments_ShowOnePreviousComment; // `Show 1 previous comment`;
    } else if (this.state.comments != undefined && !this.state.showAllComments && this.state.allCommentsCount > this.state.comments.length) {
      showPreviousReplies = `${new LocalizationService().strings.LikesAndComments_ShowPreviousComments_1} ${this.state.allCommentsCount - 2} ${
        new LocalizationService().strings.LikesAndComments_ShowPreviousComments_2
      }`;
    }
    //
    let comments: JSX.Element[] = [];
    if (this.props.commentsEnabled && this.state.comments != undefined) {
      this.state.comments.forEach((comment: IComment, index) => {
        let myComment: boolean;
        if (isPublishingNews) {
          myComment = this.props.user && comment.user.displayName == this.props.user.displayName;
        } else {
          myComment = this.props.user && comment.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase();
        }
        comments.push(
          <IAComment
            key={index}
            itemId={this.props.itemId}
            item={this.props.item}
            id={comment.id}
            index={index}
            tenant={this.props.tenant}
            component={this.props.component}
            instance={this.props.instance}
            token={this.props.token}
            environment={this.props.environment}
            source={this.props.source}
            user={this.props.user}
            author={comment.user}
            content={comment.content}
            likes={this.props.likesEnabled || this.props.likesOnCommentsEnabled ? comment.likes : undefined}
            created={comment.created}
            myComment={myComment}
            reply={comment.reply}
            highlightColor={this.props.color != undefined ? this.props.color : DEFAULT_COLOR}
            likesEnabled={this.props.likesEnabled || this.props.likesOnCommentsEnabled}
            editorOpen={true}
            padding={this.props.padding}
            selectedComment={this.state.reply && this.state.reply.commentId === comment.id}
            isMobile={this.props.isMobile}
            delete={(index: number, commentId: string) => {
              let comments = this.state.comments;
              const commentsAmount = Math.sign(this.state.allCommentsCount - 1) === -1 ? 0 : this.state.allCommentsCount - 1;
              comments.splice(index, 1);
              this.setState({ comments });
              if (isPublishingNews) {
                SocialService.deletePublishingComment(this.props.item, this.props.environment, this.props.tenant, this.props.token, commentId);
              } else {
                SocialService.deleteComment(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, commentId);
              }
              this.props.commentsUpdated(commentsAmount);
            }}
            replyComment={(reply: IReply) => {
              this.setState({ reply }, () => {
                this.props.commentClicked();
              });
            }}
            likesUpdated={(likes: ILike[]) => {
              comment.likes = likes;
              // this.props.commentsUpdated(comments.length);
            }}
          />
        );
      });
    }
    let disabled: boolean = !this.props.likesEnabled && !this.props.commentsEnabled;
    return (
      <div
        ref={(social: any) => {
          window.social = social;
        }}
        className={styles.IA_likesAndComments}
        id="IALikesAndComments"
        style={{
          paddingLeft: this.props.padding,
          paddingRight: this.props.padding,
          paddingBottom: disabled ? 0 : 10,
          ...this.props.style,
        }}
      >
        <div
          className={styles.IA_commandBar}
          style={{
            height: disabled ? 0 : 36,
            paddingTop: disabled ? 0 : 3,
            paddingBottom: disabled ? 0 : 3,
          }}
        >
          {/* Like button */}
          {this.props.likesEnabled && (
            <div
              className={[styles.IA_commandBarButton, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              role="button"
              style={{
                color: this.props.color != undefined ? this.props.color : DEFAULT_COLOR,
                opacity: this.props.user != undefined ? 1 : 0.5,
              }}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-like`}
              onClick={(e) => {
                e.stopPropagation();
                this.likeButtonOnClick(hasLiked, isPublishingNews);
              }}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.likeButtonOnClick(hasLiked, isPublishingNews);
                }
              }}
            >
              <div className={styles.IA_commandBarButtonIcon}>
                <IAIcon
                  title={hasLiked ? 'LikeFilled' : 'Like'}
                  size={14}
                  color={this.props.color != undefined ? this.props.color : DEFAULT_COLOR}
                  style={{
                    marginTop: 2,
                    opacity: this.state.showSpinner ? 0.5 : 1,
                  }}
                />
              </div>
              <div
                className={styles.IA_commandBarButtonText}
                style={{
                  opacity: this.state.showSpinner ? 0.5 : 1,
                }}
              >
                {hasLiked ? new LocalizationService().strings.LikesAndComments_Unlike : new LocalizationService().strings.LikesAndComments_Like}
              </div>
            </div>
          )}
          {/* Comment button */}
          {/* {this.props.commentsEnabled &&
            <div
              className={styles.IA_commandBarButton}
              style={{
                color: this.props.color != undefined ? this.props.color : DEFAULT_COLOR
              }}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-comment`}
              onClick={() => this.setState({ addComment: !this.state.addComment, reply: undefined },
                () => {
                  if (this.props.commentClicked) {
                    this.props.commentClicked();
                  }
                })}
            >
              <div
                className={styles.IA_commandBarButtonIcon}
              >
                <IAIcon
                  title="Comment"
                  size={14}
                  color={this.props.color != undefined ? this.props.color : DEFAULT_COLOR}
                  style={{
                    marginTop: 4
                  }}
                />
              </div>
              <div className={styles.IA_commandBarButtonText}>
                {new LocalizationService().strings.LikesAndComments_Comment}
              </div>
            </div>
          } */}
        </div>
        {/* Show likes */}
        <div className={styles.IA_likes}>{likes}</div>
        {/* Show previous comments */}
        {this.props.commentsEnabled && this.state.allCommentsCount > 2 && !isPublishingNews && (
          <div className={styles.IA_commandBar}>
            {/* Load previous comments button */}
            <div
              className={[styles.IA_commandBarButton, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              role="button"
              style={{
                color: this.props.color != undefined ? this.props.color : DEFAULT_COLOR,
              }}
              onClick={() => this.prevCommentsOnClick()}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.prevCommentsOnClick();
                }
              }}
            >
              <div className={styles.IA_commandBarButtonText}>{this.state.showAllComments ? new LocalizationService().strings.LikesAndComments_OnlyShowLatestComments : showPreviousReplies}</div>
            </div>
          </div>
        )}
        {this.props.commentsEnabled && (
          <div className={styles.IA_comments}>
            {this.state.showSpinner && (
              <div
                className={styles.IA_spinner}
                style={{
                  backgroundImage: `url('${IC_SPINNER}')`,
                }}
              ></div>
            )}
            {comments}
            <IANewComment
              token={this.props.token}
              tenant={this.props.tenant}
              environment={this.props.environment}
              imageUploadUrl={this.props.imageUploadUrl}
              buttonColor={this.props.color}
              reply={this.state.reply}
              addComment={true}
              isMobile={this.state.isMobile}
              user={this.props.user}
              dataAutomationIdPrefix={this.props.dataAutomationIdPrefix + '-comment'}
              highlightColor={this.props.color != undefined ? this.props.color : DEFAULT_COLOR}
              comment={(content: string, mentions: IUser[], reply?: IReply) => {
                return new Promise((resolve) => {
                  let commentId = reply != undefined ? reply.commentId : undefined;
                  LoggingService.trackEvent(this.constructor.name, {
                    ActionType: 'NewCommentFromLikesAndComments',
                    Component: this.props.component,
                    ReplyToId: commentId != undefined ? commentId : '-',
                    ContentType: this.props.item != undefined ? this.props.item.type : '-',
                    ContentId: this.props.item != undefined ? this.props.item.id : '-',
                    Tenant: this.props.tenant,
                    Instance: this.props.instance,
                    UserId: this.props.token,
                    Source: this.props.source,
                    Environment: this.props.environment,
                  });
                  if (isPublishingNews) {
                    SocialService.addPublishingComment(this.props.item, this.props.environment, this.props.tenant, this.props.token, content).then(() => {
                      SocialService.getPublishingComments(this.props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
                        if (response != 400 || response != 500 || response != 204) {
                          this.setState({ allCommentsCount: response.length, comments: response, showSpinner: false });
                          this.props.commentsUpdated(response);
                        } else {
                          console.log('Error getting publiching comments');
                        }
                        resolve();
                      });
                    });
                  } else {
                    SocialService.addComment(
                      this.props.environment,
                      this.props.tenant,
                      this.props.component,
                      this.props.instance,
                      this.props.token,
                      this.props.user,
                      content,
                      mentions,
                      this.props.itemId,
                      this.props.source,
                      commentId
                    ).then(() => {
                      SocialService.getLatestComments(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((comments) => {
                        this.setState({ comments, showSpinner: false, reply: undefined });
                      });
                      SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then(
                        (allCommentsCount: number) => {
                          this.setState({ allCommentsCount, reply: undefined });
                          this.props.commentsUpdated(allCommentsCount);
                        }
                      );
                      resolve();
                    });
                  }
                });
              }}
              cancelReply={() => {
                this.setState({ reply: undefined });
                this.props.commentCanceled();
              }}
              isWriting={() => {
                this.props.isWriting();
              }}
              newCommentPopupIsShowing={(isShowing: boolean) => this.props.newCommentPopupIsShowing(isShowing)}
            />
          </div>
        )}
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, 'componentDidCatch', error);
  }
}
