import * as React from 'react';
import { LoggingService } from '../../services/LoggingService';
import Select, { components } from "react-select";
import { IAIcon } from '../icon';
import * as styles from './IconDropDown.css';
import { IIconDropDown } from '../../interfaces/IIconDropDown';
import { Helper } from '../../Helper';

export interface IIconDropDownProps {
  options: IIconDropDown[];
  selectedOption: IIconDropDown;
  style: any;
  loading?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  label?: string;
  placeholder?: string;
  darkMode?: boolean;
  onChange: (selected: IIconDropDown) => void;
}

export interface IIconDropDownState {
}

export class IAIconDropDown extends React.Component<IIconDropDownProps, IIconDropDownState> {

  constructor(props: IIconDropDownProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    const { Option } = components;
    const IconOption = props => (
      <Option {...props}>
        <div className={styles.IA_option}>
          <div className={styles.IA_icon}>
            <IAIcon
              title={props.data.icon}
              size={20}
              color={this.props.darkMode ? "#ffffff" : "#333333"}
              style={{ marginTop: 6, zIndex: 100 }}
            />
          </div>
          <div className={styles.IA_label} style={{ color: this.props.darkMode ? Helper.darkModeLabelColor : '#333333' }}>
            {props.data.label}
          </div>
        </div>
      </Option>
    );
    return (
      <div style={{
        cursor: "pointer",
        ...this.props.style
      }}
      >
        {this.props.label &&
          <label style={{ fontSize: 12, lineHeight: "30px", color: this.props.darkMode ? Helper.darkModeLabelColor : '#000' }}>{this.props.label.toUpperCase()}</label>
        }
        <Select
          defaultValue={this.props.selectedOption}
          options={this.props.options}
          isLoading={this.props.loading}
          isDisabled={this.props.disabled}
          isClearable={this.props.clearable}
          isSearchable={false}
          placeholder={this.props.placeholder}
          components={{ Option: IconOption }}
          onChange={(selected: IIconDropDown) => {
            this.props.onChange(selected);
          }}
          styles={{
            menuList: (provided) => (this.props.darkMode ? {
              ...provided,
              backgroundColor: Helper.darkModeInputFieldBackgroundColor,
              borderStyle: "none"
            } : {
              ...provided,
            }),
            option: (provided, state) => (this.props.darkMode ? {
              ...provided,
              backgroundColor: state.isFocused ? Helper.darkModeCardBackgroundColor : (state.isSelected ? "#555454" : Helper.darkModeInputFieldBackgroundColor),
              color: '#fff',
            } : {
              ...provided,
              backgroundColor: state.isFocused ? "#eeeeee" : (state.isSelected ? "#a8a8a8" : "#ffffff"),
            }),
            control: (provided) => (this.props.darkMode ? {
              ...provided,
              backgroundColor: Helper.darkModeInputFieldBackgroundColor,
              color: '#fff',
              borderStyle: "none",
            } : {
              ...provided
            }),
            valueContainer: () => {
              return {
                paddingLeft: this.props.selectedOption ? 40 : 10,
                height: 23
              };
            },
            singleValue: () => {
              return {
                color: this.props.darkMode ? '#fff' : '#333333"'
              }
            }
          }}
        />
        {this.props.selectedOption &&
          <IAIcon
            title={this.props.selectedOption?.icon}
            size={20}
            color={this.props.darkMode ? "#ffffff" : "#333333"}
            style={{
              marginTop: -28,
              position: "relative",
              zIndex: 999999,
              marginLeft: 12,
              pointerEvents: "none"
            }}
          />
        }
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}