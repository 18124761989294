import { Environment } from './Environment';
import { IUserResult } from '../interfaces/IUserResult';
import { IUserRole } from '../interfaces/IUserRole';
import { Promise } from 'es6-promise';
import { LoggingService } from './LoggingService';
import { ICombinedUserResult } from '../interfaces/ICombinedUserResult';
import { FetchProxy } from 'intraactive-sdk-helper';
import { sdkContainerInstance } from '../global/Container';

export default class UserService {

  public static getUserRole(apiUrl: string, tenant: string, instanceId: number, token: string, component: string): Promise<IUserRole> {
    tenant = tenant.replace(".sharepoint.com", "");
    return new Promise((resolve) => {
      if (apiUrl && tenant && token && instanceId && component) {
        return new FetchProxy(`${apiUrl}/api/${tenant}.sharepoint.com/users/my/role?site=${component}&instanceId=${instanceId}`, {
          credentials: "same-origin",
          mode: "cors",
          headers: {
            "AuthToken": token
          }
        })
        .withContainer(sdkContainerInstance)
        .fetch()
        .then((response) => {
          if (!response.ok) {
            throw (response.status);
          } else {
            return response.json();
          }
        }).then(response => {
          resolve(response);
        });
      } else {
        LoggingService.trackException(UserService.name, "getUserRole", new Error("Missing parameter"));
        resolve(null);
      }
    });
  }

  public static getUserRoleForMessages(apiUrl: string, tenant: string, instanceId: number, token: string, component: string): Promise<IUserRole> {
    tenant = tenant.replace(".sharepoint.com", "");
    return new Promise((resolve) => {
      if (apiUrl && tenant && token && instanceId && component) {
        return new FetchProxy(`${apiUrl}/api/permissions/${tenant}.sharepoint.com/${component}/userrole?list=Instances&id=${instanceId}`, {
          credentials: "same-origin",
          mode: "cors",
          headers: {
            "AuthToken": token
          }
        })
        .withContainer(sdkContainerInstance)
        .fetch()
        .then((response) => {
          if (!response.ok) {
            throw (response.status);
          } else {
            return response.json();
          }
        }).then(response => {
          let highestRole: IUserRole;
          response.forEach((role: IUserRole) => {
            switch (role.title.toString()) {
              case "Administrator":
                resolve(role);
                break;
              case "Editor":
                highestRole = role;
                break;
              case "Reader":
                if (highestRole !== undefined && highestRole.title === "Editor") {
                  highestRole = highestRole;
                } else {
                  highestRole = role;
                }
                break;
              default:
                break;
            }
          });
          resolve(highestRole);
        });
      } else {
        LoggingService.trackException(UserService.name, "getUserRole", new Error("Missing parameter"));
        resolve(null);
      }
    });
  }

  public static getUsers(environment: "Development" | "Test" | "Production", token: string, tenant: string, searchQuery: string, showGroups?: boolean, top?: number): Promise<IUserResult> {
    return new Promise<IUserResult>((resolve: (result: IUserResult) => void, reject: (error: any) => void): void => {
      if (environment && tenant && token && searchQuery) {
        let apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}/users/search?searchQuery=${encodeURIComponent(searchQuery)}`;
        if (showGroups) {
          apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}/users/groups/search?searchQuery=${encodeURIComponent(searchQuery)}`;
        }
        if (top) {
          apiUrl += `?top=${top}`;
        }

        new FetchProxy(apiUrl, {
          method: "GET",
          headers: {
            'AuthToken': token,
            'Content-Type': 'application/json'
          }
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse: any) => {
              const userResult: IUserResult = {
                searchQuery: jsonResponse.searchQuery,
                users: jsonResponse.users
              };
              resolve(userResult);
            });
          })
          .catch(error => {
            LoggingService.trackException(UserService.name, "getUserRole", error);
            reject(null);
          });
      } else {
        LoggingService.trackException(UserService.name, "getUserRole", new Error("Missing parameter"));
        reject(null);
      }
    });
  }

  public static getSharePointAndGraphCombinedUsers(environment: "Development" | "Test" | "Production", token: string, tenant: string, siteName: string, searchQuery: string, top?: number): Promise<ICombinedUserResult> {
    return new Promise<ICombinedUserResult>((resolve: (result: ICombinedUserResult) => void, reject: (error: any) => void): void => {
      if (environment && tenant && token && searchQuery) {
        let apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}/users/component/${siteName}/search?searchQuery=${encodeURIComponent(searchQuery)}`;
        if (top) {
          apiUrl += `?top=${top}`;
        }

        new FetchProxy(apiUrl, {
          method: "GET",
          headers: {
            'AuthToken': token,
            'Content-Type': 'application/json'
          }
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse: any) => {
              const userResult: ICombinedUserResult = {
                searchQuery: jsonResponse.searchQuery,
                users: jsonResponse.users
              };
              resolve(userResult);
            });
          })
          .catch(error => {
            LoggingService.trackException(UserService.name, "getUserRole", error);
            reject(null);
          });
      } else {
        LoggingService.trackException(UserService.name, "getUserRole", new Error("Missing parameter"));
        reject(null);
      }
    });
  }

  public static isSiteOwner(environment: "Development" | "Test" | "Production", tenant: string, token: string, siteName: string): Promise<boolean> {
    return new Promise<boolean>((resolve: (isSiteOwner: boolean) => void, reject: (error: any) => void): void => {
      if (environment && tenant && token && siteName) {
        const apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}.sharepoint.com/users/issiteowner?site=${siteName}`;
        new FetchProxy(apiUrl, { method: "GET", credentials: "same-origin", headers: { 'AuthToken': token } })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse) => resolve(jsonResponse));
          })
          .catch(error => {
            LoggingService.trackException(UserService.name, "isSiteOwner", error);
            reject(null);
          });
      } else {
        LoggingService.trackException(UserService.name, "isSiteOwner", new Error("Missing parameter"));
        reject(null);
      }
    });
  }


  public static getCurrentUser(apiUrl: string, tenant: string, token: string): Promise<any> {
    return new Promise<any>((resolve: (profile: any) => void, reject: (error: any) => void): void => {
      if (apiUrl && tenant && token) {
        const endpointUrl = apiUrl + `/api/${tenant}/users/currentUser`;
        new FetchProxy(endpointUrl, { method: "GET", credentials: "same-origin", headers: { 'AuthToken': token } })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse) => resolve(jsonResponse));
          })
          .catch(error => {
            LoggingService.trackException(UserService.name, "getCurrentUser", error);
            reject(null);
          });
      } else {
        LoggingService.trackException(UserService.name, "getCurrentUser", new Error("Missing parameter"));
        reject(null);
      }
    });
  }

  public static getUPNByLookupId(environment: "Development" | "Test" | "Production", token: string, tenant: string, component: string, instance: string, lookupId: string): Promise<string> {
    return new Promise<string>((resolve: (status: string) => void, reject: (error: any) => void): void => {
      if (environment && token && tenant && component && lookupId) {
        const tenantRef = `${tenant}.sharepoint.com`;
        const siteName = component === 'Messages' ? "IntraActive-Messages" : "IntraActive-Events";
        const apiUrl = `${Environment.getEnvironmentForMainAPI(environment)}/api/${tenantRef}/users/${siteName}/${lookupId}`;
        new FetchProxy(apiUrl, { method: "GET", headers: { 'Content-Type': 'application/json', 'AuthToken': token } })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse) => resolve(jsonResponse));
          })
          .catch(error => {
            LoggingService.trackException("MessageService", "getUPNByLookupId", error, instance);
            reject(null);
          });
      } else {
        LoggingService.trackException("MessageService", "getUPNByLookupId", new Error("Missing parameter"), instance);
        reject(null);
      }
    });
  }
}