import * as React from 'react';
import * as styles from "./IAAuthors.css";
import { IAIcon } from '../../icon';
import { IUser } from '../../../interfaces/IUser';
import { IAPersonaSmall } from '../../personaSmall/PersonaSmall';
import { LoggingService } from '../../../services/LoggingService';

export interface IAuthorsProps {
  authors: IUser[];
  date: string;
  highlightColor: string;
  style?: React.CSSProperties;
}

export interface IAuthorsState {
  expanded: boolean;
}

export default class IAAuthors extends React.Component<IAuthorsProps, IAuthorsState> {

  constructor(props: IAuthorsProps) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  render() {
    let expandedContent = [];
    if (this.props.authors != undefined && this.props.authors.length > 1) {
      for (let i = 1; i < this.props.authors.length; i++) {
        expandedContent.push(
          <IAPersonaSmall
            key={`expandedContent_${i}`}
            profileImage={this.props.authors[i].image}
            name={this.props.authors[i].displayName}
            metadata={this.props.authors[i].userPrincipalName}
            backgroundColor={this.props.highlightColor}
            styles={{
              width: "calc(100% - 50px)",
              float: "left"
            }}
          />
        )
      }
    }
    return (
      <div
        className={styles.IA_authors}
        style={
          this.props.style
        }
      >
        {this.props.authors != undefined && this.props.authors.length > 0 &&
          <IAPersonaSmall
            profileImage={this.props.authors[0].image}
            name={this.props.authors[0].displayName}
            metadata={this.props.date}
            backgroundColor={this.props.highlightColor}
            styles={{
              width: "calc(100% - 50px)",
              minWidth: 170,
              float: "left"
            }}
          />
        }
        {this.props.authors != undefined && this.props.authors.length > 1 &&
          <div
            className={styles.IA_expandButton}
            style={{
              color: this.props.highlightColor
            }}
            onClick={() => {
              this.setState({ expanded: !this.state.expanded });
            }}
          >
            {this.state.expanded ?
              <IAIcon
                title="ArrowUp"
                size={18}
                color={this.props.highlightColor}
              />
              :
              `+ ${this.props.authors.length - 1}`
            }
          </div>
        }
        {this.state.expanded &&
          <div className={styles.IA_expandedContent}>
            {expandedContent}
          </div>
        }
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}