exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_Mxo3Atg4mYabOKpYNfTOt {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_Mxo3Atg4mYabOKpYNfTOt .likes_1DI7KfgdzgoG9qkZuubria {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.likesAndComments_Mxo3Atg4mYabOKpYNfTOt .spinner_1uQnE1Y201e3qXGdUEJlo2 {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_26PsKvIxEMN_yIYKWpVvCa 700ms linear infinite;\n  -moz-animation: spin_26PsKvIxEMN_yIYKWpVvCa 700ms linear infinite;\n  animation: spin_26PsKvIxEMN_yIYKWpVvCa 700ms linear infinite;\n}\n\n.likesAndComments_Mxo3Atg4mYabOKpYNfTOt .comments_2KEXyZoboU0zqbxUenxSi2 {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_Mxo3Atg4mYabOKpYNfTOt .likesText_W2MuazDtDpsWbZTw4wj97 {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n.likesAndComments_Mxo3Atg4mYabOKpYNfTOt .likesTextVertical_-PM_cw2IyJ2TIWq3OzLBw {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_Mxo3Atg4mYabOKpYNfTOt .commentText_QzS-IsCjviFfp7lLDDf9v {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_26PsKvIxEMN_yIYKWpVvCa {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_26PsKvIxEMN_yIYKWpVvCa {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_26PsKvIxEMN_yIYKWpVvCa {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_Mxo3Atg4mYabOKpYNfTOt",
	"likes": "likes_1DI7KfgdzgoG9qkZuubria",
	"spinner": "spinner_1uQnE1Y201e3qXGdUEJlo2",
	"spin": "spin_26PsKvIxEMN_yIYKWpVvCa",
	"comments": "comments_2KEXyZoboU0zqbxUenxSi2",
	"likesText": "likesText_W2MuazDtDpsWbZTw4wj97",
	"likesTextVertical": "likesTextVertical_-PM_cw2IyJ2TIWq3OzLBw",
	"commentText": "commentText_QzS-IsCjviFfp7lLDDf9v"
};