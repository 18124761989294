import * as React from 'react';
import { LoggingService } from '../../services/LoggingService';
import * as styles from './MobileMenu.css';
import { IAIcon } from '../icon';

export interface IMobileMenuProps {
  darkMode: boolean;
  highlightColor: string;
  showMobileMenu: boolean;
  toogleMobileMenu: () => void;
}

export class IAMobileMenu extends React.Component<IMobileMenuProps> {


  constructor(props: IMobileMenuProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div
        className={this.props.darkMode ? styles.IA_mobileMenumDark : styles.IA_mobileMenum}
      >
        <div className={styles.IA_menuItemIcon}>
          <IAIcon
            title={"BurgerMenu"}
            size={20}
            color={this.props.highlightColor}
            style={{
              marginTop: 8
            }}
            onClick={() => this.props.toogleMobileMenu()}
          />
        </div>
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}