import * as React from 'react';
import * as styles from './Popup.css';
import { LoggingService } from '../../services/LoggingService';
import { IAIcon } from '../icon';

export interface IIAPopupProps {
  headline?: string;
  headlineColor?: string;
  headlineSize?: number;
  headlineWidth?: string;
  backgroundImage?: string;
  content: JSX.Element;
  closeButtonColor?: string;
  closeButtonBackground?: string;
  styles?: any;
  close?: () => void;
}

export class IAPopup extends React.Component<IIAPopupProps, {}> {

  constructor(props: IIAPopupProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div className={styles.IA_popup}>
        <div
          className={styles.IA_popupBackground}
          onClick={() => {
            if (this.props.close) {
              this.props.close();
            }
          }}
        />
        <div
          className={styles.IA_popupContentBox}
          style={{
            backgroundImage: this.props.backgroundImage ? `url(${this.props.backgroundImage})` : "none"
          }}
        >
          {this.props.close != undefined &&
            <IAIcon
              title={"Close"}
              size={24}
              color={this.props.closeButtonColor ? this.props.closeButtonColor : "#000000"}
              style={{
                float: "right",
                backgroundColor: this.props.closeButtonBackground,
                borderRadius: 24,
                width: 24,
                height: 24,
                lineHeight: "24px",
                padding: this.props.closeButtonBackground ? 2 : 0
              }}
              onClick={() => this.props.close()}
            />
          }
          {this.props.headline &&
            <h1 style={{
              color: this.props.headlineColor ? this.props.headlineColor : "#333333",
              fontSize: this.props.headlineSize ? this.props.headlineSize : 19,
              width: this.props.headlineWidth ? this.props.headlineWidth : "100%"
            }}>{this.props.headline}</h1>
          }
          {this.props.content}
        </div>
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}