import * as React from 'react';
import { LoggingService } from '../../../services/LoggingService';
import * as styles from './MenuItem.css';
import { IAIcon } from '../../icon';
import { Helper } from '../../../Helper';

export interface IProps {
  label: string;
  icon: string;
  darkMode: boolean;
  highlightColor: string;
  isTeams: boolean;
  selected: boolean;
  onSelect: () => void;
}

export class MenuItem extends React.Component<IProps> {


  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div
        className={this.props.darkMode ? styles.IA_menuItemDark : styles.IA_menuItem}
        style={{
          borderLeft: this.props.selected ? `3px solid ${this.props.highlightColor}` : "3px solid transparent"
        }}
        onClick={() => {
          this.props.onSelect();
        }}
      >
        <div
          style={{marginTop: this.props.isTeams ? -3 : 0}}
          className={styles.IA_menuItemIcon}
        >
          <IAIcon
            title={this.props.icon}
            size={20}
            color={this.props.selected ? (this.props.darkMode ? "#ffffff" : "#000000") : (this.props.darkMode ? Helper.darkModeLabelColor : "#333333")}
            style={{
              marginTop: 8
            }}
          />
        </div>
        <div
          className={styles.IA_menuItemText}
          style={{
            fontWeight: this.props.selected ? "bold" : "normal",
            color: this.props.selected ? (this.props.darkMode ? "#ffffff" : "#000000") : (this.props.darkMode ? Helper.darkModeLabelColor : "#333333")
          }}
        >
          {this.props.label}
        </div>
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}