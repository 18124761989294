exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_popup_bMA2w9ExF-Z6l60SUGgLW .IA_popupBackground__zXOP7tJnfIXMFRsYnqcI {\n  width: 100vw;\n  height: 100vh;\n  background-color: #ffffff80;\n  position: fixed;\n  left: 0px;\n  top: 0px;\n  z-index: 2000000;\n}\n\n.IA_popup_bMA2w9ExF-Z6l60SUGgLW h1 {\n  clear: both;\n  margin-bottom: 25px;\n  font-weight: lighter;\n  font-size: 22px;\n}\n\n.IA_popup_bMA2w9ExF-Z6l60SUGgLW .IA_popupContentBox_28ypmjC32iCjXjHJklgk-J {\n  position: fixed;\n  top: 40%;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  width: 400px;\n  max-height: 100vh;\n  overflow-y: auto;\n  background-color: #ffffff;\n  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);\n  padding: 20px;\n  padding-bottom: 20px;\n  box-sizing: border-box;\n  text-align: left;\n  z-index: 2000001;\n  background-position: center;\n  background-size: cover;\n  font-family: \"Segoe UI Web (West European)\", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;\n}\n\n@media only screen and (max-device-width: 430px) and (orientation: portrait) {\n  .IA_popup_bMA2w9ExF-Z6l60SUGgLW .IA_popupContentBox_28ypmjC32iCjXjHJklgk-J {\n    width: calc(100vw - 40px);\n\n  }\n}\n\n.IA_closeButton_OB0ZVuPch0pMppteANWfP {\n  position: relative;\n  float: right;\n  height: 30px;\n  width: 30px;\n  background-position: center;\n  background-size: 20px;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  background-repeat: no-repeat;\n  margin-top: -5px;\n  margin-right: -17px;\n  opacity: 0.4;\n}", ""]);

// Exports
exports.locals = {
	"IA_popup": "IA_popup_bMA2w9ExF-Z6l60SUGgLW",
	"IA_popupBackground": "IA_popupBackground__zXOP7tJnfIXMFRsYnqcI",
	"IA_popupContentBox": "IA_popupContentBox_28ypmjC32iCjXjHJklgk-J",
	"IA_closeButton": "IA_closeButton_OB0ZVuPch0pMppteANWfP"
};