import * as React from 'react';
import * as styles from './ImageViewer.css';
import { LoggingService } from '../../../services/LoggingService';
import { IComposerImage } from '../../../interfaces/IComposerImage';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { IAIcon } from '../../icon';
import { Environment } from '../../../services/Environment';
import { Helper } from '../../../Helper';

export interface IProps {
  token: string;
  environment: "Development" | "Test" | "Production";
  tenant: string;
  images: IComposerImage[];
  index: number;
  isMobile: boolean;
  close: () => void;
}

export interface IState {
  index: number;
}

export class ImageViewer extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      index: props.index + 1
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.index !== this.props.index) {
      this.setState({ index: props.index + 1 });
    }
  }

  public render(): JSX.Element {
    const images = [];
    if (this.props.images) {
      this.props.images.forEach((image: IComposerImage, index) => {
        images.push(
          <div
            key={`IA_imageViewer_${index}`}
            style={{
              position: "relative",
              float: "left",
              textAlign: "center"
            }}
            onClick={() => this.props.close()}
          >
            <div className={styles.IA_imageViewerSpinner}>
              <IAIcon
                title="Spinner"
                size={20}
                color={"#999999"}
              />
            </div>
            <div
              className={styles.IA_imageViewerImage}
              style={{
                backgroundImage: (index + 1 === this.state.index) ? "none" : `url(${Helper.getImageUrl(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, image.url, image.modified, "Full")})`
              }}
            />
            {(index + 1 === this.state.index) &&
              <TransformComponent
                wrapperStyle={{
                  position: "absolute"
                }}
              >
                <div
                  className={styles.IA_imageViewerImage}
                  style={{
                    backgroundImage: `url(${Helper.getImageUrl(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, image.url, image.modified, "Full")})`
                  }}
                >
                </div>
              </TransformComponent>
            }
            {image.altText &&
              <div className={styles.IA_imageViewerImageAltTextWrapper}>
                <div className={styles.IA_imageViewerImageAltText}>{image.altText}</div>
              </div>
            }
          </div>
        );
      });
    }
    return images && images.length > 0 ? (
      <div
        id="IA_imageViewer"
        className={styles.IA_imageViewer}
      >
        <div className={styles.IA_imageViewerBackground} />
        <div
          className={styles.IA_imageViewerContainer}
          style={{
            width: `${this.props.images.length * 100}vw`,
            left: -window.innerWidth * (this.state.index - 1)
          }}
        >
          <TransformWrapper
            initialScale={1}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <React.Fragment>
                <div className={styles.IA_navigation}>
                  <div
                    className={styles.IA_previous}
                    style={{
                      opacity: this.state.index > 1 ? 1 : 0
                    }}
                    onClick={() => this.state.index > 1 ? this.setState({ index: this.state.index - 1 }, () => resetTransform()) : {}}
                  >
                    <IAIcon
                      title={"ArrowLeft"}
                      size={25}
                      color={"white"}
                      onClick={() => { }}
                    />
                  </div>
                  <div
                    className={styles.IA_next}
                    style={{
                      opacity: this.props.images.length > this.state.index ? 1 : 0
                    }}
                    onClick={() => this.props.images.length > this.state.index ? this.setState({ index: this.state.index + 1 }, () => resetTransform()) : {}}
                  >
                    <IAIcon
                      title={"Arrow"}
                      size={25}
                      color={"white"}
                      onClick={() => { }}
                    />
                  </div>
                </div>
                {images}
                {!this.props.isMobile &&
                  <div className={styles.IA_imageViewerToolbox}>
                    <div
                      className={styles.IA_imageViewerToolboxButton}
                      onClick={() => zoomIn()}
                    >
                      <IAIcon
                        title={"ZoomIn"}
                        size={25}
                        color={"white"}
                        onClick={() => { }}
                      />
                    </div>
                    <div
                      className={styles.IA_imageViewerToolboxButton}
                      onClick={() => zoomOut()}
                    >
                      <IAIcon
                        title={"ZoomOut"}
                        size={25}
                        color={"white"}
                        onClick={() => { }}
                      />
                    </div>
                    <div
                      className={styles.IA_imageViewerToolboxButton}
                      onClick={() => resetTransform()}
                    >
                      <IAIcon
                        title={"Restart"}
                        size={25}
                        color={"white"}
                        onClick={() => { }}
                      />
                    </div>
                    <div
                      className={styles.IA_imageViewerToolboxButton}
                      onClick={() => this.props.close()}
                    >
                      <IAIcon
                        title={"Close"}
                        size={25}
                        color={"white"}
                        onClick={() => { }}
                      />
                    </div>
                  </div>
                }
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
      </div>
    ) : (<div></div>);
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}