import * as React from 'react';
import * as styles from "./Comment.css";
import { Helper } from './../../../Helper';
import { IAIcon } from '../../icon';
import Utils from '../Utils';
import { IAItem } from '../../../interfaces/IAItem';
import { IUser } from '../../../interfaces/IUser';
import { ILike } from '../../../interfaces/ILike';
import { IReply } from '../../../interfaces/IReply';
import SocialService from '../../../services/SocialService';
import { Environment } from '../../../services/Environment';
import DateTimeService from '../../../services/DateTimeService';
import { LoggingService } from '../../../services/LoggingService';
import { LocalizationService } from '../../../services/LocalizationService';
import { ENTER_KEY_CODE, SPACEBAR_KEY_CODE } from '../../../global/Constants';
const IC_DELETE = require("../../../images/close.png");

export interface ICommentProps {
  index: number;
  itemId: string;
  item?: IAItem;
  id: string;
  user: IUser;
  tenant: string;
  component: string;
  instance: string;
  token: string;
  environment?: "Development" | "Test" | "Production";
  source: "Home" | "Mobile" | "SharePoint";
  author: IUser;
  content: string;
  created: Date;
  myComment: boolean;
  highlightColor: string;
  likesEnabled: boolean;
  likes?: ILike[];
  reply?: IReply;
  editorOpen: boolean;
  selectedComment: boolean;
  padding: number;
  isMobile: boolean;
  delete?: (index: number, commentId: string) => void;
  replyComment?: (reply: IReply) => void;
  likesUpdated: (likes: ILike[]) => void;
}

export interface ICommentState {
  showLikeButtonLoader: boolean;
  likes: ILike[];
  content: string;
}

export class IAComment extends React.Component<ICommentProps, ICommentState> {
  private readonly dateTimeService: DateTimeService = new DateTimeService();

  constructor(props: ICommentProps) {
    super(props);
    this.state = {
      //expanded: false,
      showLikeButtonLoader: false,
      likes: this.props.likes,
      //contentIsOverflowing: false,
      content: undefined
    };
  }

  public componentWillReceiveProps(props: ICommentProps): void {
    if (props.likes != this.state.likes) {
      this.setState({ likes: props.likes });
    }
    if (props.content != this.state.content) {
      this.setState({ content: this.useImageAPI(props.content) });
    }
  }

  private useImageAPI(contentToConvert): string {
    let content = contentToConvert;
    var contentDiv: any = document.createElement('div');
    contentDiv.innerHTML = content;
    let images = contentDiv.getElementsByTagName("img");
    for (let i = 0; i < images.length; i++) {
      let src = images[i].src;
      if (src.indexOf("sharepoint") != -1) {
        let imageAPI = "";
        if (this.props.environment == "Production") {
          imageAPI = "https://intraactive-image-functions.azurewebsites.net";
        } else if (this.props.environment == "Test") {
          imageAPI = "https://intraactive-image-functions-test.azurewebsites.net";
        } else if (this.props.environment == "Development") {
          imageAPI = "https://intraactive-image-functions-dev.azurewebsites.net";
        }
        let proxyUrl = Helper.getImageUrl(imageAPI, this.props.tenant, this.props.token, src, undefined, "Full");
        contentDiv.getElementsByTagName("img")[i].src = proxyUrl;
      }
    }
    return (contentDiv.innerHTML);
  }

  public componentDidMount(): void {
    this.setState({ content: this.useImageAPI(this.props.content) });
    //this.setState({ contentIsOverflowing: this.checkOverflow(document.getElementById(`comment-${this.props.id}`)) });
  }

  private likeButtonOnClick(hasLiked): void {
    if (!this.state.showLikeButtonLoader) {
      this.setState({ showLikeButtonLoader: true });
      if (hasLiked) {
        LoggingService.trackEvent(this.constructor.name, {
          ActionType: "UnlikeFromLikesAndComments",
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : "-",
          ContentId: this.props.item != undefined ? this.props.item.id : "-",
          Tenant: this.props.tenant,
          Instance: this.props.instance,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment
        });
        let likeId: string;
        this.state.likes.forEach((like: ILike) => {
          if (this.props.user && (like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase())) {
            likeId = like.id;
          }
        });
        SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, likeId, this.props.id).then((likes: ILike[]) => {
          this.setState({ likes, showLikeButtonLoader: false });
          this.props.likesUpdated(likes);
        });
      } else {
        LoggingService.trackEvent(this.constructor.name, {
          ActionType: "LikeFromLikesAndComments",
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : "-",
          ContentId: this.props.item != undefined ? this.props.item.id : "-",
          Tenant: this.props.tenant,
          Instance: this.props.instance,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment
        });
        SocialService.like(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.user, this.props.itemId, this.props.source, this.props.id).then((likes: ILike[]) => {
          this.setState({ likes, showLikeButtonLoader: false });
          this.props.likesUpdated(likes);
        });
      }
    }
  }

  private replyButtonOnClick(): void {
    this.props.replyComment(
      {
        user: this.props.author,
        commentId: this.props.id
      }
    );
  }

  public render(): JSX.Element {
    let isPublishingNews = this.props.item != undefined && this.props.item.type == "News" ? true : false;
    let firstNameChar = this.props.author.displayName != undefined ? this.props.author.displayName.split(" ")[0].charAt(0) : "";
    let lastNameChar = this.props.author.displayName != undefined ? this.props.author.displayName.split(" ")[this.props.author.displayName.split(" ").length - 1].charAt(0) : "";
    let likes: JSX.Element[];
    // likes
    let hasLiked = false;
    let peopleWhoLiked = [];
    if (this.props.likesEnabled && this.state.likes != undefined && this.state.likes.length > 0) {
      this.state.likes.forEach((like: ILike) => {
        if (this.props.user && (like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase())) {
          hasLiked = true;
        }
        peopleWhoLiked.push(
          <div className={styles.IA_personWhoLiked}>
            {like.user.displayName}
          </div>
        );
      });
      likes = Utils.getLikesWithExamples(this.state.likes, this.props.user, this.props.highlightColor, this.props.isMobile);
    }
    let author: JSX.Element[] = [];
    author.push(
      <span key={`displayName_${this.props.index}`}>
        {this.props.myComment ? new LocalizationService().strings.LikesAndComments_Comment_You : this.props.author.displayName}
      </span>
    );
    if (this.props.reply != undefined && this.props.reply.user != undefined) {
      author.push(
        <span
          key={`author_${this.props.reply.user.displayName}`}
          style={{ fontWeight: "normal" }}
        >
          {` ${new LocalizationService().strings.LikesAndComments_InReplyTo}`} <span style={{ fontWeight: "bold" }}>{this.props.reply.user.displayName}</span>
        </span>
      );
    }
    author.push(
      <span
        key={`authorDate_${this.props.created}`}
        style={{ color: "gray", fontWeight: "normal", fontSize: "12px", lineHeight: "18px", marginLeft: 5 }}
      >
        {this.dateTimeService.ConvertToDDMMYYYY(this.props.created) == this.dateTimeService.ConvertToDDMMYYYY(new Date()) ? new LocalizationService().strings.DateTimeService_Today + " " + this.dateTimeService.ConvertToHHMM(this.props.created) : this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.created)}
      </span>
    );
    let profileImage = "";
    if (isPublishingNews) {
      profileImage = this.props.author.image;
    } else {
      profileImage = Helper.getProfileImageUrlFromImageAPI(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, this.props.author.userPrincipalName);
    }
    return (
      <div
        className={styles.IA_comment}
        style={{
          borderLeft: this.props.myComment ? `2px solid ${this.props.highlightColor}` : `2px solid transparent`,
          backgroundColor: (this.props.selectedComment && this.props.editorOpen) ? "rgba(0,0,0,0.05)" : "",
          marginLeft: -this.props.padding,
          paddingLeft: this.props.padding,
          paddingRight: this.props.padding,
        }}
      >
        <div
          className={styles.IA_commentProfileImageWrapper}
          style={{
            backgroundColor: this.props.highlightColor != undefined ? this.props.highlightColor : "#333333",
            color: this.props.highlightColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.highlightColor, "#ffffff", "#333333") : "#ffffff"
          }}
        >
          <div className={styles.IA_commentProfileImageText}>
            {firstNameChar + lastNameChar}
          </div>
          <div
            className={styles.IA_commentProfileImagePicture}
            style={{
              backgroundImage: `url('${profileImage}')`
            }}
          />
        </div>
        <div
          className={styles.IA_commentWrapper}
        >
          <div className={styles.IA_commentAuthor}>{author}</div>
          {this.props.myComment &&
            <div
              className={styles.IA_commentDeleteBtn}
              style={{ backgroundImage: `url('${IC_DELETE}')` }}
              onClick={() => {
                LoggingService.trackEvent(this.constructor.name, {
                  ActionType: "DeleteCommentFromLikesAndComments",
                  Component: this.props.component,
                  ContentType: this.props.item != undefined ? this.props.item.type : "-",
                  ContentId: this.props.item != undefined ? this.props.item.id : "-",
                  Tenant: this.props.tenant,
                  Instance: this.props.instance,
                  UserId: this.props.token,
                  Source: this.props.source,
                  Environment: this.props.environment
                });
                this.props.delete(this.props.index, this.props.id);
              }}
            />
          }
          <div
            className={styles.IA_commentText}
            id={`comment-${this.props.id}`}
            // style={{
            //   lineClamp: this.state.expanded != undefined && !this.state.expanded ? 2 : undefined,
            //   WebkitLineClamp: this.state.expanded != undefined && !this.state.expanded ? 2 : undefined
            // }}
            dangerouslySetInnerHTML={{ __html: this.state.content }}
          />
          {/* {this.state.contentIsOverflowing &&
            <div
              className={styles.IA_expandButton}
              style={{
                color: this.props.highlightColor != undefined ? this.props.highlightColor : "#333333"
              }}
              onClick={() => {
                this.setState({ expanded: !this.state.expanded });
              }}
            >
              {this.state.expanded ? `${new LocalizationService().strings.LikesAndComments_Comment_Collapse} <` : `${new LocalizationService().strings.LikesAndComments_Comment_Expand} >`}
            </div>
          } */}
          {/* Like button */}
          {this.props.likesEnabled && !isPublishingNews &&
            <div
              className={[styles.IA_commandBarButton, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              role="button"
              style={{
                color: this.props.highlightColor,
                clear: "both"
              }}
              onClick={() => this.likeButtonOnClick(hasLiked)}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.likeButtonOnClick(hasLiked);
                }
              }}
            >
              <div className={styles.IA_commandBarButtonIcon}>
                <IAIcon
                  title={hasLiked ? "LikeFilled" : "Like"}
                  size={14}
                  color={this.props.highlightColor}
                  style={{
                    marginTop: 4
                  }}
                />
              </div>

              <div className={styles.IA_commandBarButtonText}>
                {hasLiked ? new LocalizationService().strings.LikesAndComments_Comment_Unlike : new LocalizationService().strings.LikesAndComments_Comment_Like}
              </div>
            </div>
          }
          {/* Reply button */}
          {!isPublishingNews &&
            <div
              className={[styles.IA_commandBarButton, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              role="button"
              style={{
                color: this.props.highlightColor,
              }}
              onClick={() => this.replyButtonOnClick()}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.replyButtonOnClick();
                }
              }}
            >
              <div
                className={styles.IA_commandBarButtonIcon}
              >
                <IAIcon
                  title="Comment"
                  size={14}
                  color={this.props.highlightColor}
                  style={{
                    marginTop: 5
                  }}
                />
              </div>
              <div
                className={styles.IA_commandBarButtonText}
              >
                {new LocalizationService().strings.LikesAndComments_Comment_Reply}
              </div>
            </div>
          }
          {/* Show likes */}
          {!isPublishingNews &&
            <div
              className={styles.IA_likes}
            >
              {likes}
            </div>
          }
        </div>
      </div>
    );
  }

  public componentDidCatch(error) {
    LoggingService.trackException(this.constructor.name, "componentDidCatch", error);
  }
}