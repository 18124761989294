exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listElementHeader_e-h3eh5pUrIIa4iUHSiIn {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  overflow: hidden;\n  font-size: 12px;\n  padding-left: 15px;\n  box-sizing: border-box;\n}\n\n.IA_previewColumn_3FRKKMwSug8ts1Bw_hjHSW {\n  flex: 0.2;\n}\n\n.IA_iconColumn_BEcu4nDTQQrkpPACXgNsh {\n  flex: 0.4;\n  padding-right: 10px;\n  box-sizing: border-box;\n}\n\n.IA_column_Zr_LVssnJNayAFUbuEMTb {\n  flex: 1;\n}\n\n.IA_buttons_2MhuBsFf9k6YGP-NY3jLQf {\n  padding-right: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_listElementHeader": "IA_listElementHeader_e-h3eh5pUrIIa4iUHSiIn",
	"IA_previewColumn": "IA_previewColumn_3FRKKMwSug8ts1Bw_hjHSW",
	"IA_iconColumn": "IA_iconColumn_BEcu4nDTQQrkpPACXgNsh",
	"IA_column": "IA_column_Zr_LVssnJNayAFUbuEMTb",
	"IA_buttons": "IA_buttons_2MhuBsFf9k6YGP-NY3jLQf"
};